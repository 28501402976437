import { ROOT_CAUSE_OWNERS } from "../constants"
import { MosaikViewLabel } from "features/listConversations/types"
import { formatters } from "./formatters"

export const formatChartData = (
  statsData: StatisticsData,
  selectedRootCauseOwner: string,
  labels: Record<number, MosaikViewLabel>,
) => {
  const formatOwnerName = (name?: string) => name?.toLowerCase().trim() || ""

  const matchesSelectedOwner = (stats: any): boolean => {
    if (!stats || typeof stats !== "object") return false
    if (selectedRootCauseOwner === "all") return true

    const statsOwner = formatOwnerName(stats.rootCauseOwner)
    const selectedOwner = formatOwnerName(
      ROOT_CAUSE_OWNERS.find((owner) => owner.value === selectedRootCauseOwner)
        ?.label,
    )

    return statsOwner === selectedOwner
  }

  const formatChartItem = (labelId: string, stats: any) => {
    const label = labels[parseInt(labelId)]

    const percentageConversations = formatters.percentage.format(
      stats.percentageConversations,
    )
    const rootCauseOwner = formatters.metrics(
      "rootCauseOwner",
      stats.rootCauseOwner,
    )
    const percentage = formatters.percentage.parse(
      stats.percentageConversations,
    )

    return {
      label_id: labelId,
      occurrence_count: stats.conversations,
      percentage,
      rootCauseOwner,
      percentageConversations,
      displayName: label?.displayName,
      lastPeriod: stats.lastPeriod,
      color: label?.color || "#0058a3",
    }
  }

  return Object.entries(statsData)
    .filter(([_, stats]) => matchesSelectedOwner(stats))
    .map(([labelId, stats]) => formatChartItem(labelId, stats))
    .sort((a, b) => b.percentage - a.percentage)
}
