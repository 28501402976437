import { setDoc, deleteDoc } from "firebase/firestore"
import { ChatThread } from "features/insights/types"
import { getChatThreadDoc } from "utils/firestorePaths"

export const saveChatThreadFS = async (
  chatThread: ChatThread,
  userId: string,
): Promise<void> => {
  if (!chatThread || !userId) {
    throw new Error("Invalid arguments for saving chat thread")
  }

  try {
    const threadDoc = getChatThreadDoc(userId, chatThread.uuid)

    // We filter away the raw data as its huge and can be fetched again with the stored filter string
    const filteredThread = {
      ...chatThread,
      chatMessages: chatThread.chatMessages.filter(
        (message) => message.type !== "raw-data",
      ),
    }

    await setDoc(threadDoc, filteredThread)
  } catch (error: any) {
    throw error
  }
}

export const removeChatThreadFS = async (
  threadId: string,
  userId: string,
): Promise<void> => {
  if (!threadId || !userId) {
    throw new Error("Invalid arguments for removing chat thread")
  }

  try {
    const threadDoc = getChatThreadDoc(userId, threadId)
    await deleteDoc(threadDoc)
  } catch (error: any) {
    throw error
  }
}
