import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { InsightsUtilsRunners } from "../../utils/llmUtils/llmUtilRunners"
import { removeMessage } from "../../insightsSlice"
import { ToolbarProps } from "features/insights/types"
import { useTranslation } from "react-i18next"

const SentMessageToolbar = ({ messageIndex, disabled }: ToolbarProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { runEditMessage, runCancelEdit } = InsightsUtilsRunners()

  return (
    <div className="flex-row message-toolbar">
      {insightsState.messageEditIndex !== null &&
      insightsState.messageEditIndex === messageIndex ? (
        <button disabled={disabled} onClick={() => runCancelEdit()}>
          {t("buttons:cancelEditPrompt")}
        </button>
      ) : (
        <>
          <button
            disabled={disabled}
            onClick={() => runEditMessage(messageIndex as number)}
          >
            {t("buttons:edit")}
          </button>
          <button
            disabled={disabled}
            onClick={() => dispatch(removeMessage(messageIndex as number))}
          >
            {t("buttons:remove")}
          </button>
        </>
      )}
    </div>
  )
}

export default SentMessageToolbar
