import { useState, useEffect } from "react"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import InsightsMessage from "features/insights/InsightsMessage"
import { ChatViewProps } from "features/insights/types"
import { styles } from "./styles"
import NoMessages from "./NoMessages"
import { MessageToolbar } from "../../toolbars/MessageToolbar"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import React from "react"

const ChatView = ({ chatControlsRef }: ChatViewProps) => {
  const insightsState = useAppSelector((state: RootState) => state.insights)

  const { currentThreadIsEmpty, renderableMessages } =
    useInsightsHelperFunctions()
  const [clientHeight, setClientHeight] = useState<number | null>(null)

  const messages = renderableMessages(
    insightsState.currentThread!.chatMessages,
    true,
  )

  useEffect(() => {
    if (!chatControlsRef.current) return

    const updateHeight = () => {
      if (chatControlsRef.current) {
        setClientHeight(chatControlsRef.current.clientHeight)
      }
    }

    updateHeight()

    const observer = new ResizeObserver(updateHeight)
    observer.observe(chatControlsRef.current)

    window.addEventListener("resize", updateHeight)

    return () => {
      observer.disconnect()
      window.removeEventListener("resize", updateHeight)
    }
  }, [chatControlsRef, insightsState.currentThread])

  const isMessageInEditedBranch = (messageIndex: number): boolean => {
    return (
      insightsState.messageEditIndex !== null &&
      insightsState.messageEditIndex !== undefined &&
      messageIndex > insightsState.messageEditIndex
    )
  }

  const toolbarDisabled = (messageIndex: number): boolean => {
    return Boolean(
      insightsState.insightsSummaryLoading ||
        (insightsState.messageEditIndex &&
          insightsState.messageEditIndex < messageIndex),
    )
  }

  return (
    <div className="chat">
      <div className="chat-content" style={styles.llmChatPadding(clientHeight)}>
        {currentThreadIsEmpty(false) ? (
          <NoMessages />
        ) : (
          messages?.map((message, messageIndex) => (
            <React.Fragment key={messageIndex}>
              <InsightsMessage
                messageContent={message.content}
                messageEditState={isMessageInEditedBranch(messageIndex)}
                type={message.type}
                toolbar={
                  <MessageToolbar
                    message={message}
                    messageIndex={messageIndex}
                    isDisabled={toolbarDisabled(messageIndex)}
                  />
                }
              />
            </React.Fragment>
          ))
        )}
      </div>
    </div>
  )
}

export default ChatView
