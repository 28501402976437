import { ReactElement } from "react"
import { INSIGHTS_MODELS } from "./constants"
import { ChatThreadFilter } from "./utils/firestore/types"

export interface InsightsState {
  insightsSummary: string
  insightsSummaryCost: number
  insightsLoading: boolean
  insightsSummaryLoading: boolean
  savedSummaryPrompts?: SavedPrompt[]
  savedInsightsPrompts?: SavedPrompt[]
  promptsLoading: boolean
  selectedSummaryPrompt: SavedPrompt
  selectedInsightPrompt: SavedPrompt
  summaryPromptIsSaved: boolean
  insightPromptIsSaved: boolean
  chatPromptIsSaved: boolean
  reportPromptIsSaved: boolean
  isSaving: boolean
  chatMessages: Message[]
  currentChatMessage: SavedPrompt
  messageEditIndex?: number | null
  advancedControlsSheetVisible: boolean
  advancedControlsVisible: boolean
  tabIndex: number
  animateButton: boolean
  chatHistoryVisible: boolean
  chatThreads: ChatThread[]
  selectedThread: string
  selectedThreadPrev: string
  selectedThreadToast: boolean
  previewedThread: string
  insightsDataShare: number
  insightsModelConfig: InsightModel[]
  largeContextWindowEnabled: boolean
  tokensPerConversation: number
  settingsVisible: boolean
  selectedChatModel: LLMModel
  llmReportTemplates?: LLMReportTemplate[]
  llmReportTemplatesLoading?: boolean
  llmReportTemplateSelected?: LLMReportTemplate
  llmReportManualPrompt?: string
  llmReportModel: LLMModel
  selectedTokensPerConversation: number
  generatedLlmReport?: string
  llmReportLoading: boolean
  reportMode: boolean
  currentThread?: ChatThread
  storedThreadHistoryLoading: boolean
  storedThreadHistoryLoadingSilently: boolean
  storedThreadHistoryPage: number
  storedThreadLoading: boolean
  threadHistoryPage?: number
  storedThreadHistoryLastDoc?: SerializableDocumentSnapshot | null
  initialThreadPersistCheckDone: boolean
}

export interface InsightModel {
  name: string
  cost: LLMModelCost
  contextWindow: number
  largeContextWindow?: number
}

export type LLMModel = (typeof INSIGHTS_MODELS)[number]["name"]

// Cost per million tokens in USD
// "Range" denotes the the range counted in tokens for which the cost should be applied
interface LLMModelCost {
  input: number
  inputCached?: number
  output: number
  range?: number[]
}

interface LLMRequest {
  prompt: string
  model: LLMModel
}

export interface DefaultRequest extends LLMRequest {
  insights: string[]
}

export interface DataProcessingRequest extends LLMRequest {
  messages: Record<string, string>
}

export interface ConversationInsight {
  insights: string
  cost: number
}

export interface SavedPrompt {
  id: string
  prompt: string
  displayName: string
}

export enum MessageRating {
  liked = "liked",
  disliked = "disliked",
}

export interface Message {
  content: string
  type: MessageType
  feedback?: string
  rating?: MessageRating
}

export type MessageType =
  | "sent"
  | "received"
  | "pre-processed-data"
  | "raw-data"
  | "report"

export type PromptType = "summary" | "insight" | "chat" | "report"

export type ToolbarProps = {
  disabled: boolean
  messageIndex?: number
  hasAppliedInitialData?: React.MutableRefObject<boolean>
}

export interface ChatViewProps {
  chatControlsRef: React.RefObject<HTMLDivElement>
}

export type ChatTextAreaProps = {
  textareaRef: React.RefObject<HTMLTextAreaElement>
}

export type PromptControlProps = {
  promptCrud: ReactElement
}

export interface SheetViewProps {
  visible: boolean
  handleClose: (e?: React.MouseEvent) => void
  content?: React.ReactNode
  footer: React.ReactElement | null
  details: React.ReactElement
  rightSide?: boolean
}

export interface ChatThread {
  chatMessages: Message[]
  insights: Record<string, ConversationInsight>
  filters: string
  recap?: string
  uuid: string
  lastInteraction: number
  model: LLMModel
  tokensPerConversation: number
}

export type LLMFormattingConfig = {
  instructions: LLMFormattingMessages
  systemPrompt: string
  version: string
}

type LLMFormattingMessages = {
  received: string
  sent: {
    latest: string
    previous: string
  }
  rawData: string
  preProcessedData: string
  report: string
}

type RawDataFormatting = "full" | "many"

export interface LLMReportTemplate {
  id: string
  title: string
  image?: string
  description: string
  instruction: string
  model: LLMModel
  formatting: RawDataFormatting
  manual_input?: boolean
}

export type ResumeStoredLLMThreadReq = {
  selectedThread: string
  currentFilters: string
  userId: string
}

export type ResumeStoredLLMThreadRes = ChatThread | undefined

export type FetchStoredLLMThreadsRes = {
  threads: ChatThread[]
  lastDoc: SerializableDocumentSnapshot | null
}

export type FetchStoredLLMThreadsReq = {
  userId: string
  page?: number
  fetchSilently?: boolean
  lastDoc?: SerializableDocumentSnapshot | null
}

export interface FetchStoredLLMThreadsProps extends FetchStoredLLMThreadsReq {
  filter: ChatThreadFilter
  quantity?: number
}

export type SerializableDocumentSnapshot = {
  id: string
  path: string
}
