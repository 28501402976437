import { Skeleton } from "@mui/material"

const ConversationsSkeleton = () => (
  <div className="flex-column">
    {Array.from({ length: 4 }).map((_, index) => (
      <Skeleton
        key={index}
        variant="rounded"
        width={"100%"}
        style={{ margin: "0 0 .5rem 0", borderRadius: "1rem" }}
        height={340}
      />
    ))}
  </div>
)

export default ConversationsSkeleton
