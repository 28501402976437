import Button from "@ingka/button"
import { MenuItem, Skeleton } from "@mui/material"
import TextField from "@mui/material/TextField"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { isMarket, market, validChannels } from "app/types"
import * as React from "react"
import { toChannel } from "utils/utils"
import LabelTreeSelect from "../../features/listConversations/LabelTreeSelect"
import { toggleFilterMenu } from "../../features/listConversations/listConversationSlice"
import { useTranslation } from "react-i18next"
import FeatureTitle from "components/FeatureTitle"
import VirtualizedSelect from "../VirtualizedSelect"
import { createFilterOptions } from "@mui/material/Autocomplete"
import Checkbox from "@ingka/checkbox"
import Text from "@ingka/text"
import { useEntityOptions } from "hooks/useEntityOptions"
import {
  ENTITY_OPTIONS_DEFAULT_STATE,
  FILTERS_DEFAULT_STATE,
} from "./constants"
import { FilterMap } from "./types"
import { filterMapToKv } from "utils/filterUtils"
import { useFetchConversations } from "features/listConversations/hooks/useFetchConversations"
import { useDashboardSelection } from "stores/hooks"
import InlineMessage from "@ingka/inline-message"
import warningIcon from "@ingka/ssr-icon/paths/warning-triangle"
import { useAuthHelpers } from "features/auth/useAuthHelpers"

const entityFilterFields = [
  { key: "productNames", placeholder: "Product Names" },
  { key: "touchpoints", placeholder: "Touchpoints" },
  { key: "hfbs", placeholder: "HFBs" },
  { key: "unit_names", placeholder: "Unit Names" },
  { key: "unit_codes", placeholder: "Unit Codes" },
  { key: "service_providers", placeholder: "Service providers" },
  { key: "services", placeholder: "Services" },
] as const

const FilterSelection = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const filters = useAppSelector(
    (state: RootState) => state.listConversation.filters,
  )
  const entityOptionsFetching = useAppSelector(
    (state: RootState) => state.listConversation.entityOptionsFetching,
  )
  const loadingConversations = useAppSelector(
    (state: RootState) => state.listConversation.loadingConversations,
  )

  const { accessibleMarkets, noAccessibleMarkets } = useAuthHelpers()

  const [menuFilters, setMenuFilters] = React.useState<FilterMap>(
    FILTERS_DEFAULT_STATE,
  )

  const { handleApplyFilterChange } = useFetchConversations()

  const { entityOptions, entityOptionsLoadingIntoMem } = useEntityOptions(
    menuFilters.country,
    entityOptionsFetching,
  )

  const { resetDashboardField } = useDashboardSelection()

  React.useEffect(() => {
    if (filters.length > 0) {
      const currentFilters: FilterMap = { ...menuFilters }
      for (const filter of filters) {
        const filterKey = filter.key as keyof FilterMap
        let value: any[] | string = filter.value

        switch (filterKey) {
          case "country":
            value = filter.value
              .split(",")
              .map((c) => c.toUpperCase()) as market[]
            break
          case "channel":
            value = filter.value.split(",").map((c) => toChannel(c))
            break
          case "manual_tag_label_id":
          case "prediction_label_id":
          case "llm_label_id":
          case "not_llm_label_id":
            value = filter.value.split(",").map((c) => Number(c))
            break
          case "touchpoints":
          case "productNames":
          case "productNumbers":
          case "hfbs":
          case "unit_names":
          case "unit_codes":
          case "service_providers":
          case "services":
            value = value.split(",")
            break
        }

        currentFilters[filterKey] = value as any
      }
      setMenuFilters(currentFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, dispatch])

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement> | any,
    valueName?: string,
  ) => {
    const columnName = event.target?.name ?? valueName
    const filterValue = event.target?.value ?? event

    setMenuFilters((prevFilters) => ({
      ...prevFilters,
      [columnName]: filterValue,
    }))
  }

  const handleArrayFilterChange = (event: any) => {
    const value = event.target.value
    const name = event.target.name as keyof FilterMap
    setMenuFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }))
  }

  const onEntitySelect = (key: string, newValue: string[]) => {
    setMenuFilters((prevFilters) => {
      return {
        ...prevFilters,
        [key]: newValue,
      }
    })
  }

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (entity: string) => `${entity}`,
  })

  const handleApplyFilters = () => {
    resetDashboardField("selectedAnalysisFiltering")
    resetDashboardField("selectedConversationId")
    dispatch(toggleFilterMenu())
    const kvFilters = filterMapToKv(menuFilters)
    handleApplyFilterChange(kvFilters)
  }

  const handleClearFilters = () => {
    setMenuFilters({ ...FILTERS_DEFAULT_STATE })
  }

  const handleCheckboxSelect = (
    checked: boolean,
    value: any,
    key: keyof FilterMap,
  ) => {
    const checkboxValue = value.toString()
    const currentValues = (menuFilters[key] || "")
      .toString()
      .split(",")
      .filter(Boolean)

    let newValues
    if (checked) {
      newValues = [...currentValues, checkboxValue]
    } else {
      newValues = currentValues.filter((v) => v !== checkboxValue)
    }

    handleFilterChange(newValues.join(","), key)
  }

  const entityOptionsLoading =
    entityOptionsFetching || entityOptionsLoadingIntoMem

  const displayEntityFilters =
    menuFilters.country.length === 1 &&
    isMarket(menuFilters.country[0]) &&
    entityOptions !== ENTITY_OPTIONS_DEFAULT_STATE &&
    !entityOptionsLoading

  const entityInstructions = (
    <div className="filter-text">
      <Text tagName="p" bodySize="m" style={{ margin: "0", textAlign: "left" }}>
        {menuFilters.country.length === 0
          ? t("instructions:selectCountry")
          : entityOptions === ENTITY_OPTIONS_DEFAULT_STATE
            ? t("instructions:unsupportedCountry")
            : t("instructions:oneCountry")}
      </Text>
    </div>
  )

  const entitySkeleton = <Skeleton width="100%" />

  const entityFilters = entityFilterFields.map(({ key, placeholder }) => (
    <VirtualizedSelect
      key={key}
      value={menuFilters[key]}
      onChange={(_, newValue: string[]) => onEntitySelect(key, newValue)}
      options={entityOptions[key]}
      getOptionLabel={(option: string) => `${option}`}
      filterSelectedOptions
      disableCloseOnSelect
      loading={entityOptionsLoading}
      placeholder={placeholder}
      isOptionEqualToValue={(o: string, v: string) => o === v}
      multiple
      filterOptions={filterOptions}
    />
  ))

  return (
    <div style={{ position: "relative", height: "inherit" }}>
      <div className="controls-parent" style={{ gap: "1rem" }}>
        <div style={{ marginBottom: "1.75rem" }}>
          <FeatureTitle featureName={t("buttons:editFilters")} />
        </div>
        {noAccessibleMarkets && (
          <InlineMessage
            title={t("warnings:note")}
            body={t("warnings:noAccessibleMarket")}
            variant="cautionary"
            ssrIcon={warningIcon}
          />
        )}
        <div className="modal-section-parent" style={{ gap: "1rem" }}>
          <div className="flex-row spaced">
            <h4>{t("subheadings:filters")}</h4>
          </div>
          <Text tagName="span" bodySize="s">
            {t("labels:initiationDate")}
          </Text>
          <div className="flex-row spaced">
            <TextField
              fullWidth
              label={t("labels:filters:startTime")}
              type="date"
              name="from_start_time"
              value={menuFilters.from_start_time}
              onChange={handleFilterChange}
            />
            <TextField
              fullWidth
              label={t("labels:filters:endTime")}
              type="date"
              name="to_start_time"
              value={menuFilters.to_start_time}
              onChange={handleFilterChange}
            />
          </div>
          <Text tagName="span" bodySize="s">
            {t("subheadings:marketChannel")}
          </Text>
          <TextField
            fullWidth
            label="Market"
            select
            name="country"
            value={menuFilters.country}
            disabled={noAccessibleMarkets}
            onChange={(event) => {
              menuFilters.touchpoints = []
              menuFilters.productNames = []
              menuFilters.productNumbers = []
              menuFilters.hfbs = []
              menuFilters.unit_names = []
              menuFilters.unit_codes = []
              menuFilters.service_providers = []
              menuFilters.services = []
              handleArrayFilterChange(event)
            }}
            SelectProps={{
              multiple: true,
              MenuProps: {
                sx: { height: "200px" },
              },
            }}
          >
            {accessibleMarkets.map((country) => (
              <MenuItem value={country} key={country}>
                {country}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            label="Channel"
            select
            name="channel"
            value={menuFilters.channel}
            onChange={(event) => handleArrayFilterChange(event)}
            SelectProps={{
              multiple: true,
            }}
          >
            {validChannels.map((channel) => (
              <MenuItem value={channel} key={channel}>
                {channel}
              </MenuItem>
            ))}
          </TextField>
          <Text tagName="span" bodySize="s">
            {t("subheadings:categorisation")}
          </Text>
          <LabelTreeSelect
            onChange={(newValue) =>
              handleFilterChange(newValue, "manual_tag_label_id")
            }
            label="Manual Label Id"
            value={menuFilters.manual_tag_label_id}
            multiple={true}
          />
          <LabelTreeSelect
            onChange={(newValue) =>
              handleFilterChange(newValue, "prediction_label_id")
            }
            label="Auto Label Id"
            value={menuFilters.prediction_label_id}
            multiple={true}
          />
          <Text tagName="span" bodySize="s">
            {t("labels:csatScore")}
          </Text>
          <div className="flex-row spaced">
            {[1, 2, 3, 4, 5].map((value) => (
              <Checkbox
                key={`cust_sat-${value}`}
                label={value.toString()}
                id={`cust_sat-${value}`}
                value={value.toString()}
                name="cust_sat"
                onChange={(e) => {
                  handleCheckboxSelect(e.target.checked, value, "cust_sat")
                }}
                checked={menuFilters.cust_sat
                  ?.toString()
                  .split(",")
                  .includes(value.toString())}
              />
            ))}
          </div>
        </div>
        <div className="modal-section-parent" style={{ gap: "1rem" }}>
          <div className="flex-row spaced" style={{ marginBottom: ".5rem" }}>
            <h4>{t("subheadings:entityFilters")}</h4>
          </div>
          {displayEntityFilters
            ? entityFilters
            : entityOptionsFetching
              ? entitySkeleton
              : entityInstructions}
        </div>
        <Button
          type="primary"
          onClick={handleApplyFilters}
          disabled={loadingConversations}
        >
          {t("buttons:fetchConversations")}
        </Button>
        <Button
          type="secondary"
          onClick={handleClearFilters}
          disabled={loadingConversations}
        >
          {t("buttons:resetFilters")}
        </Button>
      </div>
    </div>
  )
}

export default FilterSelection
