/** Cursor */

import { useState, useEffect, useMemo, useCallback } from "react"
import Text from "@ingka/text"
import {
  Paper,
  TextField,
  Collapse,
  IconButton,
  Button as MuiButton,
} from "@mui/material"
import { listConversations, insightsSummary, saveReportFn } from "app/api"
import { IConversation, KeyValueType } from "app/types"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ReactMarkdown from "react-markdown"
import { styled } from "@mui/material/styles"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
// @ts-ignore
import DOMPurify from "dompurify"
import Titlebar from "components/Titlebar"
import PageAnimation from "components/PageAnimation"
import { useTranslation } from "react-i18next"

export const fetchConversations = async (filters: KeyValueType[]) => {

    const payload: Record<string, any> = {};
    payload["filters"] = { filters: [], logic: "AND" }

    for (let i = 0; i < filters.length; i++) {
      const item = filters[i];

      switch (item.key) {
        case "limit":
          payload["limit"] = Number(item.value)
          break
        case "from_start_time":
        case "to_start_time":
          payload[item.key] = item.value;
          break
        case "country":
          payload["countries"] = [item.value];
          break
        case "cust_sat":
        case "manual_tag_label_id":
        case "prediction_label_id":
          payload["filters"]["filters"].push(
            { key: item.key, value: item.value.split(",").map(Number), relation: "IN" }
          )
          break
        case "channel":
          payload["filters"]["filters"].push(
            { key: item.key, value: item.value.split(","), relation: "IN" }
          )
          break
        case "hfbs":
        case "services":
        case "service_providers":
        case "unit_codes":
        case "productNames":
        case "unit_names":
          payload["filters"]["filters"].push({ key: item.key, value: item.value.split(",") });
          break
        default:
          payload["filters"]["filters"].push(item);
          break
      }
    }

    console.log(payload);
    const response = (await listConversations(payload)).data;
    console.log(response);

    return response;
  }

interface ParsedParams {
  [key: string]: string
}

interface FormatParams {
  transcriptOrder: boolean
  userTypes: string[]
  isAutomated: boolean[]
  isAfterBillie: boolean[]
  maxCharacters: number
}

interface ConfigJson {
  tests: TestCase[]
}

interface BaseTestCase {
  id: string
  test: string
  filter: string
  format: string
  model: string
  promptFile: string
}

interface TestCase extends BaseTestCase {}

interface TestState {
  isFetching: boolean
  hasFetched: boolean
  isEvaluating: boolean
  conversations: IConversation[]
  formattedConversations: string
  evaluationResult: string
  error: string | null
  isFormattedExpanded: boolean
  isEvaluationExpanded: boolean
  status: "idle" | "running" | "success" | "failed"
  prompt: string
  chatMessages: Array<{
    type: "user" | "assistant"
    content: string
    isDebug?: boolean
  }>
  showDebug: boolean
  isSaving?: boolean
}

const DEFAULT_FORMAT_PARAMS: FormatParams = {
  transcriptOrder: false,
  userTypes: ["agent", "customer"],
  isAutomated: [true, false],
  isAfterBillie: [true, false],
  maxCharacters: -1,
}

const StyledButton = styled(MuiButton)(() => ({
  textTransform: "none",
  fontWeight: 500,
  padding: "8px 16px",
  borderRadius: "6px",
  fontSize: "14px",
  boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
  transition: "all 0.2s ease",
  border: "1px solid #e2e8f0",
  backgroundColor: "#fff",
  color: "#000",
  "&:hover": {
    backgroundColor: "#f8fafc",
    borderColor: "#cbd5e1",
  },
  "&.success": {
    backgroundColor: "#22c55e",
    color: "#fff",
    borderColor: "#16a34a",
    "&:hover": {
      backgroundColor: "#16a34a",
    },
  },
  "&.error": {
    backgroundColor: "#ef4444",
    color: "#fff",
    borderColor: "#dc2626",
    "&:hover": {
      backgroundColor: "#dc2626",
    },
  },
  "&.primary": {
    backgroundColor: "#3b82f6",
    color: "#fff",
    borderColor: "#2563eb",
    "&:hover": {
      backgroundColor: "#2563eb",
    },
  },
  "&.Mui-disabled": {
    backgroundColor: "#f1f5f9",
    color: "#94a3b8",
    borderColor: "#e2e8f0",
  },
}))

const ChatInterface: React.FC<{
  test: TestCase
  testState: TestState
  onSendMessage: (testId: string, message: string) => void
  onResetChat: (testId: string) => void
  onToggleDebug: (testId: string) => void
}> = ({ test, testState, onSendMessage, onResetChat, onToggleDebug }) => (
  <Paper
    elevation={0}
    style={{
      padding: "1.5rem",
      marginTop: "1rem",
      backgroundColor: "#fff",
      border: "1px solid #e9ecef",
      borderRadius: "4px",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "1rem",
      }}
    >
      <Text tagName="h5" style={{ margin: 0 }}>
        Chat with Results
      </Text>
      <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
        <StyledButton
          onClick={() => onResetChat(test.id)}
          style={{ padding: "4px 8px" }}
        >
          Reset Chat
        </StyledButton>
        <Text style={{ fontSize: "0.9rem", color: "#666", marginLeft: "1rem" }}>
          Show Debug
        </Text>
        <StyledButton
          onClick={() => onToggleDebug(test.id)}
          className={testState.showDebug ? "primary" : ""}
          style={{ padding: "4px 8px", minWidth: "60px" }}
        >
          {testState.showDebug ? "On" : "Off"}
        </StyledButton>
      </div>
    </div>

    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        maxHeight: "400px",
        overflowY: "auto",
        padding: "1rem",
        backgroundColor: "#fff",
        borderRadius: "4px",
        border: "1px solid #e9ecef",
      }}
    >
      {testState.chatMessages
        ?.filter((message) => !message.isDebug || testState.showDebug)
        .map((message, index) => (
          <div
            key={index}
            style={{
              alignSelf: message.type === "user" ? "flex-end" : "flex-start",
              maxWidth: "80%",
              padding: "1rem",
              borderRadius: "8px",
              backgroundColor: message.isDebug
                ? "#fff3e0"
                : message.type === "user"
                  ? "#e3f2fd"
                  : "#f5f5f5",
              boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
            }}
          >
            <div
              style={{
                fontSize: "0.8rem",
                color: "#666",
                marginBottom: "0.5rem",
              }}
            >
              {message.type === "user"
                ? "You"
                : message.isDebug
                  ? "Debug"
                  : "Assistant"}
            </div>
            <ReactMarkdown>{message.content}</ReactMarkdown>
          </div>
        ))}
    </div>

    <div
      style={{
        display: "flex",
        gap: "1rem",
        marginTop: "1rem",
        alignItems: "flex-start",
      }}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Ask about the evaluation results..."
        multiline
        rows={2}
        id={`chat-input-${test.id}`}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault()
            const input = e.target as HTMLInputElement
            if (input.value.trim()) {
              onSendMessage(test.id, input.value)
              input.value = ""
            }
          }
        }}
      />
      <StyledButton
        onClick={() => {
          const input = document.getElementById(
            `chat-input-${test.id}`,
          ) as HTMLInputElement
          if (input && input.value.trim()) {
            onSendMessage(test.id, input.value)
            input.value = ""
          }
        }}
        className="primary"
        style={{ height: "56px" }}
      >
        Send
      </StyledButton>
    </div>
  </Paper>
)

const PromptBuilder = () => {
  const { labels } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const { t } = useTranslation()

  // Move processXmlContent to the top of the component
  const processXmlContent = (xmlString: string): string => {
    return xmlString.replace(
      /(<filter>)(.*?)(<\/filter>)/g,
      (openTag, content, closeTag) => {
        // Don't escape ampersands that are already part of entities
        const processedContent = content.replace(
          /&(?!amp;|lt;|gt;|quot;|apos;)/g,
          "&amp;",
        )
        return openTag + processedContent + closeTag
      },
    )
  }

  const [configJson, setConfigJson] = useState<string>(() => {
    const saved = localStorage.getItem("promptBuilder.configJson")
    return (
      saved ||
      JSON.stringify(
        {
          tests: [
            {
              id: crypto.randomUUID(),
              test: "1",
              filter: "",
              format: "",
              model: "gpt-4o",
              promptFile: "",
            },
          ],
        },
        null,
        2,
      )
    )
  })
  const [fetchError, setFetchError] = useState<string | null>(null)
  const [testStates, setTestStates] = useState<Record<string, TestState>>({})
  const [_, setInputFileName] = useState<string>("")

  // Helper function to create valid TestCase objects
  const createTestCase = (
    id: string,
    test: string | null | undefined,
    filter: string | null | undefined,
    format: string | null | undefined,
    model: string | null | undefined,
    promptFile: string | null | undefined,
  ): TestCase => ({
    id,
    test: test || id, // Use the provided test value or fall back to id
    filter: filter || "",
    format: format || "",
    model: model || "gpt-4o",
    promptFile: promptFile || "",
  })

  // Memoize the config parsing to prevent re-renders
  const parseConfig = useCallback((configString: string): ConfigJson | null => {
    try {
      const trimmed = configString.trim()
      if (!trimmed) {
        console.warn("Empty config string")
        return null
      }

      if (trimmed.startsWith("<?xml") || trimmed.startsWith("<tests>")) {
        console.log("Parsing XML config...")
        const processedXml = processXmlContent(trimmed)
        return parseXmlConfig(processedXml)
      } else {
        console.log("Parsing JSON config...")
        let fixedJson = trimmed
        if (!trimmed.startsWith("[") && !trimmed.startsWith("{")) {
          console.warn("Invalid JSON start, attempting to fix")
          return null
        }

        try {
          const parsed = JSON.parse(fixedJson)
          const tests = Array.isArray(parsed) ? parsed : parsed.tests

          // Ensure each test has all required fields
          const testsWithIds = tests.map((test: Partial<TestCase>) => {
            const testId = test.id || crypto.randomUUID()
            const testLabel = test.test || testId
            return createTestCase(
              testId,
              testLabel,
              test.filter,
              test.format,
              test.model,
              test.promptFile,
            )
          })

          console.log("Successfully parsed JSON:", {
            type: Array.isArray(parsed) ? "array" : "object",
            hasTests: testsWithIds.length,
          })

          return { tests: testsWithIds }
        } catch (jsonError) {
          console.error("JSON parse error:", jsonError)
          return null
        }
      }
    } catch (error) {
      console.error("Error parsing config:", error)
      return null
    }
  }, [])

  // Memoize the parsed config
  // const parsedConfig = useMemo(
  //   () => parseConfig(configJson),
  //   [configJson, parseConfig],
  // )

  // Add memoized config validation
  const isConfigValid = useMemo(() => {
    try {
      // Check if it's XML format
      if (
        configJson.trim().startsWith("<?xml") ||
        configJson.trim().startsWith("<tests>")
      ) {
        console.log("Validating XML config...")

        // First process the XML content
        const processedXml = processXmlContent(configJson)

        // Sanitize the XML content
        const sanitizedXml = DOMPurify.sanitize(processedXml, {
          USE_PROFILES: {}, // Empty object is correct
          ALLOWED_TAGS: [
            "tests",
            "test",
            "filter",
            "format",
            "model",
            "promptfile",
          ],
          ALLOWED_ATTR: ["id"],
        })

        // Create a new parser and parse the sanitized XML
        const parser = new DOMParser()
        const xmlDoc = parser.parseFromString(sanitizedXml, "text/xml")

        // Check for parsing errors
        const parseError = xmlDoc.getElementsByTagName("parsererror")
        if (parseError.length > 0) {
          console.error("XML validation error:", parseError[0].textContent)
          return false
        }

        // Verify the document structure
        const tests = xmlDoc.getElementsByTagName("test")
        if (tests.length === 0) {
          console.error("No test elements found in XML")
          return false
        }

        // Validate each test element has required children
        for (let i = 0; i < tests.length; i++) {
          const test = tests[i]
          const requiredElements = ["filter", "format", "model", "promptfile"]
          const missingElements = requiredElements.filter(
            (elem) => !test.getElementsByTagName(elem).length,
          )

          if (missingElements.length > 0) {
            console.error(
              `Test ${i + 1} is missing required elements:`,
              missingElements,
            )
            return false
          }
        }

        console.log("XML validation successful:", tests.length, "tests found")
        return true
        console.log("Validation found tests:", tests.length)
        return tests.length > 0
      } else {
        console.log("Validating JSON config...")
        const parsed = JSON.parse(configJson)
        if (Array.isArray(parsed)) {
          return parsed.length > 0
        }
        return !!(
          parsed.tests &&
          Array.isArray(parsed.tests) &&
          parsed.tests.length > 0
        )
      }
    } catch (error) {
      console.error("Config validation error:", error)
      return false
    }
  }, [configJson]) // Only recompute when configJson changes

  // Save config JSON to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("promptBuilder.configJson", configJson)
  }, [configJson])

  // Add new function to resolve prompt file path
  const resolvePromptFilePath = (filePath: string): string => {
    // If it's an absolute URL, return as is
    if (filePath.startsWith("http://") || filePath.startsWith("https://")) {
      return filePath
    }

    // Remove any leading slashes
    const cleanPath = filePath.replace(/^\/+/, "")

    // Get the base URL from the current window location
    const baseUrl = window.location.origin

    // Combine the base URL with the file path
    return `${baseUrl}/${cleanPath}`
  }

  // Update readPromptFile function with better error handling
  const readPromptFile = async (filePath: string): Promise<string> => {
    if (!filePath) {
      throw new Error("No prompt file path provided")
    }

    try {
      const resolvedPath = resolvePromptFilePath(filePath)
      console.log("Loading prompt from:", resolvedPath)

      const response = await fetch(resolvedPath)
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error(`Prompt file not found: ${filePath}`)
        }
        throw new Error(
          `Failed to load prompt file (${response.status}): ${response.statusText}`,
        )
      }

      const contentType = response.headers.get("content-type")
      if (
        contentType &&
        !contentType.includes("text/plain") &&
        !contentType.includes("text/markdown")
      ) {
        console.warn("Unexpected content type:", contentType)
        if (contentType.includes("text/html")) {
          throw new Error(
            `Invalid response: Got HTML instead of text file. Make sure the prompt file exists at: ${filePath}`,
          )
        }
      }

      const text = await response.text()
      // Check if the response looks like HTML
      if (
        text.trim().toLowerCase().startsWith("<!doctype html") ||
        text.trim().toLowerCase().startsWith("<html")
      ) {
        throw new Error(
          `Invalid response: Got HTML instead of text file. Make sure the prompt file exists at: ${filePath}`,
        )
      }

      return text
    } catch (error) {
      console.error("Error reading prompt file:", error)
      throw error
    }
  }

  // Update handleConfigChange to ensure each test has a unique state
  const handleConfigChange = (newConfig: string) => {
    try {
      const config = parseConfig(newConfig)
      if (
        !config ||
        !config.tests ||
        !Array.isArray(config.tests) ||
        config.tests.length === 0
      ) {
        setConfigJson(newConfig)
        console.log("No valid tests found in config")
        return
      }

      // Initialize test states with unique IDs
      const initialTestStates: Record<string, TestState> = {}
      config.tests.forEach((test) => {
        // Ensure each test has a unique ID
        const uniqueId = test.id || crypto.randomUUID()
        test.id = uniqueId // Update the test's ID in the config

        initialTestStates[uniqueId] = {
          isFetching: false,
          hasFetched: false,
          isEvaluating: false,
          conversations: [],
          formattedConversations: "",
          evaluationResult: "",
          error: null,
          isFormattedExpanded: false,
          isEvaluationExpanded: false,
          status: "idle",
          prompt: "",
          chatMessages: [],
          showDebug: false,
        }
      })

      // Update the config with the new unique IDs
      setConfigJson(JSON.stringify({ tests: config.tests }, null, 2))

      // Set all states synchronously
      setTestStates(initialTestStates)
      // setConversations([]);
      // setFormattedConversations("");
      // setEvaluation("");
      setFetchError(null)

      // Load prompts for each test
      config.tests.forEach(async (test) => {
        try {
          const promptContent = await readPromptFile(test.promptFile)
          setTestStates((prev) => ({
            ...prev,
            [test.id]: {
              ...prev[test.id],
              prompt: promptContent,
              error: null,
            },
          }))
        } catch (error) {
          const errorMessage =
            error instanceof Error
              ? error.message
              : "Unknown error loading prompt file"
          console.error(`Error loading prompt for test ${test.id}:`, error)
          setTestStates((prev) => ({
            ...prev,
            [test.id]: {
              ...prev[test.id],
              prompt: "",
              error: errorMessage,
            },
          }))
        }
      })

      // Use the first test for initial state
      const firstTest = config.tests[0]
      if (firstTest.filter) {
        // setUrlParams(firstTest.filter.replace(/^\/\?/, ''));
        parseUrlParams(firstTest.filter.replace(/^\/\?/, ""))
      }

      if (firstTest.format) {
        // setFormatParams(firstTest.format);
        // const parsed = parseFormatParams(firstTest.format)
        // setParsedFormatParams(parsed)
      }
    } catch (error) {
      console.error("Error in handleConfigChange:", error)
      setConfigJson(newConfig)
      // setParsedParams({});
      // setParsedFormatParams(DEFAULT_FORMAT_PARAMS)
      setTestStates({})
      // setConversations([]);
      // setFormattedConversations("");
      // setEvaluation("");
      setFetchError("Error parsing configuration")
    }
  }

  // Update parseXmlConfig to ensure valid TestCase objects
  const parseXmlConfig = (xmlString: string): ConfigJson => {
    console.log("Attempting to parse XML:", xmlString.substring(0, 100) + "...")

    // Process and sanitize the XML
    const processedXml = processXmlContent(xmlString)
    const sanitizedXml = DOMPurify.sanitize(processedXml, {
      USE_PROFILES: {},
      ALLOWED_TAGS: [
        "tests",
        "test",
        "filter",
        "format",
        "model",
        "promptfile",
      ],
      ALLOWED_ATTR: ["id"],
    })

    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(sanitizedXml, "text/xml")

    const parseError = xmlDoc.getElementsByTagName("parsererror")
    if (parseError.length > 0) {
      console.error("XML parsing error:", parseError[0].textContent)
      throw new Error("XML parsing failed")
    }

    const tests = Array.from(xmlDoc.getElementsByTagName("test"))
    console.log("Found tests:", tests.length)

    const mappedTests = tests.map((test) => {
      const testId = crypto.randomUUID()
      const testLabel = test.getAttribute("id")
      const testElement = Array.from(xmlDoc.getElementsByTagName("test")).find(
        (elem) => elem.getAttribute("id") === testLabel,
      )
      if (testElement) {
        const testId = testElement.getAttribute("id") || crypto.randomUUID()
        const test = {
          id: testId,
          test: testId,
          filter:
            testElement.getElementsByTagName("filter")[0]?.textContent || "",
          format:
            testElement.getElementsByTagName("format")[0]?.textContent || "",
          model:
            testElement.getElementsByTagName("model")[0]?.textContent ||
            "gpt-4o",
          promptFile:
            testElement.getElementsByTagName("promptFile")[0]?.textContent ||
            "",
        } as TestCase
        return test
      }
      return {
        id: testId,
        test: testId,
        filter: test.getElementsByTagName("filter")[0]?.textContent || "",
        format: test.getElementsByTagName("format")[0]?.textContent || "",
        model: test.getElementsByTagName("model")[0]?.textContent || "gpt-4o",
        promptFile:
          test.getElementsByTagName("promptFile")[0]?.textContent || "",
      }
    })

    return { tests: mappedTests }
  }

  const parseFormatParams = (params: string): FormatParams => {
    const result = { ...DEFAULT_FORMAT_PARAMS }

    if (!params.trim()) {
      return result
    }

    try {
      // First find all the key-value pairs using regex to handle commas within curly braces
      // Match key=value pairs where value can be either:
      // 1. A set of values in curly braces (including internal commas)
      // 2. A single value without curly braces
      // Added \s* to handle optional whitespace around the values
      const pairs =
        params.match(/(\w+)\s*=\s*(\{[^}]+\}|[^,\s]+)(?:\s*,\s*|\s*$)/g) || []

      pairs.forEach((pair) => {
        // Remove any trailing commas and whitespace from the pair
        const cleanPair = pair.replace(/,\s*$/, "").trim()
        // Split only on the first equals sign to handle cases where value might contain =
        const splitIndex = cleanPair.indexOf("=")
        const key = cleanPair.substring(0, splitIndex).trim()
        const value = cleanPair.substring(splitIndex + 1).trim()

        switch (key.toLowerCase()) {
          case "transcriptorder":
            result.transcriptOrder = value.toLowerCase() === "true"
            break
          case "usertype":
          case "usertypes":
            // Remove curly braces and split by commas
            const valueWithoutBraces = value.replace(/^\{|\}$/g, "").trim() // Remove braces and trim
            const users = valueWithoutBraces
              .split(",")
              .map((u) => u.trim().toLowerCase()) // Convert to lowercase and trim each value
              .filter((u) => u.length > 0)
            result.userTypes =
              users.length > 0 ? users : DEFAULT_FORMAT_PARAMS.userTypes
            break
          case "isautomated":
            // Handle multiple boolean values
            const automatedValues = value
              .replace(/[{}]/g, "")
              .trim()
              .split(",")
              .map((v) => v.trim())
            const automatedBools = automatedValues
              .map((v) => v.toLowerCase() === "true")
              .filter((v, i, arr) => arr.indexOf(v) === i) // Remove duplicates
            result.isAutomated =
              automatedBools.length > 0 ? automatedBools : [true, false]
            break
          case "isafterbillie":
            // Handle multiple boolean values
            const billieValues = value
              .replace(/[{}]/g, "")
              .trim()
              .split(",")
              .map((v) => v.trim())
            const billieBools = billieValues
              .map((v) => v.toLowerCase() === "true")
              .filter((v, i, arr) => arr.indexOf(v) === i) // Remove duplicates
            result.isAfterBillie =
              billieBools.length > 0 ? billieBools : [true, false]
            break
          case "max_characters":
            const num = parseInt(value.trim())
            result.maxCharacters = isNaN(num) ? -1 : num
            break
        }
      })
    } catch (error) {
      console.error("Error parsing format parameters:", error)
    }

    return result
  }

  // New helper function to format conversations with specific params
  const formatConversationsWithParams = (
    conversations: IConversation[],
    formatParams: FormatParams,
    testId: string,
  ) => {
    const systemPrompt = testStates[testId]?.prompt || ""

    // Format conversations first
    const formattedConvs = conversations
      .map((conv, index) => {
        // Format the date from conversation start time
        const date = conv.startTime
          ? new Date(conv.startTime).toISOString().split("T")[0]
          : "unknown"

        let messages = conv.messages
          .filter((msg) => {
            const messageType = msg.userType.toLowerCase()
            const allowedTypes = formatParams.userTypes.map((t) =>
              t.toLowerCase(),
            )
            return allowedTypes.includes(messageType)
          })
          .filter((msg) => msg.textRaw || msg.text)
          .filter((msg) => formatParams.isAutomated.includes(msg.isAutomated))
          .filter((msg) =>
            formatParams.isAfterBillie.includes(msg.isAfterBillie),
          )
          .map((msg) => {
            const text = msg.textRaw || msg.text
            const userType = msg.userType.toLowerCase()
            const orderAttr = formatParams.transcriptOrder
              ? ` row="${msg.transcriptOrder}"`
              : ""
            return `<${userType}${orderAttr}>${text}</${userType}>`
          })
          .join("\n")

        if (
          formatParams.maxCharacters > 0 &&
          messages.length > formatParams.maxCharacters
        ) {
          // Find the last opening tag before truncation point
          const truncatePoint = formatParams.maxCharacters
          const lastMessageStart = messages.lastIndexOf("<", truncatePoint)

          if (lastMessageStart > 0) {
            // Extract the tag name from the last message
            const tagMatch = messages
              .substring(lastMessageStart)
              .match(/<(\w+)[^>]*>/)
            if (tagMatch) {
              const tagName = tagMatch[1]
              // Find the content end for this message
              const contentEnd = messages.indexOf(
                `</${tagName}>`,
                lastMessageStart,
              )
              if (contentEnd > 0) {
                // Get the full opening tag
                const openingTagEnd =
                  messages.indexOf(">", lastMessageStart) + 1
                const openingTag = messages.substring(
                  lastMessageStart,
                  openingTagEnd,
                )

                // Get the content and truncate it
                const content = messages.substring(openingTagEnd, contentEnd)
                const truncatedContent =
                  content.substring(
                    0,
                    formatParams.maxCharacters - lastMessageStart,
                  ) + "..."

                // Keep all complete messages before this one and add the truncated message
                messages =
                  messages.substring(0, lastMessageStart) +
                  openingTag +
                  truncatedContent +
                  `</${tagName}>`
              }
            }
          }
        }

        return `<conversation id="${index + 1}" date="${date}">\n\n${messages}\n\n</conversation>`
      })
      .join("\n\n")

    // Get the prediction label ID from the test's filter
    let test: TestCase | undefined
    try {
      if (
        configJson.trim().startsWith("<?xml") ||
        configJson.trim().startsWith("<tests>")
      ) {
        // Parse XML safely
        const processedXml = processXmlContent(configJson)
        const sanitizedXml = DOMPurify.sanitize(processedXml, {
          USE_PROFILES: {},
          ALLOWED_TAGS: [
            "tests",
            "test",
            "filter",
            "format",
            "model",
            "promptfile",
          ],
          ALLOWED_ATTR: ["id"],
        })
        const xmlDoc = new DOMParser().parseFromString(sanitizedXml, "text/xml")
        const testElement = Array.from(
          xmlDoc.getElementsByTagName("test"),
        ).find((elem) => elem.getAttribute("id") === testId)
        if (testElement) {
          const testId = testElement.getAttribute("id") || crypto.randomUUID()
          test = {
            id: testId,
            test: testId,
            filter:
              testElement.getElementsByTagName("filter")[0]?.textContent || "",
            format:
              testElement.getElementsByTagName("format")[0]?.textContent || "",
            model:
              testElement.getElementsByTagName("model")[0]?.textContent ||
              "gpt-4o",
            promptFile:
              testElement.getElementsByTagName("promptFile")[0]?.textContent ||
              "",
          } as TestCase
        }
      } else {
        // Parse JSON
        const config = JSON.parse(configJson)
        test = (Array.isArray(config) ? config : config.tests).find(
          (t: TestCase) => t.id === testId,
        )
      }
    } catch (error) {
      console.error(
        "Error parsing config in formatConversationsWithParams:",
        error,
      )
    }

    const urlParams = new URLSearchParams(
      test?.filter.replace(/^[/?]/, "") || "",
    )
    const predictionLabelId = urlParams.get("prediction_label_id")
    const numericLabelId = predictionLabelId
      ? parseInt(predictionLabelId, 10)
      : null
    const labelDisplayName =
      numericLabelId && !isNaN(numericLabelId) && labels[numericLabelId]
        ? labels[numericLabelId].displayName
        : "No Label"

    // If the prompt contains {{CONVERSATIONS}} or {{topics}}, replace them
    let finalPrompt = systemPrompt
    if (finalPrompt.includes("{{CONVERSATIONS}}")) {
      finalPrompt = finalPrompt.replace("{{CONVERSATIONS}}", formattedConvs)
    }
    if (finalPrompt.includes("{{topics}}")) {
      finalPrompt = finalPrompt.replace(/{{topics}}/g, labelDisplayName)
    }

    // Return the final prompt without the header
    return finalPrompt
  }

  const parseUrlParams = (params: string) => {
    try {
      // Remove leading '/' and '?' if present
      const cleanParams = params.replace(/^[/?]/, "")
      const searchParams = new URLSearchParams(cleanParams)
      const parsed: ParsedParams = {}

      searchParams.forEach((value, key) => {
        parsed[key] = value
      })

      // setParsedParams(parsed);
      return true
    } catch (error) {
      console.error("Error parsing URL parameters:", error)
      return false
    }
  }

  // Update handleRunTest to remove chat initialization
  const handleRunTest = async (test: TestCase) => {
    setTestStates((prev) => ({
      ...prev,
      [test.id]: {
        ...prev[test.id],
        isFetching: true,
        hasFetched: false,
        isEvaluating: false,
        evaluationResult: "",
        isFormattedExpanded: false,
        isEvaluationExpanded: false,
        error: null,
        status: "running",
        chatMessages: [],
        showDebug: false,
      },
    }))

    try {
      // First fetch the conversations
      const cleanParams = test.filter.replace(/^[/?]/, "")
      const searchParams = new URLSearchParams(cleanParams)
      const parsed: ParsedParams = {}
      searchParams.forEach((value, key) => {
        parsed[key] = value
      })

      // Get label information early
      const predictionLabelId = searchParams.get("prediction_label_id")
      const numericLabelId = predictionLabelId
        ? parseInt(predictionLabelId, 10)
        : null
      const labelDisplayName =
        numericLabelId && !isNaN(numericLabelId) && labels[numericLabelId]
          ? labels[numericLabelId].displayName
          : "No Label"

      const filters: KeyValueType[] = Object.entries(parsed).map(
        ([key, value]) => ({
          key,
          value,
        }),
      )

      // const result = await listConversations(filters)
      console.log(`filters: ${filters}`)
      const result = await fetchConversations(filters)
      const fetchedConversations = result.conversations
      console.log(`fetchedConversations:${fetchedConversations}`)
      const conversationCount = fetchedConversations.length

      // Check if no conversations were returned
      if (!fetchedConversations || fetchedConversations.length === 0) {
        setTestStates((prev) => ({
          ...prev,
          [test.id]: {
            ...prev[test.id],
            isFetching: false,
            isEvaluating: false,
            hasFetched: false,
            evaluationResult: `# Test ${test.test}\n\n❌ Test failed: No conversations found for the given filter criteria.`,
            error:
              "No conversations found. Please check your filter parameters.",
            status: "failed",
          },
        }))
        return
      }

      // Format conversations with test ID
      const formatParams = parseFormatParams(test.format)
      const formattedConvs = formatConversationsWithParams(
        fetchedConversations,
        formatParams,
        test.id,
      )

      // Update state with fetched data
      setTestStates((prev) => ({
        ...prev,
        [test.id]: {
          ...prev[test.id],
          isFetching: false,
          hasFetched: true,
          isEvaluating: true,
          conversations: fetchedConversations,
          formattedConversations: formattedConvs,
          error: null,
        },
      }))

      const evalResult = await insightsSummary({
        insights: [formattedConvs],
        prompt: testStates[test.id].prompt,
        model: "gpt-4o-mini",
      })

      if (!evalResult.data) {
        setTestStates((prev) => ({
          ...prev,
          [test.id]: {
            ...prev[test.id],
            isEvaluating: false,
            evaluationResult: `# Test ${test.test}\n\n❌ No response data received`,
          },
        }))
        return
      }

      const analysis = evalResult.data.analysis || "No analysis generated"
      const truncatedPrompt = testStates[test.id].prompt
      const displayPrompt =
        truncatedPrompt.length > 70
          ? `${truncatedPrompt.substring(0, 70)}...`
          : truncatedPrompt

      // Clean the analysis text by removing XML tags
      const cleanAnalysis = analysis.replace(/<\/?[^>]+(>|$)/g, "")

      // Construct the evaluation result
      const resultWithMetadata = `# Test ${test.test}

### Filter Parameters

- **Label:** ${labelDisplayName}
- **Number of Conversations:** ${conversationCount}
- **Date Range:** ${searchParams.get("from_start_time")} to ${searchParams.get("to_start_time")}
- **Country:** ${searchParams.get("country")}
- **Channel:** ${searchParams.get("channel")}

## Configuration

- **Prompt:** ${displayPrompt}
- **Model:** ${test.model}
- **Prompt File:** ${test.promptFile}
- **Loaded Prompt:** ${
        testStates[test.id]?.prompt
          ? testStates[test.id].prompt.length > 75
            ? `${testStates[test.id].prompt.substring(0, 75)}...`
            : testStates[test.id].prompt
          : "Loading..."
      }

## Analysis

${cleanAnalysis}`

      setTestStates((prev) => ({
        ...prev,
        [test.id]: {
          ...prev[test.id],
          isEvaluating: false,
          evaluationResult: resultWithMetadata,
          isEvaluationExpanded: true,
          status: "success",
        },
      }))
    } catch (error) {
      console.error("Error running test:", error)
      setTestStates((prev) => ({
        ...prev,
        [test.id]: {
          ...prev[test.id],
          isFetching: false,
          isEvaluating: false,
          hasFetched: false,
          evaluationResult: `# Test ${test.id}\n\n❌ Error running test. Please make sure your configuration is valid and try again.`,
          error:
            "Failed to run test. Make sure you have the required filters (time frame and country).",
          status: "failed",
        },
      }))
    }
  }

  // Add new function to run all tests
  const handleRunAllTests = async (tests: TestCase[]) => {
    for (const test of tests) {
      await handleRunTest(test)
    }
  }

  // Update handleFileUpload to store the filename
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setInputFileName(file.name.replace(/\.[^/.]+$/, "")) // Remove file extension
      const reader = new FileReader()
      reader.onload = (e) => {
        const content = e.target?.result as string
        handleConfigChange(content)
      }
      reader.readAsText(file)
    }
    // Reset the file input so the same file can be uploaded again
    event.target.value = ""
  }

  // Add new function to handle expansion toggle
  const handleExpandFormatted = (testId: string) => {
    setTestStates((prev) => ({
      ...prev,
      [testId]: {
        ...prev[testId],
        isFormattedExpanded: !prev[testId].isFormattedExpanded,
      },
    }))
  }

  // Add new function to handle evaluation expansion toggle
  const handleExpandEvaluation = (testId: string) => {
    setTestStates((prev) => ({
      ...prev,
      [testId]: {
        ...prev[testId],
        isEvaluationExpanded: !prev[testId].isEvaluationExpanded,
      },
    }))
  }

  // Update handleSendMessage to use the correct API request format
  const handleSendMessage = async (testId: string, message: string) => {
    if (!message.trim()) return

    // Get the current test state
    const currentState = testStates[testId]
    if (!currentState?.evaluationResult) {
      console.error("No evaluation result available for chat")
      return
    }

    console.log("Starting chat message processing for test:", testId)

    // Create a copy of current chat messages
    const updatedChatMessages = [...(currentState.chatMessages || [])]

    // Add user message
    updatedChatMessages.push({ type: "user", content: message })

    // Update state with user message immediately
    setTestStates((prev) => ({
      ...prev,
      [testId]: {
        ...prev[testId],
        chatMessages: updatedChatMessages,
      },
    }))

    try {
      // Validate conversations data
      if (
        !currentState.conversations ||
        currentState.conversations.length === 0
      ) {
        throw new Error("No conversations available for analysis")
      }

      // Create a simplified conversation prompt
      const conversationPrompt = `Here are the conversations and their evaluation results. Please analyze them to answer the user's question:

Evaluation Results:
${currentState.evaluationResult}

User Question: ${message}

Please provide a specific answer based on the evaluation results above.`

      console.log("Sending chat request with prompt:", {
        promptLength: conversationPrompt.length,
        hasEvaluation: !!currentState.evaluationResult,
        messageLength: message.length,
      })

      // Call the API with the simplified format
      const response = await insightsSummary({
        insights: [conversationPrompt],
        prompt:
          "You are a helpful assistant analyzing evaluation results. Provide clear and specific answers based on the evaluation results provided.",
        model: "gpt-4o-mini",
      })

      console.log("Raw API response:", response)
      console.log("API Response details:", {
        hasData: !!response?.data,
        hasAnalysis: !!response?.data?.analysis,
        analysisLength: response?.data?.analysis?.length || 0,
        responseKeys: Object.keys(response?.data || {}),
      })

      if (!response?.data) {
        throw new Error("API response is missing data")
      }

      if (!response.data.analysis) {
        console.warn("Empty analysis received from API")
        updatedChatMessages.push({
          type: "assistant",
          content:
            "I apologize, but I'm having trouble processing your request. Could you try asking a more specific question about the evaluation results?",
        })
      } else {
        updatedChatMessages.push({
          type: "assistant",
          content: response.data.analysis,
        })
        console.log("Successfully added response to chat")
      }

      // Update state with assistant response
      setTestStates((prev) => ({
        ...prev,
        [testId]: {
          ...prev[testId],
          chatMessages: updatedChatMessages,
        },
      }))
    } catch (error) {
      console.error("Error in chat processing:", error)

      updatedChatMessages.push({
        type: "assistant",
        content: `I encountered an error while processing your request: ${error instanceof Error ? error.message : "Unknown error"}. Please try asking a more specific question about the evaluation results.`,
      })

      setTestStates((prev) => ({
        ...prev,
        [testId]: {
          ...prev[testId],
          chatMessages: updatedChatMessages,
        },
      }))
    }
  }

  // Add toggle debug function
  const handleToggleDebug = (testId: string) => {
    setTestStates((prev) => ({
      ...prev,
      [testId]: {
        ...prev[testId],
        showDebug: !prev[testId].showDebug,
      },
    }))
  }

  // Add reset chat function
  const handleResetChat = (testId: string) => {
    setTestStates((prev) => ({
      ...prev,
      [testId]: {
        ...prev[testId],
        chatMessages: [],
        showDebug: false,
      },
    }))
  }

  const handleSaveToFirebase = async (test: TestCase, testState: TestState) => {
    const testId = test.id

    try {
      console.log("Starting Firebase save operation:", {
        testId: test.id,
        testLabel: test.test,
        hasEvaluationResult: !!testState.evaluationResult,
        hasFormattedConversations: !!testState.formattedConversations,
      })

      // Update state to show saving
      setTestStates((prev) => ({
        ...prev,
        [testId]: {
          ...prev[testId],
          isSaving: true,
          error: null,
        },
      }))

      // Extract market and test label from the filter
      const urlParams = new URLSearchParams(test.filter.replace(/^[/?]/, ""))
      const country = urlParams.get("country") || "Unknown"
      const predictionLabelId =
        urlParams.get("prediction_label_id") || "Unknown"

      if (country === "Unknown" || predictionLabelId === "Unknown") {
        throw new Error("Invalid country or prediction label ID in filter")
      }

      // Prepare the report data
      const reportData = {
        testType: test.test,
        country,
        filter: test.filter,
        formattedConversations: testState.formattedConversations,
        evaluationResults: testState.evaluationResult,
        metadata: {
          country,
          predictionLabelId,
          channel: urlParams.get("channel"),
          dateRange: {
            from: urlParams.get("from_start_time"),
            to: urlParams.get("to_start_time"),
          },
        },
      }

      // Call the cloud function
      const result = await saveReportFn(reportData)

      console.log("Successfully saved report:", result)

      // Show success state
      setTestStates((prev) => ({
        ...prev,
        [testId]: {
          ...prev[testId],
          isSaving: false,
          error: null,
        },
      }))
    } catch (error) {
      console.error("Error in handleSaveToFirebase:", error)
      setTestStates((prev) => ({
        ...prev,
        [testId]: {
          ...prev[testId],
          isSaving: false,
          error:
            error instanceof Error
              ? error.message
              : "An unknown error occurred",
        },
      }))
    }
  }

  return (
    <>
      <Titlebar title={t("buttons:promptBuilder")} />
      <PageAnimation>
        <div className="flex-column" style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              marginBottom: "1rem",
              alignItems: "center",
            }}
          >
            <input
              type="file"
              accept=".json,text/json,.xml,text/xml,application/xml"
              onChange={handleFileUpload}
              style={{ display: "none" }}
              id="config-file-upload"
            />
            <StyledButton
              variant="contained"
              onClick={() =>
                document.getElementById("config-file-upload")?.click()
              }
            >
              Upload Config
            </StyledButton>
          </div>

          {isConfigValid ? (
            <div style={{ marginBottom: "1rem" }}>
              {(() => {
                try {
                  console.log(
                    "Attempting to parse in render:",
                    configJson.substring(0, 100),
                  )
                  let config: ConfigJson
                  if (
                    configJson.trim().startsWith("<?xml") ||
                    configJson.trim().startsWith("<tests>")
                  ) {
                    console.log("Parsing XML in render...")
                    config = parseXmlConfig(configJson)
                  } else {
                    console.log("Parsing JSON in render...")
                    const parsed = JSON.parse(configJson)
                    config = Array.isArray(parsed) ? { tests: parsed } : parsed
                  }

                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <Text tagName="h3" style={{ margin: 0 }}>
                          Loaded Test Cases:
                        </Text>
                        <div style={{ display: "flex", gap: "1rem" }}>
                          <StyledButton
                            variant="contained"
                            onClick={() => handleRunAllTests(config.tests)}
                            className="primary"
                          >
                            Run All Tests
                          </StyledButton>
                        </div>
                      </div>
                      {config.tests.map((test) => {
                        const testState = testStates[test.id] || {
                          isFetching: false,
                          hasFetched: false,
                          isEvaluating: false,
                          conversations: [],
                          formattedConversations: "",
                          evaluationResult: "",
                          error: null,
                          isFormattedExpanded: false,
                          isEvaluationExpanded: false,
                          status: "idle",
                          prompt: "",
                          chatMessages: [],
                          showDebug: false,
                        }

                        return (
                          <Paper
                            key={test.id}
                            elevation={2}
                            style={{
                              padding: "1.5rem",
                              marginBottom: "1rem",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <Text tagName="h4" style={{ margin: 0 }}>
                                Test {test.test}
                              </Text>
                              <div style={{ display: "flex", gap: "0.5rem" }}>
                                {testState.hasFetched &&
                                  testState.evaluationResult && (
                                    <>
                                      <StyledButton
                                        onClick={() =>
                                          handleSaveToFirebase(test, testState)
                                        }
                                        disabled={testState.isSaving}
                                        className={
                                          testState.isSaving ? "" : "primary"
                                        }
                                      >
                                        {testState.isSaving
                                          ? "Saving..."
                                          : "Save to Firebase"}
                                      </StyledButton>
                                    </>
                                  )}
                                <StyledButton
                                  onClick={() => handleRunTest(test)}
                                  disabled={
                                    testState.isFetching ||
                                    testState.isEvaluating
                                  }
                                  className={`${testState.status === "success" ? "success" : testState.status === "failed" ? "error" : "primary"} test-${test.test}`}
                                  data-testid={`run-test-${test.test}`}
                                >
                                  {testState.isFetching
                                    ? "Fetching..."
                                    : testState.isEvaluating
                                      ? "Evaluating..."
                                      : testState.status === "success"
                                        ? "Run Again"
                                        : testState.status === "failed"
                                          ? "Retry"
                                          : "Run Test"}
                                </StyledButton>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "1rem",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "flex-start",
                                }}
                              >
                                <div>
                                  <strong>Model:</strong> {test.model}
                                  <div>
                                    <strong>Prompt File:</strong>{" "}
                                    {test.promptFile}
                                  </div>
                                  <div>
                                    <strong>Filter:</strong> {test.filter}
                                  </div>
                                  <div>
                                    <strong>Format:</strong> {test.format}
                                  </div>
                                  <div>
                                    <strong>Loaded Prompt:</strong>{" "}
                                    {testStates[test.id]?.prompt
                                      ? testStates[test.id].prompt.length > 75
                                        ? `${testStates[test.id].prompt.substring(0, 75)}...`
                                        : testStates[test.id].prompt
                                      : "Not loaded"}
                                  </div>
                                  {testState.error && (
                                    <div
                                      style={{
                                        color: "red",
                                        marginTop: "0.5rem",
                                      }}
                                    >
                                      {testState.error}
                                    </div>
                                  )}
                                </div>
                              </div>

                              {testState.hasFetched && (
                                <>
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom: "0.5rem",
                                      }}
                                    >
                                      <Text tagName="h5" style={{ margin: 0 }}>
                                        Formatted Conversations
                                      </Text>
                                      <IconButton
                                        onClick={() =>
                                          handleExpandFormatted(test.id)
                                        }
                                        size="small"
                                      >
                                        {testState.isFormattedExpanded ? (
                                          <ExpandLessIcon />
                                        ) : (
                                          <ExpandMoreIcon />
                                        )}
                                      </IconButton>
                                    </div>
                                    <Collapse
                                      in={testState.isFormattedExpanded}
                                    >
                                      <TextField
                                        value={testState.formattedConversations}
                                        multiline
                                        rows={10}
                                        fullWidth
                                        variant="outlined"
                                        InputProps={{
                                          readOnly: true,
                                          style: {
                                            fontFamily: "monospace",
                                            overflow: "auto",
                                          },
                                        }}
                                      />
                                    </Collapse>
                                  </div>

                                  {testState.evaluationResult && (
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          marginBottom: "0.5rem",
                                        }}
                                      >
                                        <Text
                                          tagName="h5"
                                          style={{ margin: 0 }}
                                        >
                                          Evaluation Results
                                        </Text>
                                        <IconButton
                                          onClick={() =>
                                            handleExpandEvaluation(test.id)
                                          }
                                          size="small"
                                        >
                                          {testState.isEvaluationExpanded ? (
                                            <ExpandLessIcon />
                                          ) : (
                                            <ExpandMoreIcon />
                                          )}
                                        </IconButton>
                                      </div>
                                      <Collapse
                                        in={testState.isEvaluationExpanded}
                                      >
                                        <Paper
                                          elevation={0}
                                          style={{
                                            padding: "1rem",
                                            backgroundColor: "#f8f9fa",
                                            border: "1px solid #e9ecef",
                                            borderRadius: "4px",
                                          }}
                                        >
                                          <ReactMarkdown>
                                            {testState.evaluationResult}
                                          </ReactMarkdown>
                                        </Paper>
                                        {testState.hasFetched &&
                                          testState.evaluationResult && (
                                            <ChatInterface
                                              test={test}
                                              testState={testState}
                                              onSendMessage={handleSendMessage}
                                              onResetChat={handleResetChat}
                                              onToggleDebug={handleToggleDebug}
                                            />
                                          )}
                                      </Collapse>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </Paper>
                        )
                      })}
                    </div>
                  )
                } catch (err: unknown) {
                  const error = err as Error
                  console.error("Error parsing in render:", error)
                  return null
                }
              })()}
            </div>
          ) : (
            <TextField
              label="Or paste configuration here"
              value={configJson}
              onChange={(e) => handleConfigChange(e.target.value)}
              placeholder={`Example JSON:
[
  {
    "test": "1",
    "filter": "/?from_start_time=2024-12-01&to_start_time=2025-01-07&channel=Chat&country=CA&limit=10",
    "format": "userType={agent,customer}, transcriptOrder=false, isAutomated=false, max_characters=1000",
    "model": "gpt-4o",
    "promptFile": "prompts/analyze_conversation.txt"
  }
]

Example XML:
<?xml version="1.0" encoding="UTF-8"?>
<tests>
  <test id="1">
    <filter>/?from_start_time=2024-12-01&to_start_time=2025-01-07&channel=Chat&country=CA&limit=10</filter>
    <format>userType={agent,customer}, transcriptOrder=false, isAutomated=false, max_characters=1000</format>
    <model>gpt-4o</model>
    <promptFile>prompts/analyze_conversation.txt</promptFile>
  </test>
</tests>`}
              multiline
              rows={15}
              fullWidth
              variant="outlined"
              InputProps={{
                style: { fontFamily: "monospace" },
              }}
            />
          )}

          {fetchError && (
            <div style={{ marginTop: "1rem", color: "red" }}>
              <Text>{fetchError}</Text>
            </div>
          )}
        </div>
      </PageAnimation>
    </>
  )
}

export default PromptBuilder
