import InsightsMessage from "features/insights/InsightsMessage"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import { useTranslation } from "react-i18next"
import OverlayDetailsPane, {
  OverlayDetailsState,
} from "components/SheetViewDetails"

const HistoryChatView = () => {
  const { t } = useTranslation()
  const insightsState = useAppSelector((state: RootState) => state.insights)

  const { previewedThread, previewedThreadIsEmpty, renderableMessages } =
    useInsightsHelperFunctions()

  const visible = insightsState.chatHistoryVisible
  const showNoMessages = !previewedThread || previewedThreadIsEmpty
  const messages = renderableMessages(previewedThread?.chatMessages, true)

  const noMessages = (
    <div className="no-messages-label-parent" style={{ margin: "0" }}>
      <div className="no-messages-title">{t("labels:noPreviewedThread")}</div>
    </div>
  )

  const thread = (
    <div className="chat" style={{ height: "auto" }}>
      <div className="chat-content" style={{ width: "100%", height: "100%" }}>
        {messages?.map((message, messageIndex) => (
          <InsightsMessage
            key={messageIndex}
            messageContent={message.content}
            messageEditState={false}
            type={message.type}
            toolbar={null}
            showReport={true}
          />
        ))}
      </div>
    </div>
  )

  const state: OverlayDetailsState = showNoMessages
    ? "collapsed"
    : "fullyExpanded"

  return (
    <OverlayDetailsPane visible={visible} state={state}>
      {showNoMessages ? noMessages : thread}
    </OverlayDetailsPane>
  )
}

export default HistoryChatView
