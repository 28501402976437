import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { validMarkets } from "app/types"

export const useAuthHelpers = () => {
  const checkingAccessMarkets = useAppSelector(
    (state: RootState) => state.auth.checkingAccessMarkets,
  )

  const accessMarket = useAppSelector(
    (state: RootState) => state.auth.accessMarkets,
  )

  const accessibleMarkets = validMarkets.filter((market) =>
    accessMarket.includes(market),
  )

  const noAccessibleMarkets =
    accessibleMarkets.length === 0 && !checkingAccessMarkets

  return {
    checkingAccessMarkets,
    accessibleMarkets,
    noAccessibleMarkets,
  }
}
