import { JSX } from "react"
import React from "react"
import { Avatar, Typography } from "@mui/material"
import { Entity } from "../types"
import {
  MessageContainer,
  MessageContent,
  MessageBubbleWrapper,
  MessageBubble as StyledMessageBubble,
  TimeStamp,
} from "./StyledComponents"
import { formatDate } from "../utils/dateUtils"

interface HighlightedMessageProps {
  content: string
  entities: Entity[]
  transcriptOrder: number
}

const HighlightedMessage: React.FC<HighlightedMessageProps> = ({
  content,
  entities,
  transcriptOrder,
}) => {
  const messageEntities = entities.filter(
    (e) => e.transcriptOrder === transcriptOrder,
  )

  if (messageEntities.length === 0) {
    return (
      <Typography variant="body1" component="div" sx={{ userSelect: "text" }}>
        {content}
      </Typography>
    )
  }

  const positions: Array<{ start: number; end: number; entity: Entity }> =
    messageEntities
      .map((entity) => ({
        start: content.indexOf(entity.value),
        end: content.indexOf(entity.value) + entity.value.length,
        entity,
      }))
      .filter((pos) => pos.start !== -1)
      .sort((a, b) => a.start - b.start)

  if (positions.length === 0) {
    return (
      <Typography variant="body1" component="div" sx={{ userSelect: "text" }}>
        {content}
      </Typography>
    )
  }

  const parts: JSX.Element[] = []
  let currentIndex = 0

  positions.forEach((pos, index) => {
    if (pos.start > currentIndex) {
      parts.push(
        <span key={`text-${index}`}>
          {content.slice(currentIndex, pos.start)}
        </span>,
      )
    }

    parts.push(
      <span
        key={`highlight-${index}`}
        style={{
          backgroundColor: `${EntityColors[pos.entity.type]}40`,
          padding: "0 4px",
          margin: "0 -1px",
          borderRadius: 4,
          border: `2px solid ${EntityColors[pos.entity.type]}80`,
          fontWeight: 500,
        }}
      >
        {content.slice(pos.start, pos.end)}
      </span>,
    )

    currentIndex = pos.end
  })

  if (currentIndex < content.length) {
    parts.push(<span key="text-end">{content.slice(currentIndex)}</span>)
  }

  return (
    <Typography variant="body1" component="div" sx={{ userSelect: "text" }}>
      {parts}
    </Typography>
  )
}

export const EntityColors: Record<string, string> = {
  store: "#4CAF50",
  case_number: "#2196F3",
  order_number: "#FF9800",
  product: "#9C27B0",
  service: "#00BCD4",
  anonymization_failure: "#F44336",
}

interface MessageBubbleProps {
  message: {
    is_automated: boolean
    is_customer: boolean
    transcript_order: number
    timestamp?: { value: string }
    text?: string | null
    text_raw?: string | null
  }
  useTranslatedMessages: boolean
  entities: Entity[]
  isHighlighted?: boolean
}

export const MessageBubbleComponent = React.forwardRef<
  HTMLDivElement,
  MessageBubbleProps
>(({ message, useTranslatedMessages, entities, isHighlighted }, ref) => {
  const messageContent = useTranslatedMessages
    ? (message.text ?? "[No Translation]")
    : (message.text_raw ?? "[No Message Content]")
  const isCustomer = message.is_customer
  const isAutomated = message.is_automated

  const avatar = isAutomated ? (
    <Avatar sx={{ bgcolor: "#955", flexShrink: 0 }}>S</Avatar>
  ) : isCustomer ? (
    <Avatar sx={{ bgcolor: "grey.500", flexShrink: 0 }}>C</Avatar>
  ) : (
    <Avatar sx={{ bgcolor: "primary.main", flexShrink: 0 }}>A</Avatar>
  )

  return (
    <MessageContainer
      ref={ref}
      data-transcript-order={message.transcript_order}
      sx={{
        transition: "background-color 0.3s ease",
        backgroundColor: isHighlighted ? "action.hover" : "transparent",
      }}
    >
      <MessageContent isCustomer={isCustomer}>
        {isCustomer && avatar}
        <MessageBubbleWrapper isCustomer={isCustomer}>
          <StyledMessageBubble
            isCustomer={isCustomer}
            isAutomated={isAutomated}
          >
            <HighlightedMessage
              content={messageContent}
              entities={entities}
              transcriptOrder={message.transcript_order}
            />
          </StyledMessageBubble>
          <TimeStamp
            variant="caption"
            sx={{
              textAlign: isCustomer ? "left" : "right",
              display: "block",
              width: "100%",
            }}
          >
            {formatDate(message.timestamp?.value, "p")}
          </TimeStamp>
        </MessageBubbleWrapper>
        {!isCustomer && avatar}
      </MessageContent>
    </MessageContainer>
  )
})

MessageBubbleComponent.displayName = "MessageBubbleComponent"

export default MessageBubbleComponent
