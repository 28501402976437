import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { useTranslation } from "react-i18next"
import LogOutButton from "features/auth/LogOutButton"
import { useNavigate } from "react-router-dom"
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material"
import React from "react"
import Text from "@ingka/text"
import { useDispatch } from "react-redux"
import { toggleMarketSettingsVisiblity } from "features/admin/marketSettingsSlice"

const UserNavigation = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isInternal, user } = useAppSelector((state: RootState) => state.auth)
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const userDisplayName = user?.displayName
    ? user?.displayName?.replace(/\s*\(.*?\)\s*/g, "").trim()
    : user?.email

  return (
    <div className="nav-links">
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={t("buttons:settings")}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              {userDisplayName ? userDisplayName[0] : "?"}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        variant="menu"
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              maxWidth: "20vw",
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45 deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {isInternal && (
          <MenuItem dense style={{ justifyContent: "center" }}>
            <Text
              style={{ margin: "0" }}
              onClick={() => {
                navigate("/admin")
                handleClose()
              }}
              bodySize="s"
            >
              {t("buttons:admin")}
            </Text>
          </MenuItem>
        )}
        {isInternal && <Divider />}
        {isInternal && (
          <MenuItem dense style={{ justifyContent: "center" }}>
            <Text
              style={{ margin: "0" }}
              onClick={() => {
                navigate("/prompt-builder")
                handleClose()
              }}
              bodySize="s"
            >
              {t("buttons:promptBuilder")}
            </Text>
          </MenuItem>
        )}
        {isInternal && (
          <MenuItem dense style={{ justifyContent: "center" }}>
            <Text
              style={{ margin: "0" }}
              onClick={() => {
                navigate("/label")
                handleClose()
              }}
              bodySize="s"
            >
              {t("buttons:label")}
            </Text>
          </MenuItem>
        )}
        {isInternal && <Divider />}
        <MenuItem>
          <Text
            style={{ margin: "0" }}
            onClick={() => {
              window.open(t("buttons:helpHref"))?.focus()
              handleClose()
            }}
            bodySize="s"
          >
            {t("buttons:help")}
          </Text>
        </MenuItem>
        {isInternal && (
          <MenuItem>
            <Text
              style={{ margin: "0" }}
              onClick={() => {
                dispatch(toggleMarketSettingsVisiblity())
                handleClose()
              }}
              bodySize="s"
            >
              {t("buttons:marketAdministration")}
            </Text>
          </MenuItem>
        )}
        <Divider />
        <MenuItem dense>
          <LogOutButton />
        </MenuItem>
      </Menu>
    </div>
  )
}

export default UserNavigation
