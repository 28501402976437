import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { KeyValueType } from "app/types"
import { ChangeEvent, useCallback } from "react"
import { purify } from "utils/sanitize"
import ConversationItem from "../ConversationItem"
import {
  fetchConversations,
  setSelectedConversation,
} from "../listConversationSlice"
import "../styles/conversationList.css"
import { useTranslation } from "react-i18next"
import ResultsList from "./ResultsList"
import Text from "@ingka/text"
import { Pagination } from "@mui/material"
import { PAGINATION_THRESHOLD } from "../listConversationConsts"
import ConversationSearch from "features/conversationSearch/ConversationSearch"
import ConversationsSkeleton from "../ConversationsSkeleton"
import { setMessages } from "features/messages/messagesSlice"
import useConversationsHelperFunctions from "../hooks/useConversationsHelperFunctions"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"

const FiltersResultsSection: React.FC = ({}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const listConversationState = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const conversations = listConversationState.conversations ?? []
  const showManualTag = listConversationState.filters.some(
    (filter) => filter.key === "manual_tag_label_id",
  )
  const { conversationsAvailable } = useConversationsHelperFunctions()
  const { prePromptedInsights } = useInsightsHelperFunctions()

  const numberFormatter = new Intl.NumberFormat("en-SE", {
    useGrouping: true,
    minimumFractionDigits: 0,
  })

  const conversationSummary = t("text:conversationSummary", {
    conversationsLength: numberFormatter.format(conversations.length),
    totalConversations: numberFormatter.format(
      listConversationState.totalAmountOfConversations,
    ),
  })

  const handlePaginationChange = useCallback(
    (_: ChangeEvent<unknown>, page: number) => {
      const pageParam: KeyValueType = {
        key: "page",
        value: purify(page),
      }
      const updatedFilters = [...listConversationState.filters, pageParam]
      dispatch(setSelectedConversation(undefined))
      dispatch(setMessages([]))
      dispatch(fetchConversations(updatedFilters))
    },
    [listConversationState.filters, dispatch],
  )

  const resultsHeader = conversationsAvailable ? (
    <div className="component-scaler">
      <div className="flex-row spaced wrapped">
        <Text
          tagName="p"
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            flex: "1",
            minWidth: "0",
          }}
        >
          {conversationSummary}
        </Text>
        <div style={{ flexShrink: 0, display: "flex" }}>
          <Pagination
            onChange={handlePaginationChange}
            count={Math.ceil(
              listConversationState.totalAmountOfConversations /
                PAGINATION_THRESHOLD,
            )}
            disabled={listConversationState.loadingConversations}
            size="small"
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )

  const filterSection = conversationsAvailable ? (
    <div className="component-scaler" style={{ marginBottom: ".125rem" }}>
      <ConversationSearch key="search" />
    </div>
  ) : (
    <></>
  )

  const resultsSkeleton = (
    <div style={{ flexGrow: 1, marginTop: ".25rem" }}>
      <ConversationsSkeleton />
    </div>
  )

  const results = conversationsAvailable ? (
    conversations.map((conversation) => (
      <div key={conversation.id}>
        <ConversationItem
          showManualTagId={showManualTag}
          conversation={conversation}
          insight={
            prePromptedInsights
              ? prePromptedInsights[conversation.id]
              : undefined
          }
        />
      </div>
    ))
  ) : (
    <></>
  )

  const resultsSection = listConversationState.loadingConversations
    ? resultsSkeleton
    : results

  return (
    <ResultsList
      results={[filterSection, resultsHeader, resultsSection, resultsHeader]
        .flat()
        .map((item, index) => (
          <div key={index}>{item}</div>
        ))}
    />
  )
}

export default FiltersResultsSection
