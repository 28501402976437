import Loading, { LoadingBall } from "@ingka/loading"
import { JSX } from "react"

const LoadingInstructionHandler = ({
  showLoading,
  loadingLabel,
  showInstruction,
  instructionLabel,
  children,
}: {
  showLoading: boolean
  loadingLabel: string
  showInstruction: boolean
  instructionLabel: string
  children: JSX.Element
}) => {
  const loadingAnimation = (
    <Loading color="primary" labelTransitions text="">
      <LoadingBall size="large" />
    </Loading>
  )

  const label = (
    <div
      className="no-messages-label-parent centered"
      style={{ flexDirection: "column", flexGrow: "1" }}
    >
      {showLoading && loadingAnimation}
      <p className="no-messages-title">
        {showLoading ? loadingLabel : instructionLabel}
      </p>
    </div>
  )

  const showLabel = showLoading || showInstruction

  return showLabel ? label : children
}

export default LoadingInstructionHandler
