import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import {
  resumeStoredLLMThread,
  fetchStoredLLMThreads,
  incrementStoredThreadHistoryPage,
  setReportMode,
} from "features/insights/insightsSlice"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import { deterministicallyOrdered } from "utils/filterUtils"

export const useStoredLLMThreads = () => {
  const dispatch = useAppDispatch()

  const user = useAppSelector((state: RootState) => state.auth.user)

  const filters = useAppSelector(
    (state: RootState) => state.listConversation.filters,
  )
  const selectedThread = useAppSelector(
    (state: RootState) => state.insights.selectedThread,
  )
  const initialThreadPersistCheckDone = useAppSelector(
    (state: RootState) => state.insights.initialThreadPersistCheckDone,
  )
  const storedThreadHistoryPage = useAppSelector(
    (state: RootState) => state.insights.storedThreadHistoryPage,
  )
  const storedThreadHistoryLastDoc = useAppSelector(
    (state: RootState) => state.insights.storedThreadHistoryLastDoc,
  )

  const { currentThreadIsWithoutReport } = useInsightsHelperFunctions()

  const shouldFetchThreadHistory = storedThreadHistoryPage === 0

  useEffect(() => {
    if (user && !initialThreadPersistCheckDone) {
      dispatch(
        resumeStoredLLMThread({
          userId: user.id,
          selectedThread: selectedThread,
          currentFilters: JSON.stringify(deterministicallyOrdered(filters)),
        }),
      )
    }
  }, [selectedThread])

  useEffect(() => {
    if (user && shouldFetchThreadHistory) {
      dispatch(
        fetchStoredLLMThreads({
          userId: user.id,
          page: storedThreadHistoryPage,
          fetchSilently: false,
          lastDoc: storedThreadHistoryLastDoc,
        }),
      )

      dispatch(incrementStoredThreadHistoryPage())
    }
  }, [])

  useEffect(() => {
    if (currentThreadIsWithoutReport) {
      dispatch(setReportMode(false))
    }
  }, [currentThreadIsWithoutReport])
}
