import { Slider } from "@mui/material"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { setTokensPerConversation } from "features/insights/insightsSlice"
import { useFormattedRawData } from "features/insights/utils/hooks/useFormattedRawData"

const TokensPerConvSelection = () => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { conversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  if (!conversations) return null

  const { formattedRawDataLength, formattedRawDataTokenCountLargestItem } =
    useFormattedRawData().settings

  // Used for debouncing the slider value to make it not lag (avoids triggering recalculation of useFormattedRawData while interacting with the slider)
  const [sliderValue, setSliderValue] = useState(
    insightsState.tokensPerConversation,
  )

  useEffect(() => {
    setSliderValue(insightsState.tokensPerConversation)
  }, [insightsState.tokensPerConversation])

  const handleSliderCommit = (
    _: Event | React.SyntheticEvent,
    newValue: number | number[],
  ) => {
    if (typeof newValue === "number") {
      dispatch(setTokensPerConversation(newValue))
    }
  }

  return (
    <div className="flex-row" style={{ width: "100%", gap: "1.5rem" }}>
      <div style={{ flexGrow: "1" }}>
        <div className="flex-row spaced" style={{ marginBottom: "1rem" }}>
          <label className="skapa-label scaled no-wrap">
            <span>Number of conversations:</span>
            <br /> {formattedRawDataLength} /{" "}
            {Object.keys(conversations).length}
          </label>
          <label
            className="skapa-label scaled no-wrap"
            style={{ textAlign: "right" }}
          >
            <span>Tokens per conversation:</span>
            <br />
            {sliderValue} / {formattedRawDataTokenCountLargestItem}
          </label>
        </div>

        <Slider
          id="amountSlider"
          value={sliderValue}
          min={100}
          max={formattedRawDataTokenCountLargestItem}
          step={100}
          onChange={(_, newValue) => {
            if (typeof newValue === "number") {
              setSliderValue(newValue)
            }
          }}
          onChangeCommitted={handleSliderCommit}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `${value} tokens`}
        />
      </div>
    </div>
  )
}

export default TokensPerConvSelection
