import RouteWrapperBase, { RouteProps, RouteType } from "./RouteWrapperBase"

const createRoute =
  (type: RouteType) =>
  ({ children }: RouteProps) => (
    <RouteWrapperBase type={type} children={children} />
  )

export const PrivateRoute = createRoute("private")
export const AdminRoute = createRoute("admin")
export const InternalRoute = createRoute("internal")
