import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import OverlayDetailsPane, {
  OverlayDetailsState,
} from "components/SheetViewDetails"
import InsightsTable from "features/insights/InsightsTable"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"

const AdvancedViewDetails = () => {
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { prePromptedInsights } = useInsightsHelperFunctions()

  const visible = insightsState.advancedControlsSheetVisible

  const state: OverlayDetailsState = prePromptedInsights
    ? "fullyExpanded"
    : insightsState.insightsLoading
      ? "slightlyVisible"
      : "collapsed"

  return (
    <OverlayDetailsPane visible={visible} state={state}>
      <InsightsTable
        loading={insightsState.insightsLoading}
        insights={prePromptedInsights ?? {}}
      />
    </OverlayDetailsPane>
  )
}

export default AdvancedViewDetails
