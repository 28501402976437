import Login from "features/auth/Login"
import Grid from "@mui/material/Grid"
import { motion } from "framer-motion"

const LoginPage = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5, delay: 1 }}
  >
    <Grid container justifyContent="center" alignItems="center" height="100vh">
      <Login />
    </Grid>
  </motion.div>
)

export default LoginPage
