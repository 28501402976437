import React from "react"
import {
  Paper,
  Typography,
  CircularProgress,
  Autocomplete,
  TextField,
  Box,
} from "@mui/material"
import { Label } from "app/types"

interface LabelSelectorProps {
  conversationId: string
  selectedLabel: number | null
  labels: Label[]
  labelFilter: string
  loadingLabels: boolean
  labelError: string | null
  onLabelChange: (event: React.SyntheticEvent, value: Label | null) => void
  onLabelFilterChange: (value: string) => void
  autocompleteRef: React.RefObject<HTMLDivElement | null>
}

const LabelSelector: React.FC<LabelSelectorProps> = ({
  conversationId,
  selectedLabel,
  labels,
  labelFilter,
  loadingLabels,
  labelError,
  onLabelChange,
  onLabelFilterChange,
  autocompleteRef,
}) => {
  const label = labels.find((x) => x.label_id === selectedLabel)
  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Label Conversation
      </Typography>
      <Typography variant="body2" color="text.secondary" gutterBottom>
        ID: {conversationId}
      </Typography>
      {loadingLabels ? (
        <CircularProgress size={24} />
      ) : labelError ? (
        <Typography color="error">{labelError}</Typography>
      ) : (
        <Autocomplete
          ref={autocompleteRef}
          options={labels}
          getOptionLabel={(option) =>
            `${option.label_id} - ${option.main_label_name}::${option.label_name}`
          }
          groupBy={(option) => option.customer_journey_name}
          value={label || null}
          onChange={onLabelChange}
          inputValue={labelFilter}
          onInputChange={(_, value) => onLabelFilterChange(value)}
          isOptionEqualToValue={(option, value) =>
            option.label_id === value.label_id
          }
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              const input = event.target as HTMLInputElement
              setTimeout(() => {
                input.blur()
              }, 0)
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Label"
              variant="outlined"
              fullWidth
              size="small"
            />
          )}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.label_id}>
              <Box>
                <Typography variant="body1">{option.label_id} : {option.label_name}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {option.customer_journey_name} - {option.main_label_name}
                </Typography>
              </Box>
            </Box>
          )}
        />
      )}
    </Paper>
  )
}

export default LabelSelector
