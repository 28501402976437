import { Typography } from "@mui/material"
import { formatters } from "./utils/formatters"
import "./metricsTable.css"

const MetricsTable = ({
  selectedStatistics,
}: {
  selectedStatistics: StatisticsData[string] | null
}) => {
  if (!selectedStatistics) return null

  const excludedFields = [
    "conversations",
    "lastPeriod",
  ]

  const NA_VALUE = "N/A"

  const availableMetrics = Object.entries(selectedStatistics)
    .filter(
      ([key, value]) =>
        !excludedFields.includes(key) &&
        value?.toString().toLowerCase() !== NA_VALUE.toLowerCase(),
    )
    .map(([key, value]) => ({
      key,
      label: formatters.label(key),
      value: formatters.metrics(key, value),
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  return (
    <div className="metrics-table-container">
      <div className="metrics-table">
        {/* Header */}
        <div className="metrics-table-row metrics-table-header">
          {["Metric", "Value", "Change"].map((text, index) => (
            <Typography
              key={text}
              component="div"
              className={`metrics-table-cell metrics-table-header-cell ${
                index > 0 ? "metrics-table-value-cell" : ""
              }`}
            >
              {text}
            </Typography>
          ))}
        </div>

        {/* Data Rows */}
        {availableMetrics.map(({ key, label, value }) => (
          <div key={key} className="metrics-table-row">
            <Typography
              variant="body1"
              component="div"
              className="metrics-table-cell"
            >
              {label}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className="metrics-table-cell"
            >
              {value}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className="metrics-table-cell metrics-table-change"
            >
              {selectedStatistics.lastPeriod || "—"}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MetricsTable
