import React from "react"
import { ButtonGroup, Button, Box, TextField, MenuItem } from "@mui/material"
import ChevronLeft from "@mui/icons-material/ChevronLeft"
import ChevronRight from "@mui/icons-material/ChevronRight"
import { NavigationHeader } from "./StyledComponents"

interface NavigationControlsProps {
  selectedIndex: number
  totalConversations: number
  skipMode: "none" | "completed" | "labeled_and_completed"
  jumpToIndex: string
  onPrevious: () => void
  onNext: () => void
  onSkipModeChange: (
    mode: "none" | "completed" | "labeled_and_completed",
  ) => void
  onJumpToChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const NavigationControls: React.FC<NavigationControlsProps> = ({
  selectedIndex,
  totalConversations,
  skipMode,
  jumpToIndex,
  onPrevious,
  onNext,
  onSkipModeChange,
  onJumpToChange,
}) => {
  return (
    <NavigationHeader>
      <ButtonGroup
        variant="contained"
        fullWidth
        size="small"
        sx={{
          "& .MuiButton-root": {
            flex: 1,
            whiteSpace: "nowrap",
            minWidth: "auto",
            px: 1,
          },
        }}
      >
        <Button
          onClick={onPrevious}
          disabled={selectedIndex === 0}
          startIcon={<ChevronLeft />}
        >
          Prev
        </Button>
        <Button
          onClick={onNext}
          disabled={selectedIndex === totalConversations - 1}
          endIcon={<ChevronRight />}
        >
          Next
        </Button>
      </ButtonGroup>
      <Box sx={{ display: "flex", gap: 1 }}>
        <TextField
          select
          size="small"
          fullWidth
          label="Skip Mode"
          value={skipMode}
          onChange={(e) => onSkipModeChange(e.target.value as typeof skipMode)}
        >
          <MenuItem value="none">Show All</MenuItem>
          <MenuItem value="completed">Skip Completed</MenuItem>
          <MenuItem value="labeled_and_completed">
            Skip Labeled or Completed
          </MenuItem>
        </TextField>
      </Box>
      <TextField
        size="small"
        fullWidth
        type="number"
        label="Jump to conversation"
        value={jumpToIndex}
        onChange={onJumpToChange}
        InputProps={{
          inputProps: { min: 1, max: totalConversations },
        }}
      />
    </NavigationHeader>
  )
}

export default NavigationControls
