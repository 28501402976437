import Button from "@ingka/button"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { InsightsUtilsRunners } from "../../utils/llmUtils/llmUtilRunners"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import { useTranslation } from "react-i18next"

const SendMessageButton = () => {
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { t } = useTranslation()
  const { runCancelEdit, runChatMessage } = InsightsUtilsRunners()
  const { inputDisabled } = useInsightsHelperFunctions()

  return (
    <>
      {insightsState.messageEditIndex !== null && (
        <Button
          disabled={insightsState.insightsSummaryLoading}
          onClick={() => runCancelEdit()}
          type="secondary"
          small
        >
          {t("buttons:cancelEditPrompt")}
        </Button>
      )}

      <Button
        disabled={inputDisabled}
        onClick={() => runChatMessage()}
        type="primary"
        small
        fluid={true}
        style={{
          maxWidth:
            "calc(var(--navigation-width) - 2 * var(--horizontal-padding) - .25rem)",
          width: "100%",
          flexGrow: 1,
          flexShrink: 1,
        }}
      >
        {t("buttons:sendPrompt")}
      </Button>
    </>
  )
}

export default SendMessageButton
