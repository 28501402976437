import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { createThread } from "features/insights/insightsSlice"
import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { deterministicallyOrdered } from "utils/filterUtils"

export const useInitiateNewThread = () => {
  const dispatch = useDispatch()
  const listConversationState = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  const currentFiltersStringified = JSON.stringify(
    deterministicallyOrdered(listConversationState.filters),
  )
  const previousFiltersRef = useRef(currentFiltersStringified)

  const newSelectionOfInteractions =
    currentFiltersStringified !== previousFiltersRef.current &&
    !listConversationState.loadingConversations

  const initiateNewThread = newSelectionOfInteractions

  // Initiate new thread when sampled interactions has changed
  useEffect(() => {
    if (initiateNewThread) {
      previousFiltersRef.current = currentFiltersStringified
      dispatch(createThread(currentFiltersStringified))
    }
  }, [
    listConversationState.filters,
    listConversationState.loadingConversations,
  ])
}
