import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AuthResponse, IUser } from "./types"
import { doc, docExists } from "app/firebase"
import { getAccessMarketAdmin, getAccessGpt, getAccessMarkets } from "app/api"
import { market } from "app/types"

interface AuthState {
  user: IUser | null
  loading: boolean
  checkingAdminPermissions: boolean
  checkingInternalPermissions: boolean
  isAdmin: boolean
  isInternal: boolean
  error: string | null
  accessGpt: boolean
  checkingAccessGpt: boolean
  checkingAccessMarkets: boolean
  accessMarkets: market[]
  checkingAccessMarketAdmin: boolean
  accessMarketAdmin: boolean
}

const initialState: AuthState = {
  user: null,
  isAdmin: false,
  isInternal: false,
  loading: true,
  checkingAdminPermissions: true,
  checkingInternalPermissions: true,
  error: null,
  accessGpt: false,
  checkingAccessGpt: false,
  checkingAccessMarkets: false,
  accessMarkets: [],
  checkingAccessMarketAdmin: false,
  accessMarketAdmin: false,
}

export const checkIsAdmin = createAsyncThunk(
  "auth/checkIsAdmin",
  async (email: string) => {
    const docRef = doc("config", "admin", "users", email)
    return docExists(docRef)
  },
)

export const checkIsInternal = createAsyncThunk(
  "auth/checkIsInternal",
  async (email: string) => {
    const docRef = doc("config", "internal", "users", email)
    return docExists(docRef)
  },
)

export const checkAccessMarkets = createAsyncThunk(
  "auth/checkAccessMarkets",
  async () => {
    const result = await getAccessMarkets()
    return result.data
  },
)

export const checkAccessGpt = createAsyncThunk(
  "auth/checkAccessGpt",
  async () => {
    const result = await getAccessGpt()
    return result.data
  },
)

export const checkAccessMarketAdmin = createAsyncThunk(
  "auth/getAccessMarketAdminThunk",
  async (market: market) => {
    const result = await getAccessMarketAdmin(market)
    return result.data
  }
)

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true
    },
    loginSuccess: (state, action: PayloadAction<IUser>) => {
      state.loading = false
      state.user = action.payload
      state.error = null
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.loading = false
      state.user = null
      state.error = action.payload
    },
    logout: (state) => {
      state.user = null
      state.loading = false
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkIsInternal.pending, (state) => {
      if (state.checkingInternalPermissions === false) {
        state.checkingInternalPermissions = true
      }
    })
    builder.addCase(
      checkIsInternal.fulfilled,
      (state, action: PayloadAction<boolean>) => {
        state.checkingInternalPermissions = false
        state.isInternal = action.payload
      },
    )
    builder.addCase(checkIsInternal.rejected, (state) => {
      state.checkingInternalPermissions = false
      state.isInternal = false
    })
    builder.addCase(checkIsAdmin.pending, (state) => {
      if (state.checkingAdminPermissions === false) {
        state.checkingAdminPermissions = true
      }
    })
    builder.addCase(
      checkIsAdmin.fulfilled,
      (state, action: PayloadAction<boolean>) => {
        state.checkingAdminPermissions = false
        state.isAdmin = action.payload
      },
    )
    builder
      .addCase(checkAccessMarkets.pending, (state) => {
        state.checkingAccessMarkets = true
      })
      .addCase(
        checkAccessMarkets.fulfilled,
        (state, action: PayloadAction<AuthResponse>) => {
          state.checkingAccessMarkets = false
          state.accessMarkets = action.payload.valid_markets as market[]
        },
      )
      .addCase(checkAccessMarkets.rejected, (state) => {
        state.checkingAccessMarkets = false
        state.accessMarkets = []
      })
      .addCase(checkAccessGpt.pending, (state) => {
        state.checkingAccessGpt = true
      })
      .addCase(
        checkAccessGpt.fulfilled,
        (state, action: PayloadAction<boolean>) => {
          state.checkingAccessGpt = false
          state.accessGpt = action.payload
        },
      )
      .addCase(checkAccessGpt.rejected, (state) => {
        state.checkingAccessGpt = false
        state.accessGpt = false
      })
      .addCase(checkAccessMarketAdmin.pending, (state) => {
        state.checkingAccessMarketAdmin = true
        state.accessMarketAdmin = false
      })
      .addCase(
        checkAccessMarketAdmin.fulfilled,
        (state, action: PayloadAction<boolean>) => {
          state.checkingAccessMarketAdmin = false
          state.accessMarketAdmin = action.payload
        },
      )
      .addCase(checkAccessMarketAdmin.rejected, (state) => {
        state.checkingAccessMarketAdmin = false
        state.accessMarketAdmin = false
      })
  },
})

export const { loginStart, loginSuccess, loginFailure, logout } =
  authSlice.actions

export default authSlice.reducer
