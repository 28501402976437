import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { ChatThread, Message } from "features/insights/types"
import { formatPrePromptedInsights } from "../llmUtils/llmFormatting"
import { useFormattedRawData } from "./useFormattedRawData"

const useInsightsHelperFunctions = () => {
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { conversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  // Disabling condition for all LLM related inputs
  const inputDisabled =
    Object.keys(conversations ?? {}).length === 0 ||
    insightsState.insightsLoading ||
    insightsState.insightsSummaryLoading

  // Retrieval of objects
  const llmReport = insightsState.currentThread?.chatMessages.find(
    (message) => message.type === "report",
  )

  const llmReportIndex = insightsState.currentThread?.chatMessages.findIndex(
    (message) => message.type === "report",
  )

  const previewedThread = insightsState.chatThreads.find(
    ({ uuid }) => uuid === insightsState.previewedThread,
  )

  const prePromptedInsights =
    insightsState.currentThread?.insights &&
    Object.keys(insightsState.currentThread.insights).length !== 0
      ? insightsState.currentThread.insights
      : undefined

  // Creation and retrieval of data objects
  const rawDataMessage: Message = {
    content: useFormattedRawData().chat.formattedRawData,
    type: "raw-data",
  }

  let prePromtedDataMessage: Message | null = null

  if (prePromptedInsights) {
    prePromtedDataMessage = {
      content: formatPrePromptedInsights(prePromptedInsights),
      type: "pre-processed-data",
    }
  }

  const selectedDataMessage = prePromtedDataMessage ?? rawDataMessage // Defaults to using LLM pre-processed data when available

  // Thread checker
  const threadIsEmpty = (messages?: Message[], countReport = true) =>
    !messages ||
    !messages.some(
      (message) =>
        message.type === "received" ||
        message.type === "sent" ||
        message.type === "pre-processed-data" ||
        (countReport && message.type === "report"),
    )

  // Current LLM thread conditions
  const currentThreadIsEmpty = (countReport?: boolean) =>
    threadIsEmpty(insightsState.currentThread?.chatMessages, countReport)

  const currentThreadIsWithoutReport = !currentThreadIsEmpty() && !llmReport

  const currentThreadUsesPrePromptedInsights =
    selectedDataMessage.type === "pre-processed-data"

  const currentThreadUsingCustomPromptMode =
    !insightsState.reportMode || currentThreadIsWithoutReport

  // Stored LLM threads conditions
  const previewedThreadIsEmpty = threadIsEmpty(previewedThread?.chatMessages)
  const threadHistoryAvailable = Array.isArray(insightsState.chatThreads)

  // Renderering filters
  const renderableThreads = (threads?: ChatThread[]) => {
    return threads
      ?.filter((thread) => !threadIsEmpty(thread.chatMessages))
      .sort((a, b) => b.lastInteraction - a.lastInteraction)
  }

  const renderableMessages = (
    messages?: Message[],
    renderWithReport: boolean = false,
  ) => {
    return messages?.filter(
      (message) => !threadIsEmpty([message], renderWithReport),
    )
  }

  return {
    inputDisabled,
    llmReport,
    llmReportIndex,
    previewedThread,
    prePromptedInsights,
    selectedDataMessage,
    currentThreadIsEmpty,
    currentThreadIsWithoutReport,
    currentThreadUsesPrePromptedInsights,
    currentThreadUsingCustomPromptMode,
    previewedThreadIsEmpty,
    threadHistoryAvailable,
    renderableThreads,
    renderableMessages,
  }
}

export default useInsightsHelperFunctions
