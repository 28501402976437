import { LLMFormattingConfig } from "../../types"

export const defaultConfig: LLMFormattingConfig = {
  version: "1.6.0",
  instructions: {
    received: `<This is you, the LLM model, one of your previously given answers to me>${JSON.stringify("PLACEHOLDER")}<This is the end of your previously given answers to me>`,
    sent: {
      latest: `<This is the latest instruction sent from me, the user to be focused on in your answer>${JSON.stringify("PLACEHOLDER")}<This is the end of the latest instruction sent from me, the user to be focused on in your answer>`,
      previous: `<This is a previous instruction sent from me, the user>${JSON.stringify("PLACEHOLDER")}<This is the end of a previous instruction sent from me, the user>`,
    },
    rawData: `<This is formatted data extracted from a sample of support conversations from our system>${JSON.stringify("PLACEHOLDER")}<This is the end of the formatted data extracted from a sample of support conversations from our system>`,
    preProcessedData: `<This is LLM pre-processed data extracted from a sample of support conversations from our system>${JSON.stringify("PLACEHOLDER")}<This is the end of the LLM pre-processed data extracted from a sample of support conversations from our system>`,
    report: `<This is you, the LLM model. A report you generated for me on the supplied data>${JSON.stringify("PLACEHOLDER")}<This is the end of the report you previously generated for me>`,
  },
  systemPrompt:
    "GET STRAIGHT TO THE POINT. ONLY WHEN GENERATING THE REPORT USE HEADINGS ETC. OTHERWISE ANSWER MORE CONVERSATIONALLY, YOU THEN HAVE TO USE THE SMALLEST HEADING POSSIBLE. DO NOT ANSWER WITH MARKDOWN WITHIN MARKDOWN NEVER. ALWAYS JUST ANSWER AS YOU USUALLY WOULD DO. IF YOU DO NOT FOLLOW THESE ORDERS YOU WILL BE SEVERLY PUNISHED. NEVER MARKDOWN INSIDE MARKDOWN. NEVER NEVER. NEVER ANSWER WITH A CODE BLOCK EVER. NOT ALLOWED.",
}

export const llmReportTopics = (filters: string): string => {
  return `The given support interactions have been selected with the following filters: ${filters}, referenced to as {{{topics}}} in the prompt (if mentioned).`
}
