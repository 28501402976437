import { AnimatePresence, motion } from "framer-motion"
import { JSX } from "react"

const PageAnimation = ({
  children,
  duration = 0.3,
  movement = true,
}: {
  children: JSX.Element
  duration?: number
  movement?: boolean
}) => {
  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial={{
          opacity: 0,
          y: movement ? 10 : 0,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        exit={{
          opacity: 0,
          y: movement ? 10 : 0,
        }}
        transition={{ duration: duration }}
        className="motion-wrapper"
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

export default PageAnimation
