import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import Text from "@ingka/text"
import { useTranslation } from "react-i18next"
import Button from "@ingka/button"
import {
  generateLLMReport,
  setReportMode,
  setSelectedLLMTemplate,
} from "features/insights/insightsSlice"
import PromptInput from "features/insights/PromptInput"
import { usePromptCruds } from "features/insights/utils/hooks/usePromptCruds"
import { calculateLLMChatStats } from "features/insights/utils/costUtils/costUtils"
import { displayCost } from "features/insights/utils/costUtils/costFormatting"
import Hyperlink from "@ingka/hyperlink"
import PromptCrud from "features/insights/PromptCrud"
import { LLMReportTemplate } from "features/insights/types"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import { useLlmModelConfigs } from "features/insights/utils/hooks/useLlmModelConfigs"
import { llmReportTopics } from "features/insights/utils/llmUtils/llmFormattingConfig"

const LLMReportTool = ({}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const listConversationState = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const { savedInsightsPrompts, onAddPrompt, onPromptSave, onPromptDelete } =
    usePromptCruds()

  const { currentThreadUsesPrePromptedInsights } = useInsightsHelperFunctions()

  const { contextWindowReport, selectedReportModelConfig } =
    useLlmModelConfigs()

  const prePromptCrud = (
    <PromptCrud
      prompts={savedInsightsPrompts}
      promptType="insight"
      onAddPrompt={onAddPrompt}
      onPromptSave={onPromptSave}
      onPromptDelete={onPromptDelete}
    />
  )

  const { cost } = calculateLLMChatStats(
    insightsState.selectedSummaryPrompt,
    insightsState.llmReportModel,
  )

  const toggleSelectedReportTemplate = (template: LLMReportTemplate) => {
    if (template.id !== insightsState.llmReportTemplateSelected?.id) {
      dispatch(setSelectedLLMTemplate(template))
    } else {
      dispatch(setSelectedLLMTemplate(undefined))
    }
  }

  const templateSelection =
    insightsState.llmReportTemplates &&
    [...insightsState.llmReportTemplates]
      .sort((a, b) => a.title.localeCompare(b.title))
      .map((template, index) => {
        const templateSelected =
          insightsState.llmReportTemplateSelected?.id === template.id

        return (
          <button
            key={index}
            onClick={() => toggleSelectedReportTemplate(template)}
            style={{
              border: templateSelected ? "1px solid #111" : "1px solid #e7e7e7",
              cursor: "pointer",
            }}
          >
            <Text headingSize="s">{template.title}</Text>
            <Text
              bodySize="m"
              style={{ marginBottom: "0", marginTop: ".5rem" }}
            >
              {template.description}
            </Text>
            {templateSelected && (
              <div className="flex-row" style={{ marginTop: ".75rem" }}>
                {cost ? displayCost(cost, "Estimated cost of report") : ""}
              </div>
            )}
          </button>
        )
      })

  const reportManualInput = (
    <div>
      <PromptInput
        type="insight"
        promptInfo={
          <div className="flex-row">
            {cost ? displayCost(cost, "Estimated cost of report") : ""}
          </div>
        }
      />
      {prePromptCrud && prePromptCrud}
    </div>
  )

  const useReportManualInput =
    insightsState.llmReportTemplateSelected &&
    insightsState.llmReportTemplateSelected.manual_input

  const reportGenerationReady =
    listConversationState.conversations &&
    contextWindowReport &&
    selectedReportModelConfig &&
    insightsState.llmReportTemplateSelected

  const handleGenerateReport = () => {
    if (reportGenerationReady) {
      dispatch(
        generateLLMReport({
          conversations: listConversationState.conversations ?? [],
          tokensPerConversation: insightsState.selectedTokensPerConversation,
          contextWindow: contextWindowReport,
          insightsDataShare: insightsState.insightsDataShare,
          model: selectedReportModelConfig.name,
          template:
            insightsState.llmReportTemplateSelected?.instruction +
            " " +
            llmReportTopics(JSON.stringify(listConversationState.filters)),
        }),
      )
    }
  }

  const appliedDataLabel = currentThreadUsesPrePromptedInsights
    ? t("text:applyingInsights")
    : t("text:applyingRawData")

  return (
    <>
      <div className="flex-column" style={{ gap: "var(--gap-xxl)" }}>
        <div className="flex-column" style={{ gap: "var(--gap-xl)" }}>
          <div>
            <Text
              className="large-title"
              headingSize="m"
              style={{ marginBottom: ".5rem" }}
            >
              Select a report template
            </Text>
            <Text bodySize="s" style={{ marginTop: ".25rem" }}>
              Have another template in mind? Please let us know on{" "}
              <Hyperlink
                text="Teams"
                colour="black"
                newWindow={true}
                url="https://teams.microsoft.com/l/chat/0/0?users=henrik.benckert@ingka.com"
              ></Hyperlink>
              .
            </Text>
          </div>
          <div className="large-items-grid">
            {insightsState.llmReportTemplates
              ? templateSelection
              : insightsState.llmReportTemplatesLoading
                ? "Loading templates"
                : "No templates found"}
            <button
              onClick={() => dispatch(setReportMode(false))}
              style={{ border: "#f0f0f" }}
            >
              <Text headingSize="s">{t("buttons:customPrompt")}</Text>
              <Text
                bodySize="m"
                style={{ marginBottom: "0", marginTop: ".5rem" }}
              >
                Skip the process of creating a report and get to the chatting
                immidiately instead.
              </Text>
            </button>
          </div>
        </div>

        {useReportManualInput && (
          <div className="flex-column" style={{ gap: ".675rem" }}>
            <div>
              <Text
                className="large-title"
                headingSize="m"
                style={{ marginBottom: ".75rem" }}
              >
                Add more context (optional)
              </Text>
              <Text bodySize="s">
                Provide additional context using a prompt
              </Text>
            </div>
            {reportManualInput}
          </div>
        )}
      </div>

      <div className="flex-row">
        <Button
          type="emphasised"
          disabled={
            !insightsState.llmReportTemplateSelected ||
            insightsState.llmReportLoading
          }
          text={t("buttons:generateReport") + " " + appliedDataLabel}
          onClick={handleGenerateReport}
          style={{ flex: 1 }}
        />
      </div>
    </>
  )
}

export default LLMReportTool
