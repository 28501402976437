import Button from "@ingka/button"
import Search from "@ingka/search"
import AddIcon from "@ingka/ssr-icon/paths/plus"
import DeleteIcon from "@ingka/ssr-icon/paths/trash-can"
import Table, { TableBody, TableHeader } from "@ingka/table"
import {
  CircularProgress,
  Grid,
  Paper,
  TableContainer,
  TableRow,
} from "@mui/material"
import { collection, getDocs } from "app/firebase"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { query } from "firebase/firestore"
import React, { ChangeEvent, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import AddUserDialog from "./AddUserDialog"
import DeleteUserDialog from "./DeleteUserDialog"
import UserStatsDialog from "./UserStatsDialog"
import { addAdminUser, deleteAdminUser, getAdminUsers } from "./adminSlice"
import { AdminUser, UserStats } from "./types"
import { MouseEvent } from "react"

const getEmailErrors = (
  email: string,
  adminUsers: AdminUser[],
): string | undefined => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const isEmail = emailRegex.test(email)
  const exists = adminUsers.some((u) => u.email === email)
  if (!isEmail) {
    return "Please enter a valid email address."
  }

  if (exists) {
    return "Email already exists"
  }
}

const AdminUserTable: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { adminUsers, loading, saving } = useAppSelector(
    (state: RootState) => state.admin,
  )
  const [searchTerm, setSearchTerm] = useState("")
  const [newUser, setNewUser] = useState<AdminUser>({
    email: "",
    description: "",
  })
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null)
  const [emailError, setEmailError] = useState("")
  const [descriptionError, setDescriptionError] = useState("")
  const [selectedUserStats, setSelectedUserStats] = useState<UserStats | null>(
    null,
  )
  const [loadingUserStats, setLoadingUserStats] = useState<boolean>(false)

  useEffect(() => {
    if (!loading && adminUsers === undefined) {
      dispatch(getAdminUsers())
    }
  }, [dispatch, loading, adminUsers])

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof AdminUser,
  ) => {
    setNewUser((prevUser) => ({ ...prevUser, [field]: event.target.value }))
  }

  const handleAddUser = () => {
    const emailErrors = getEmailErrors(newUser.email, adminUsers ?? [])
    if (emailErrors) {
      setEmailError(emailErrors)
      return
    }
    if (newUser.description.length > 200) {
      setDescriptionError("Description should not exceed 200 characters.")
      return
    }
    dispatch(addAdminUser(newUser))
    setNewUser({ email: "", description: "" })
    setOpenAddDialog(false)
    setEmailError("")
    setDescriptionError("")
  }

  const handleDeleteUser = () => {
    if (selectedUserId) {
      dispatch(deleteAdminUser(selectedUserId))
      setSelectedUserId(null)
    }
    setOpenDeleteDialog(false)
  }

  const handleUserClick = async (email: string) => {
    try {
      setLoadingUserStats(true)
      const currentYear = new Date().getFullYear().toString()
      const userUsageRef = collection(
        "config",
        "admin",
        "userUsage",
        email,
        currentYear,
      )
      const userUsageQuery = query(userUsageRef)
      const snapshot = await getDocs(userUsageQuery)

      const usageData: UserStats["usageData"] = []
      let totalUsage = 0
      let totalCost = 0

      snapshot.forEach((doc) => {
        const data = doc.data
        if (!data) return
        const month = t(`shared:months:${doc.id}`)
        const usage = data.requestCount || 0
        const cost = data.cost || 0

        usageData.push({ month, usage, cost })
        totalUsage += usage
        totalCost += cost
      })

      const userStats: UserStats = {
        email,
        usageData,
        totalUsage,
        totalCost,
      }

      setSelectedUserStats(userStats)
      setLoadingUserStats(false)
    } catch (error) {
      console.error("Error fetching user statistics:", error)
    }
  }

  const filteredUsers = useMemo(
    () =>
      adminUsers?.filter((user) =>
        user.email.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    [adminUsers, searchTerm],
  )

  return (
    <Grid container sx={{ paddingBottom: "2rem" }}>
      <Grid
        item
        display="flex"
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        style={{ margin: "0 0 2rem 0" }}
      >
        <Search
          ariaLabel="Search for admin users"
          clearBtnText="Clear search field"
          id="search"
          onChange={handleSearch}
          placeholder="Search"
          submitBtnText="Submit"
          value={searchTerm}
          style={{ marginBottom: 2 }}
        />
        <Button
          type="emphasised"
          ssrIcon={AddIcon}
          iconPosition="leading"
          onClick={() => setOpenAddDialog(true)}
          style={{ marginBottom: 2 }}
          disabled={saving}
        >
          {saving ? <CircularProgress size={24} /> : "Add User"}
        </Button>
      </Grid>
      <TableContainer component={Paper}>
        <Table fullWidth inset>
          <TableHeader sticky>
            <tr>
              <th>Email</th>
              <th>Description</th>
              <th></th>
            </tr>
          </TableHeader>
          <TableBody>
            {loading ? (
              <tr>
                <td colSpan={3} align="center">
                  <CircularProgress />
                </td>
              </tr>
            ) : (
              filteredUsers?.map((user) => (
                <TableRow
                  key={user.email}
                  onClick={() => handleUserClick(user.email)}
                  style={{ cursor: "pointer" }}
                >
                  <td>{user.email}</td>
                  <td>{user.description}</td>
                  <td>
                    <Button
                      onClick={(
                        e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
                      ) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setSelectedUserId(user.email)
                        setOpenDeleteDialog(true)
                      }}
                      disabled={saving}
                      iconOnly
                      ssrIcon={DeleteIcon}
                      type="plain"
                    ></Button>
                  </td>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AddUserDialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        newUser={newUser}
        onInputChange={handleInputChange}
        onAddUser={handleAddUser}
        saving={saving}
        emailError={emailError}
        descriptionError={descriptionError}
      />
      <DeleteUserDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        selectedUserId={selectedUserId}
        onDeleteUser={handleDeleteUser}
        saving={saving}
      />
      <UserStatsDialog
        open={!!selectedUserStats}
        onClose={() => setSelectedUserStats(null)}
        userStats={selectedUserStats}
        loadingUserStats={loadingUserStats}
      />
    </Grid>
  )
}

export default AdminUserTable
