import { useMemo } from "react"
import { rawDataFormatter } from "../rawDataFormatter"
import { RootState } from "app/store"
import { useAppSelector } from "app/hooks"
import { useLlmModelConfigs } from "./useLlmModelConfigs"
import { useDataSources } from "stores/hooks"

export const useFormattedRawData = () => {
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { conversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const { contextWindowChat, contextWindowReport } = useLlmModelConfigs()
  const { savedSelection } = useDataSources()

  const memoizedConversations = useMemo(
    () => conversations ?? [],
    [conversations],
  )
  const memoizedDataShare = useMemo(
    () => insightsState.insightsDataShare,
    [insightsState.insightsDataShare],
  )

  const rawDataParamsChat = useMemo(
    () => ({
      conversations: memoizedConversations,
      tokensPerConversation: insightsState.selectedTokensPerConversation,
      tokenLimit: contextWindowChat * (memoizedDataShare / 100),
    }),
    [
      insightsState.selectedTokensPerConversation,
      contextWindowChat,
      memoizedDataShare,
      memoizedConversations,
    ],
  )

  const rawDataParamsSettings = useMemo(
    () => ({
      conversations: memoizedConversations,
      tokensPerConversation: insightsState.tokensPerConversation,
      tokenLimit: contextWindowChat * (memoizedDataShare / 100),
    }),
    [
      insightsState.tokensPerConversation,
      contextWindowChat,
      memoizedDataShare,
      memoizedConversations,
    ],
  )

  const rawDataParamsReport = useMemo(
    () => ({
      conversations: memoizedConversations,
      tokensPerConversation: insightsState.selectedTokensPerConversation,
      tokenLimit: contextWindowReport * (memoizedDataShare / 100),
    }),
    [
      insightsState.selectedTokensPerConversation,
      contextWindowReport,
      memoizedDataShare,
      memoizedConversations,
    ],
  )

  return useMemo(
    () => ({
      chat: rawDataFormatter(rawDataParamsChat),
      settings: rawDataFormatter(rawDataParamsSettings),
      report: rawDataFormatter(rawDataParamsReport),
    }),
    [
      rawDataParamsChat,
      rawDataParamsSettings,
      rawDataParamsReport,
      savedSelection,
    ],
  )
}
