import Modal, { Prompt, ModalHeader } from "@ingka/modal"
import Button from "@ingka/button"
import TextArea from "@ingka/text-area"
import Text from "@ingka/text"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { setMessageAttribute } from "features/insights/insightsSlice"
import { RootState } from "app/store"
import { useAppSelector } from "app/hooks"
import { useEffect, useState } from "react"
import { useLlmFeedback } from "stores/hooks"
import useSuppressSkapaErrors from "hooks/useSuppressSkapaErrors"

const MessageFeedback = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { currentThread } = useAppSelector((state: RootState) => state.insights)
  const { openMessageIndex, feedbackIsOpen, toggleFeedback } = useLlmFeedback()

  const [feedbackInput, setFeedbackInput] = useState<string | null>(null)
  const openMessageIndexDefined = openMessageIndex !== null

  useEffect(() => {
    if (!openMessageIndexDefined) return

    const storedFeedback =
      currentThread?.chatMessages?.[openMessageIndex]?.feedback ?? null

    setFeedbackInput(storedFeedback)
  }, [openMessageIndex])

  const handleSaveFeedback = ({ clear = false }: { clear?: boolean } = {}) => {
    if (!openMessageIndexDefined) return

    dispatch(
      setMessageAttribute({
        messageIndex: openMessageIndex,
        attribute: "feedback",
        value: clear ? null : feedbackInput,
      }),
    )

    if (!clear) toggleFeedback()
    if (clear) setFeedbackInput(null)
  }

  useSuppressSkapaErrors()

  return (
    <Modal visible={feedbackIsOpen} handleCloseBtn={() => toggleFeedback()}>
      <Prompt
        header={
          <ModalHeader
            titleId="message-feedback"
            title={t("headers:llmFeedback")}
          />
        }
        title=""
        titleId="message-feedback-title"
        footer={
          <div className="flex-row feedback-actions">
            {feedbackInput ? (
              <Button
                small
                type="secondary"
                onClick={() => handleSaveFeedback({ clear: true })}
              >
                {t("buttons:removeFeedback")}
              </Button>
            ) : (
              ""
            )}
            <Button small type="primary" onClick={() => handleSaveFeedback()}>
              {t("buttons:sendFeedback")}
            </Button>
          </div>
        }
        style={{ width: "30rem" }}
      >
        <div className="flex-column">
          <Text bodySize="m" style={{ marginTop: "-1rem" }}>
            {t("descriptions:llmFeedback")}
          </Text>
          <TextArea
            label={t("labels:feedback")}
            id="message-feedback-input"
            value={feedbackInput ?? ""}
            onChange={(e) => setFeedbackInput(e.target.value)}
          />
        </div>
      </Prompt>
    </Modal>
  )
}

export default MessageFeedback
