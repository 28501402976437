import React, { useRef } from "react"
import {
  Box,
  Typography,
  Button,
  ListItem,
  ListItemButton,
  ListItemText,
  alpha,
} from "@mui/material"
import Switch from "@mui/material/Switch"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import { SidePanel, InfoPanel } from "./StyledComponents"
import NavigationControls from "./NavigationControls"
import { useConversationStatus } from "../hooks/useConversationStatus"
import { FixedSizeList, ListChildComponentProps } from "react-window"
import AutoSizer from "react-virtualized-auto-sizer"

interface ConversationSidePanelProps {
  conversations: Array<{
    md5_conversation_id: string
    conversation_id: string
    country: string
    channel: string
  }>
  selectedIndex: number
  showFlagInput: boolean
  totalConversations: number
  labeledConversations: number
  completedConversations: number
  skipMode: "none" | "completed" | "labeled_and_completed"
  jumpToIndex: string
  onSetConversationMode: (value: boolean) => void
  showPiiData: boolean
  onSetTranslationMode: (value: boolean) => void
  translatedData: boolean
  onShowFlagInput: () => void
  onPrevious: () => void
  onNext: () => void
  onSkipModeChange: (
    mode: "none" | "completed" | "labeled_and_completed",
  ) => void
  onJumpToChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onConversationSelect: (index: number) => void
}

const ITEM_SIZE = 72 // Height of each conversation item

const ConversationSidePanel: React.FC<ConversationSidePanelProps> = ({
  conversations,
  selectedIndex,
  totalConversations,
  labeledConversations,
  completedConversations,
  skipMode,
  jumpToIndex,
  onSetConversationMode,
  showPiiData,
  onSetTranslationMode,
  translatedData,
  onShowFlagInput,
  onPrevious,
  onNext,
  onSkipModeChange,
  onJumpToChange,
  onConversationSelect,
}) => {
  const { getConversationData } = useConversationStatus()
  const listRef = useRef<FixedSizeList>(null)

  // Scroll to selected index when it changes
  React.useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(selectedIndex, "smart")
    }
  }, [selectedIndex])

  const ConversationRow = React.memo(
    ({ index, style }: ListChildComponentProps) => {
      const conversation = conversations[index]
      const conversationData = getConversationData(
        conversation.md5_conversation_id,
      )

      return (
        <ListItem disablePadding data-conversation-index={index} style={style}>
          <ListItemButton
            selected={index === selectedIndex}
            onClick={() => onConversationSelect(index)}
            sx={(theme) => ({
              height: "100%",
              backgroundColor: conversationData.isCompleted
                ? alpha(theme.palette.success.main, 0.3)
                : conversationData.labelId !== null
                  ? alpha(theme.palette.info.main, 0.15)
                  : "inherit",
              border:
                index === selectedIndex
                  ? `2px solid ${theme.palette.primary.main}`
                  : "none",
              "&:hover": {
                backgroundColor: conversationData.isCompleted
                  ? alpha(theme.palette.success.main, 0.4)
                  : conversationData.labelId !== null
                    ? alpha(theme.palette.info.main, 0.25)
                    : theme.palette.action.hover,
              },
              "&.Mui-selected": {
                backgroundColor: conversationData.isCompleted
                  ? alpha(theme.palette.success.main, 0.45)
                  : conversationData.labelId !== null
                    ? alpha(theme.palette.info.main, 0.3)
                    : theme.palette.action.selected,
              },
            })}
          >
            <ListItemText
              primary={
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography
                    component="span"
                    sx={{ opacity: 0.6, minWidth: 32 }}
                  >
                    #{index + 1}
                  </Typography>
                  <Typography component="span">
                    {conversation.md5_conversation_id}
                  </Typography>
                </Box>
              }
              secondary={`${conversation.country} - ${conversation.channel}`}
            />
          </ListItemButton>
        </ListItem>
      )
    },
  )

  ConversationRow.displayName = "ConversationRow"

  return (
    <SidePanel>
      <InfoPanel>
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="subtitle2">Progress</Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={onShowFlagInput}
              sx={{ ml: 1 }}
            >
              Fetch New
            </Button>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Typography variant="body2" color="text.secondary">
              Total Conversations:
            </Typography>
            <Typography variant="body2">{totalConversations}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Typography variant="body2" color="text.secondary">
              Labeled:
            </Typography>
            <Typography variant="body2">
              {labeledConversations} (
              {Math.round((labeledConversations / totalConversations) * 100)}%)
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body2" color="text.secondary">
              Completed:
            </Typography>
            <Typography
              variant="body2"
              color="success.main"
              sx={{ fontWeight: 500 }}
            >
              {completedConversations} (
              {Math.round((completedConversations / totalConversations) * 100)}
              %)
            </Typography>
          </Box>
        </>
      </InfoPanel>

      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={showPiiData}
              onChange={(_, checked) => onSetConversationMode(checked)}
            />
          }
          label={"Show PII Data"}
        />
        <FormControlLabel control={<Switch checked={translatedData} onChange={(_, checked) => onSetTranslationMode(checked)} />} label={"Translated Data"}/>
      </FormGroup>

      <NavigationControls
        selectedIndex={selectedIndex}
        totalConversations={totalConversations}
        skipMode={skipMode}
        jumpToIndex={jumpToIndex}
        onPrevious={onPrevious}
        onNext={onNext}
        onSkipModeChange={onSkipModeChange}
        onJumpToChange={onJumpToChange}
      />

      <Box sx={{ flex: 1 }}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              ref={listRef}
              height={height}
              width={width}
              itemCount={conversations.length}
              itemSize={ITEM_SIZE}
              overscanCount={5}
            >
              {ConversationRow}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Box>
    </SidePanel>
  )
}

export default ConversationSidePanel
