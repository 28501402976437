import {
  DocumentData,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  startAfter,
  where,
  doc,
  getDoc,
} from "firebase/firestore"
import {
  ChatThreadFilter,
  ChatThreadSorting,
  nonRangeFields,
  rangeFields,
  SortingDirection,
  sortableFields,
} from "./types"
import { getChatThreadsCollection } from "utils/firestorePaths"
import {
  FetchStoredLLMThreadsProps,
  SerializableDocumentSnapshot,
} from "features/insights/types"

export const fetchChatThreadsFS = async (
  params: FetchStoredLLMThreadsProps,
) => {
  const query = generateChatThreadQuery(params.userId, params.filter)

  if (!query)
    throw new Error(
      "Could not fetch chat threads. Invalid filtering or sorting.",
    )

  try {
    const paginatedQuery = await buildPaginatedQuery(
      query,
      params.page ?? 0,
      params?.lastDoc,
      params.quantity,
    )
    return await getDocs(paginatedQuery)
  } catch (error) {
    throw new Error(
      `Error fetching chat threads: ${error instanceof Error ? error.message : String(error)}`,
    )
  }
}

function generateChatThreadQuery(
  identifier: string,
  filters: ChatThreadFilter,
): Query<DocumentData> | undefined {
  if (!filters)
    throw new Error(
      "Insufficiently formatted chat threads fetch: filtering conditions undefined",
    )

  let q: Query<DocumentData> = getChatThreadsCollection(identifier)

  for (const field of nonRangeFields) {
    if (filters[field] !== undefined) {
      q = applyNonRangeFilter(q, field, filters[field])
    }
  }

  for (const field of rangeFields) {
    if (filters[field]) {
      q = applyRangeFilter(q, field, filters[field])
    }
  }

  if (filters.sorting && filters.sorting.field) {
    q = applySorting(q, filters.sorting)
  }

  return q
}

function applyNonRangeFilter(
  q: Query<DocumentData>,
  field: string,
  value: string,
): Query<DocumentData> {
  return query(q, where(field, "==", value))
}

function applyRangeFilter(
  q: Query<DocumentData>,
  field: string,
  filter: { min?: number; max?: number },
): Query<DocumentData> {
  if (filter.min !== undefined && filter.max !== undefined) {
    return query(
      q,
      where(field, ">=", filter.min),
      where(field, "<=", filter.max),
    )
  } else if (filter.min !== undefined) {
    return query(q, where(field, ">=", filter.min))
  } else if (filter.max !== undefined) {
    return query(q, where(field, "<=", filter.max))
  }
  return q
}

function applySorting(
  q: Query<DocumentData>,
  sorting: ChatThreadSorting,
): Query<DocumentData> {
  const direction: SortingDirection = sorting.direction
  const sortableField = sortableFields[sorting.field]

  if (!sortableField) {
    throw new Error(
      `Invalid sort field: ${sorting.field}. Must be one of: ${Object.keys(sortableFields).join(", ")}`,
    )
  }

  return query(q, orderBy(sortableField, direction))
}

const buildPaginatedQuery = async (
  baseQuery: Query<DocumentData>,
  page: number,
  lastDoc?: SerializableDocumentSnapshot | null,
  quantity?: number,
): Promise<Query<DocumentData>> => {
  let paginatedQuery = baseQuery

  if (page > 0 && lastDoc) {
    const docRef = doc(
      getChatThreadsCollection(lastDoc.path.split("/")[1]),
      lastDoc.id,
    )
    const snapshot = await getDoc(docRef)
    if (snapshot.exists()) {
      paginatedQuery = query(paginatedQuery, startAfter(snapshot))
    }
  }

  if (quantity !== undefined) {
    paginatedQuery = query(paginatedQuery, limit(quantity))
  }

  return paginatedQuery
}
