import { configureStore } from "@reduxjs/toolkit"
import listConversationReducer from "features/listConversations/listConversationSlice"
import authReducer from "features/auth/authSlice"
import insightsReducer from "features/insights/insightsSlice"
import reviewsReducer from "features/review/conversationReviewSlice"
import messagesReducer from "features/messages/messagesSlice"
import conversationSearchReducer from "features/conversationSearch/conversationSearchSlice"
import adminReducer from "features/admin/adminSlice"
import flagReducer from "features/flag/flagSlice"
import chatThreadsMiddleware from "features/insights/middleware/chatThreadsMiddleware"
import labelConversationsReducer from "features/labelConversations/labelConversationsSlice"
import marketSettingsReducer from "features/admin/marketSettingsSlice"

export const store = configureStore({
  reducer: {
    listConversation: listConversationReducer,
    labelConversations: labelConversationsReducer,
    auth: authReducer,
    insights: insightsReducer,
    review: reviewsReducer,
    messages: messagesReducer,
    conversationSearch: conversationSearchReducer,
    admin: adminReducer,
    flag: flagReducer,
    marketSettings: marketSettingsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(chatThreadsMiddleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
