import { useAppSelector, useAppDispatch } from "app/hooks"
import { RootState } from "app/store"
import FeatureTitle from "components/FeatureTitle"
import ChatThreadPreview from "../misc/ChatThreadPreview"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import LoadingInstructionHandler from "components/LoadingInstructionHandler"
import { useTranslation } from "react-i18next"
import {
  fetchStoredLLMThreads,
  incrementStoredThreadHistoryPage,
} from "features/insights/insightsSlice"
import Button from "@ingka/button"
import Loading, { LoadingLinear } from "@ingka/loading"
import { useEffect, useState } from "react"

const HistoryView = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const [loadingProgress, setLoadingProgress] = useState(0)

  const { renderableThreads, threadHistoryAvailable } =
    useInsightsHelperFunctions()

  const showFetchAnimation = insightsState.storedThreadHistoryLoadingSilently

  useEffect(() => {
    let intervalId: NodeJS.Timeout
    if (showFetchAnimation) {
      setLoadingProgress(0)
      intervalId = setInterval(() => {
        setLoadingProgress((prev) => (prev >= 90 ? 90 : prev + 3))
      }, 20)
    } else {
      setLoadingProgress(0)
    }
    return () => clearInterval(intervalId)
  }, [showFetchAnimation])

  const history = renderableThreads(insightsState.chatThreads)?.map(
    (thread, index) => (
      <ChatThreadPreview
        key={index}
        thread={thread}
        index={index}
        currentChat={thread.uuid === insightsState.currentThread?.uuid}
      />
    ),
  )

  const fetchMoreThreads = () => {
    if (!user) return

    dispatch(
      fetchStoredLLMThreads({
        userId: user.id,
        page: insightsState.storedThreadHistoryPage,
        fetchSilently: true,
        lastDoc: insightsState.storedThreadHistoryLastDoc,
      }),
    )

    dispatch(incrementStoredThreadHistoryPage())
  }

  const fetchMore = threadHistoryAvailable && !showFetchAnimation && (
    <Button onClick={fetchMoreThreads} style={{ marginTop: "1rem" }}>
      {t("buttons:loadMore")}
    </Button>
  )

  const fetchMoreAnimation = showFetchAnimation && (
    <Loading color="primary" labelTransitions>
      <LoadingLinear loadingValue={loadingProgress} loadingMax={99} />
    </Loading>
  )

  return (
    <div style={{ position: "relative", height: "inherit" }}>
      <div
        className="controls-parent"
        style={{ minHeight: "calc(100% - 4rem)" }}
      >
        <div style={{ marginBottom: "1rem" }}>
          <FeatureTitle featureName="History" />
        </div>
        <LoadingInstructionHandler
          showLoading={
            insightsState.storedThreadHistoryLoading &&
            !insightsState.storedThreadHistoryLoadingSilently
          }
          loadingLabel={t("labels:loadingStoredThreads")}
          showInstruction={!threadHistoryAvailable}
          instructionLabel={t("labels:noResults")}
        >
          <div className="flex-column" style={{ gap: "1.25rem" }}>
            {history}
            {fetchMore}
            {fetchMoreAnimation}
          </div>
        </LoadingInstructionHandler>
      </div>
    </div>
  )
}

export default HistoryView
