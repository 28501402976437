import { IConversation, KeyValueType, ClientStorageKeys } from "app/types"

export const loadConversationsFromStorage = (): {
  conversations: IConversation[] | undefined
  totalAmount: number
  filters: KeyValueType[]
} => {
  try {
    const storedConversations = sessionStorage.getItem(
      ClientStorageKeys.CONVERSATIONS_STORAGE_KEY,
    )
    const storedTotalAmount = sessionStorage.getItem(
      ClientStorageKeys.TOTAL_CONVERSATIONS_STORAGE_KEY,
    )
    const storedFilters = sessionStorage.getItem(
      ClientStorageKeys.FILTERS_STORAGE_KEY,
    )

    return {
      conversations: storedConversations
        ? JSON.parse(storedConversations)
        : undefined,
      totalAmount: storedTotalAmount ? parseInt(storedTotalAmount) : 0,
      filters: storedFilters ? JSON.parse(storedFilters) : [],
    }
  } catch (error) {
    console.error("Error loading conversations from sessionStorage:", error)
    return { conversations: undefined, totalAmount: 0, filters: [] }
  }
}

export const saveConversationsToStorage = (
  conversations: IConversation[],
  totalAmount: number,
  filters: KeyValueType[],
) => {
  try {
    sessionStorage.setItem(
      ClientStorageKeys.CONVERSATIONS_STORAGE_KEY,
      JSON.stringify(conversations),
    )
    sessionStorage.setItem(
      ClientStorageKeys.TOTAL_CONVERSATIONS_STORAGE_KEY,
      totalAmount.toString(),
    )
    sessionStorage.setItem(
      ClientStorageKeys.FILTERS_STORAGE_KEY,
      JSON.stringify(filters),
    )
  } catch (error) {
    console.error("Error saving conversations to sessionStorage:", error)
  }
}
