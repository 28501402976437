import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import { useTranslation } from "react-i18next"

const NoMessages = () => {
  const { t } = useTranslation()
  const { currentThreadUsingCustomPromptMode } = useInsightsHelperFunctions()

  return (
    <div className="no-messages">
      <div
        className="no-messages-label-parent chat"
        style={{
          marginTop: "auto",
          height: "auto",
          justifyContent: currentThreadUsingCustomPromptMode
            ? "unset"
            : "center",
        }}
      >
        <div className="no-messages-title">
          {currentThreadUsingCustomPromptMode
            ? t("instructions:chatCustomPromptMode")
            : t("instructions:chatReportMode")}
        </div>
      </div>
      <div
        className="no-messages-label-parent"
        style={{ marginTop: "auto", height: "auto" }}
      >
        <label className="skapa-label" style={{ marginBottom: "0" }}>
          <span>{t("help:recommendation")}:</span> {t("help:emptyChat")}
        </label>
      </div>
    </div>
  )
}

export default NoMessages
