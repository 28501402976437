import { setMarketSettingField } from "app/api"
import { MarketSettings } from "./types"

interface SettingChange {
  document: string
  field: string
  value: any
}

export const getSettingsChanges = (
  newSettings: MarketSettings,
  currentSettings: MarketSettings,
): SettingChange[] =>
  Object.entries(newSettings).flatMap(([document, fields]) =>
    Object.entries(fields)
      .filter(([field, value]) => value !== currentSettings[document]?.[field])
      .map(([field, value]) => ({
        document,
        field,
        value,
      })),
  )

export const applySettingsChanges = (
  market: string,
  changes: SettingChange[],
): Promise<void> =>
  Promise.all(
    changes.map(({ document, field, value }) =>
      setMarketSettingField({ market, document, field, value }).then(
        (result) => result.data,
      ),
    ),
  ).then(() => {})
