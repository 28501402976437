import { FirestoreStorageKeys } from "app/types"
import { collection, doc } from "firebase/firestore"
import { firestore } from "app/firebase"
import { market } from "app/types"

export const getChatThreadsCollection = (userId: string) => {
  return collection(
    firestore,
    FirestoreStorageKeys.CHAT_THREADS,
    userId,
    FirestoreStorageKeys.CHAT_THREADS_USER_COLLECTION,
  )
}

export const getChatThreadDoc = (userId: string, threadId: string) => {
  return doc(getChatThreadsCollection(userId), threadId)
}

export const getLabelsDocument = () => {
  return doc(firestore, "config", "labels")
}

export const getConversationLabelInfo = (
  md5_conversation_id: string,
  dataset: string,
) => {
  return doc(
    firestore,
    "labellingDatasets",
    dataset,
    "conversations",
    md5_conversation_id,
  )
}

export const getDatasetCollection = () => {
  return collection(firestore, "labellingDatasets")
}

export const getDatasetDoc = (name: string) => {
  return doc(firestore, "labellingDatasets", name)
}

export const getLLMReportTemplatesCollection = collection(
  firestore,
  "config",
  "llm_inputs",
  "templates",
)

export const getPathMarketSettings = (market: market) =>
  `markets/${market}/settings`
