import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { useNavigate } from "react-router-dom"

export const useNavigateWithParams = () => {
  const { filters } = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  const navigate = useNavigate()

  const navigateWithParams = (path: string) => {
    const params = new URLSearchParams()
    filters.forEach(({ key, value }) => {
      params.append(key, value)
    })
    navigate(path + "?" + params.toString())
  }

  return { navigateWithParams }
}
