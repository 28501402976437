import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchLabelConversations, fetchEntitiesAPI } from "app/api"
import {
  LabelConversationsState,
  Dataset,
  DatasetKeySample,
  ConversationDatas,
  Entities,
} from "./types"
import {
  IFetchLabelConversationsResponse,
  ILabelConversation,
  Label,
} from "app/types"
import {
  getLabelsDocument,
  getDatasetCollection,
  getDatasetDoc,
} from "utils/firestorePaths"
import { DocumentData, getDoc, getDocs } from "firebase/firestore"
import { docExists, deleteDoc, setDoc, updateDoc } from "app/firebase"
import JSZip from "jszip"

// Map display names to customer journey names
const customerJourneyMap: { [key: string]: { name: string; id: number } } = {
  General: { name: "Before Sales", id: 10 },
  "Product & Service Information": { name: "Before Sales", id: 10 },
  "Planning & Advice": { name: "Before Sales", id: 10 },
  Availability: { name: "During Sales", id: 20 },
  "Order Creation": { name: "During Sales", id: 20 },
  "Order Processing": { name: "Delivery", id: 30 },
  "Order Fulfilment Issues": { name: "Delivery", id: 30 },
  "Service Fulfilment": { name: "After Sales", id: 40 },
  "Help Integrating Product": { name: "After Sales", id: 40 },
  "Change of Mind Returns": { name: "After Sales", id: 40 },
  "Initiate Aftersales": { name: "After Sales", id: 40 },
  "Issue Handling": { name: "After Sales", id: 40 },
  "General After Purchase": { name: "After Sales", id: 40 },
  "No Suitable Category": { name: "Other", id: 90 },
  "Chatbot Contained": { name: "Other", id: 90 },
  "Automated tag": { name: "Other", id: 90 },
  "Quiz tag": { name: "Other", id: 90 },
}

const initialState: LabelConversationsState = {
  loadingConversations: false,
  loadingLabels: false,
  errorMessage: undefined,
  labelError: null,
  emptyConversationFilter: false,
  conversations: [],
  labels: [],
  dataset: undefined,
  savingDataset: false,
  deletingDataset: false,
  datasetLoaded: false,
  datasets: [],
  datasetsLoaded: false,
  conversationStatuses: {},
  loadingConversationStatuses: false,
  savingConversationData: false,
  entityReloadRequired: false,
  loadingEntities: false,
  entities: undefined,
}

const fetchEntitiesFromApi = async (
  country: string,
): Promise<Entities | undefined> => {
  const response = await fetchEntitiesAPI(country)

  const zip = await JSZip.loadAsync(response.data, { base64: true })
  const entitiesRaw = await zip.file("tmp_entities")?.async("string")

  if (!entitiesRaw) return undefined
  const entitiesParsed = JSON.parse(entitiesRaw) as Entities

  entitiesParsed.prodcuts = entitiesParsed.productNames.concat(entitiesParsed.productNumbers)
  return entitiesParsed
}

const fetchLabelsFromApi = async (): Promise<Label[]> => {
  const labelsRaw = (await getDoc(getLabelsDocument())).data() as DocumentData

  return labelsRaw.labels.map((label: { displayName: string; id: number }) => {
    // Split displayName into main label and sub label if it contains '::'
    const [mainLabelName, subLabelName] = label.displayName.split("::")

    // Get customer journey info from the map
    const journey = customerJourneyMap[mainLabelName] || {
      name: "Other",
      id: 90,
    }

    // If there's no sub label, it's a main label
    const isMainLabel = !subLabelName

    return {
      customer_journey_name: journey.name,
      customer_journey_id: journey.id,
      main_label_name: mainLabelName,
      main_label_id: isMainLabel ? label.id : Math.floor(label.id / 100) * 100,
      label_name: subLabelName || mainLabelName,
      label_id: label.id,
    }
  })
}

const fetchDatasetsFromFirestore = async (): Promise<string[]> => {
  const datasets = (await getDocs(getDatasetCollection())).docs
  return datasets.map((x) => x.id)
}

const fetchConversationStatusFromFirestore = async (
  name: string,
): Promise<ConversationDatas> => {
  const dataset = (await getDoc(getDatasetDoc(name))).data()

  const conversationDatas: ConversationDatas = {}
  if (!dataset) return conversationDatas

  Object.keys(dataset)
    .filter((x) => !(x in DatasetKeySample))
    .forEach((md5ConversationId) => {
      conversationDatas[md5ConversationId] = dataset[md5ConversationId]
    })

  return dataset
}

const updateConversationStatusInFirestore = async (
  name: string,
  data: ConversationDatas,
) => {
  await updateDoc(getDatasetDoc(name), data)
  return data
}

const saveDatasetInFirestore = async (
  name: string,
  data: Dataset,
): Promise<boolean> => {
  const docAlreadyExists = await docExists(getDatasetDoc(name))
  if (docAlreadyExists) return false

  await setDoc(getDatasetDoc(name), data)

  return true
}

const deleteDatasetInFirestore = async (name: string): Promise<boolean> => {
  const docAlreadyExists = await docExists(getDatasetDoc(name))
  if (!docAlreadyExists) return false

  await deleteDoc(getDatasetDoc(name))

  return true
}

export const fetchConversations = createAsyncThunk(
  "labelConversations/fetchConversations",
  async (dataset: Dataset) => {
    const response = await fetchLabelConversations({
      flagName: dataset.flagName,
      limit: dataset.limit,
      country: dataset.country,
      channel: dataset.channel
    })
    return response.data
  },
)

export const fetchEntities = createAsyncThunk(
  "labelConversations/fetchEntities",
  async (country: string): Promise<Entities | undefined> => {
    const response = await fetchEntitiesFromApi(country)
    return response
  },
)

export const fetchLabels = createAsyncThunk(
  "labelConversations/fetchLabels",
  async () => {
    const response = await fetchLabelsFromApi()
    return response
  },
)

export const fetchDatasets = createAsyncThunk(
  "labelConversations/fetchDatasets",
  async () => {
    const response = await fetchDatasetsFromFirestore()
    return response
  },
)

export const fetchConversationStatus = createAsyncThunk(
  "labelConversations/fetchConversationStatus",
  async (dataset: string) => {
    const response = await fetchConversationStatusFromFirestore(dataset)
    return response
  },
)

export const setConversationStatus = createAsyncThunk(
  "labelConversations/setConversationStatus",
  async ({ dataset, data }: { dataset: string; data: ConversationDatas }) => {
    const response = await updateConversationStatusInFirestore(dataset, data)
    return response
  },
)

export const saveDataset = createAsyncThunk(
  "labelConversations/saveDataset",
  async (dataset: Dataset) => {
    const response = await saveDatasetInFirestore(dataset.name, dataset)
    return response
  },
)

export const deleteDataset = createAsyncThunk(
  "labelConversations/deleteDataset",
  async (dataset: string) => {
    const response = await deleteDatasetInFirestore(dataset)
    return response
  },
)

export const labelConversationsSlice = createSlice({
  name: "labelConversations",
  initialState,
  reducers: {
    setConversations: (state, action: PayloadAction<ILabelConversation[]>) => {
      state.conversations = action.payload
    },
    setDataset: (state, action: PayloadAction<Dataset>) => {
      state.dataset = action.payload
      state.datasetLoaded = false
    },
    setDatasetsLoaded: (state, action: PayloadAction<boolean>) => {
      state.datasetsLoaded = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEntities.fulfilled, (state, action) => {
        state.loadingEntities = false
        state.entityReloadRequired = false
        state.entities = action.payload
      })
      .addCase(fetchEntities.pending, (state, _action) => {
        state.loadingEntities = true
      })
      .addCase(fetchEntities.rejected, (state, _action) => {
        state.loadingEntities = false
        state.entities = undefined
      })
      .addCase(saveDataset.fulfilled, (state, _action) => {
        state.savingDataset = false
        state.datasetsLoaded = false
      })
      .addCase(saveDataset.pending, (state, _action) => {
        state.savingDataset = true
      })
      .addCase(saveDataset.rejected, (state, _action) => {
        state.savingDataset = false
      })
      .addCase(deleteDataset.fulfilled, (state, _action) => {
        state.deletingDataset = false
        state.datasetsLoaded = false
      })
      .addCase(deleteDataset.pending, (state, _action) => {
        state.deletingDataset = true
      })
      .addCase(deleteDataset.rejected, (state, _action) => {
        state.deletingDataset = false
      })
      .addCase(setConversationStatus.fulfilled, (state, action) => {
        state.savingConversationData = false
        state.conversationStatuses = action.payload
      })
      .addCase(setConversationStatus.pending, (state, _action) => {
        state.savingConversationData = true
      })
      .addCase(setConversationStatus.rejected, (state, _action) => {
        state.savingConversationData = false
      })
      .addCase(fetchConversationStatus.fulfilled, (state, action) => {
        state.conversationStatuses = action.payload
        state.loadingConversationStatuses = false
      })
      .addCase(fetchConversationStatus.pending, (state, _action) => {
        state.loadingConversationStatuses = true
      })
      .addCase(fetchConversationStatus.rejected, (state, _action) => {
        state.loadingConversationStatuses = false
        state.conversationStatuses = {}
      })
      .addCase(
        fetchConversations.fulfilled,
        (state, action: PayloadAction<IFetchLabelConversationsResponse>) => {
          state.conversations = action.payload.conversations ?? []
          state.datasetLoaded = true
          state.entityReloadRequired = true
          state.loadingConversations = false
          state.errorMessage = undefined
          state.emptyConversationFilter = state.conversations.length === 0
        },
      )
      .addCase(fetchConversations.rejected, (state, action) => {
        if (state.loadingConversations === true) {
          state.datasetLoaded = true
          state.loadingConversations = false
          state.conversations = []
          state.errorMessage = action.error.message
          state.emptyConversationFilter = false
        }
      })
      .addCase(fetchConversations.pending, (state) => {
        if (state.loadingConversations === false) {
          state.loadingConversations = true
          state.emptyConversationFilter = false
        }
      })
      .addCase(fetchDatasets.fulfilled, (state, action) => {
        state.datasetsLoaded = true
        state.datasets = action.payload
      })
      .addCase(fetchDatasets.rejected, (state, _action) => {
        state.datasetLoaded = false
        state.datasets = []
      })
      .addCase(fetchLabels.pending, (state) => {
        state.loadingLabels = true
        state.labelError = null
      })
      .addCase(fetchLabels.fulfilled, (state, action) => {
        state.loadingLabels = false
        state.labels = action.payload
      })
      .addCase(fetchLabels.rejected, (state, action) => {
        state.loadingLabels = false
        state.labelError = action.error.message || "Failed to fetch labels"
      })
  },
})

export const { setConversations, setDataset, setDatasetsLoaded } =
  labelConversationsSlice.actions
export default labelConversationsSlice.reducer
