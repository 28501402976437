import { Middleware } from "@reduxjs/toolkit"
import {
  removeChatThreadFS,
  saveChatThreadFS,
} from "../utils/firestore/writeChatThreadFS"
import { isInsightAction, ChatThreadsMiddlewareState } from "./types"

const chatThreadsMiddleware: Middleware<{}, ChatThreadsMiddlewareState> =
  (store) => (next) => async (action) => {
    if (!isInsightAction(action)) return next(action)

    const result = next(action)
    const state = store.getState()
    const insightState = state.insights
    const authState = state.auth

    const removalCondition =
      action.type === "insights/removeThread" &&
      typeof action.payload === "string"

    if (authState.user?.id) {
      try {
        if (removalCondition) {
          await removeChatThreadFS(action.payload, authState.user.id)
        } else if (insightState.currentThread) {
          await saveChatThreadFS(insightState.currentThread, authState.user.id)
        }
      } catch (error) {
        console.error("Error in chatThreadsMiddleware:", error)
      }
    }

    return result
  }

export default chatThreadsMiddleware
