import { create } from "zustand"
import { immer } from "zustand/middleware/immer"
import { StoreState } from "./types"
import { createDataSourcesSlice } from "./slices/dataSources"
import { createLlmFeedbackSlice } from "./slices/llmFeedback"
import { createDashboardSelectionSlice } from "./slices/dashboardSelection"
import { createNotificationToastsSlice } from "./slices/notificationToasts"

/* Use this store for slices that need immer immutability 
handling and that are not to be persisted. **/

export const useStore = create<StoreState>()(
  immer((set) => ({
    dataSources: createDataSourcesSlice(set),
    llmFeedback: createLlmFeedbackSlice(set),
    dashboardSelection: createDashboardSelectionSlice(set),
    notificationToasts: createNotificationToastsSlice(set),
  })),
)
