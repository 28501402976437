import { useAppDispatch, useAppSelector } from "app/hooks"
import ConversationDetails from "../messages/ConversationDetails"
import FiltersResultsSection from "./components/FiltersResultsSection"
import { RootState } from "app/store"
import { useTranslation } from "react-i18next"
import Text from "@ingka/text"
import Titlebar from "components/Titlebar"
import UserNavigation from "components/UserNavigation"
import Switch from "@ingka/switch"
import {
  toggleAutomatedMessages,
  toggleLanguage,
} from "./listConversationSlice"
import MessagePageHandler from "components/MessagePageHandler"
import PageAnimation from "components/PageAnimation"
import useConversationsHelperFunctions from "./hooks/useConversationsHelperFunctions"

const ListConversations: React.FC = () => {
  const listConversationState = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const { searchText } = useAppSelector(
    (state: RootState) => state.conversationSearch,
  )

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { conversationSelected } = useConversationsHelperFunctions()

  const conversationOptionsDisabled =
    listConversationState.loadingConversations || !conversationSelected

  const conversationOptions = (
    <>
      <Switch
        id="automatedMessageSwitch"
        label={t("labels:automatedMessages")}
        value={"automatedMessages"}
        onChange={() => dispatch(toggleAutomatedMessages())}
        disabled={conversationOptionsDisabled}
      />
      <Switch
        id="translationSwitch"
        label={t("labels:translatedMessages")}
        value={listConversationState.showOriginalLanguage.toString()}
        onChange={() => dispatch(toggleLanguage())}
        disabled={conversationOptionsDisabled}
      />
    </>
  )

  const titlebarContent = (
    <>
      <div className="flex-row" style={{ gap: "1rem", width: "auto" }}>
        <Text tagName="h1" headingSize="s">
          {t("buttons:view")}
        </Text>
      </div>

      <div className="flex-row" style={{ gap: "0", width: "auto" }}>
        <div className="flex-row" style={{ gap: "1.5rem" }}>
          {conversationOptions}
        </div>
        <UserNavigation />
      </div>
    </>
  )

  const listConversations = (
    <div className="page-contents-narrow two-pane-row">
      <div>
        <FiltersResultsSection />
      </div>
      <div style={{ overflow: "auto" }}>
        <ConversationDetails />
      </div>
    </div>
  )

  const noSearchResults =
    Object.keys(listConversationState.conversations ?? {}).length === 0 &&
    Boolean(searchText)

  return (
    <>
      <Titlebar>{titlebarContent}</Titlebar>
      <MessagePageHandler overrideCondition={noSearchResults}>
        <PageAnimation duration={0.5} movement={false}>
          {listConversations}
        </PageAnimation>
      </MessagePageHandler>
    </>
  )
}

export default ListConversations
