export const ROOT_CAUSE_OWNERS = [
  { value: "all", label: "All" },
  { value: "core-services", label: "Core Services" },
  { value: "customer-care", label: "Customer Care" },
  { value: "customer-care-rcmp", label: "Customer Care; RCMP Resolution" },
  { value: "customer-operations", label: "Customer Operations" },
  { value: "fulfilment-operations", label: "Fulfilment unit operations" },
  { value: "marketing-loyalty", label: "Marketing & Loyalty" },
  { value: "payment", label: "Payment" },
  { value: "product-quality", label: "Product Quality" },
  { value: "selling", label: "Selling" },
]
