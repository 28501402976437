import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"

const useConversationsHelperFunctions = () => {
  const conversationState = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const messagesState = useAppSelector((state: RootState) => state.messages)

  const filtersApplied = Object.keys(conversationState.filters).length !== 0

  const conversationsBeingFetched = conversationState.loadingConversations

  const conversationsAvailable =
    conversationState.conversations &&
    Object.keys(conversationState.conversations).length !== 0

  const conversationSelected = Object.keys(messagesState.messages).length !== 0

  return {
    filtersApplied,
    conversationsBeingFetched,
    conversationsAvailable,
    conversationSelected,
  }
}

export default useConversationsHelperFunctions
