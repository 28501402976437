// See https://mui.com/material-ui/react-autocomplete/#virtualization For documentation

/* eslint-disable react/display-name */
/* eslint-disable no-prototype-builtins */
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete"
import ListSubheader from "@mui/material/ListSubheader"
import Popper from "@mui/material/Popper"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import PropTypes from "prop-types"
import * as React from "react"
import { useTranslation } from "react-i18next"
import { ListChildComponentProps, VariableSizeList } from "react-window"

const LISTBOX_PADDING = 8 // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  }

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    )
  }

  const { optionProps, optionText } = dataSet
  const { key, ...otherProps } = optionProps

  return (
    <Typography
      component="li"
      key={key}
      {...otherProps}
      noWrap
      style={inlineStyle}
      sx={{ width: "fit-content !important" }}
    >
      {optionText}
    </Typography>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

interface OptionProps {
  group?: string
  key?: string
  children?: React.ReactNode
  [key: string]: any
}

const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData: any[] = []

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) return

    const childProps = child.props as OptionProps

    if (childProps.hasOwnProperty("group")) {
      itemData.push(childProps)
    } else {
      itemData.push({
        optionProps: {
          ...childProps,
          style: undefined,
        },
        optionText: childProps.children,
      })
    }
  })

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = (child: any) => {
    if (child.hasOwnProperty("group")) {
      return 48
    }
    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

ListboxComponent.propTypes = {
  children: PropTypes.array,
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
})

interface VirtualizedSelectProps {
  options: any[]
  inputLabel?: string
  placeholder?: string
  value: any | null | any[]
  onChange: (event: any, value: any) => void
  getOptionLabel: (option: any) => string
  filterSelectedOptions?: boolean
  disableCloseOnSelect?: boolean
  loading?: boolean
  isOptionEqualToValue?: (option: any, value: any) => boolean
  style?: React.CSSProperties
  multiple?: boolean
  size?: "small" | "medium"
  filterOptions?: (options: any[], state: any) => any[]
}

export default function VirtualizedSelect(props: VirtualizedSelectProps) {
  const { t } = useTranslation()

  const value = props.value ?? (props.multiple ? [] : null)

  return (
    <Autocomplete
      id="virtualized-select"
      limitTags={5}
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={props.options}
      renderInput={(params) => {
        const handlePaste = (e: any) => {
          e.stopPropagation()
          e.preventDefault()
          const clipboardData: any =
            e.clipboardData?.getData("Text") || navigator.clipboard.readText()

          let modifiedString = clipboardData.replaceAll(".", "")
          modifiedString = modifiedString.replaceAll("-", "")
          e.target.value = modifiedString
          params.inputProps.onChange && params.inputProps.onChange(e)
        }
        return (
          <TextField
            {...params}
            label={props.inputLabel}
            placeholder={props.placeholder}
            onPaste={handlePaste}
          />
        )
      }}
      renderOption={(propsInner, option) => {
        const { key, ...otherProps } = propsInner
        const optionLabel = props.getOptionLabel(option)
        return (
          <li key={key} {...otherProps}>
            {optionLabel}
          </li>
        )
      }}
      value={value}
      onChange={props.onChange}
      getOptionLabel={props.getOptionLabel}
      filterSelectedOptions={props.filterSelectedOptions}
      loading={props.loading}
      loadingText={t("shared:loading")}
      isOptionEqualToValue={props.isOptionEqualToValue}
      style={props.style}
      size={props.size}
      multiple={props.multiple}
      disableCloseOnSelect={props.disableCloseOnSelect}
      filterOptions={props.filterOptions}
    />
  )
}

VirtualizedSelect.propTypes = {
  options: PropTypes.array.isRequired,
  inputLabel: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  filterSelectedOptions: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
  loading: PropTypes.bool,
  isOptionEqualToValue: PropTypes.func,
  style: PropTypes.object,
  multiple: PropTypes.bool,
  size: PropTypes.string,
  filterOptions: PropTypes.func,
}
