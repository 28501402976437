import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import {
  appendMessage,
  createThread,
  popMessages,
  setCurrentMessage,
  setMessageEditIndex,
  setSelectedPrompt,
} from "../../insightsSlice"
import { updateInsights, updateLLMChat } from "./llmUtils"
import { Message } from "../../types"
import useInsightsHelperFunctions from "../hooks/useInsightsHelperFunctions"
import { useTranslation } from "react-i18next"
import { EMPTY_PROMPT_PLACEHOLDER } from "features/insights/constants"
import { useLlmModelConfigs } from "../hooks/useLlmModelConfigs"
import { deterministicallyOrdered } from "utils/filterUtils"

export const InsightsUtilsRunners = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const selectedInsightPrompt = useAppSelector(
    (state: RootState) => state.insights.selectedInsightPrompt,
  )
  const currentChatMessage = useAppSelector(
    (state: RootState) => state.insights.currentChatMessage,
  )
  const currentThread = useAppSelector(
    (state: RootState) => state.insights.currentThread,
  )
  const messageEditIndex = useAppSelector(
    (state: RootState) => state.insights.messageEditIndex,
  )
  const selectedChatModel = useAppSelector(
    (state: RootState) => state.insights.selectedChatModel,
  )

  const { conversations, filters } = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  const { selectedDataMessage } = useInsightsHelperFunctions()
  const { selectedChatModelConfig } = useLlmModelConfigs()

  const prePromptingAvailable =
    selectedInsightPrompt &&
    selectedInsightPrompt.prompt &&
    selectedChatModelConfig &&
    conversations

  const runInsights = async () => {
    if (!prePromptingAvailable)
      throw new Error(
        "Could not update insights, one of the following is not defined: prompt, model or conversations.",
      )

    runCancelEdit()
    updateInsights(
      selectedInsightPrompt.prompt,
      conversations,
      dispatch,
      selectedChatModelConfig.name,
    )
  }

  const runChatCreate = () => {
    dispatch(createThread(JSON.stringify(deterministicallyOrdered(filters))))
  }

  const runChatCreateWithPrompt = () => {
    const userConfirmed = window.confirm(t("confirmations:newChat"))

    if (userConfirmed) runChatCreate()
    return userConfirmed
  }

  const runChatMessage = async () => {
    if (!currentChatMessage.prompt || !currentThread) return

    const sentMessage: Message = {
      content: currentChatMessage.prompt,
      type: "sent",
    }

    messageEditIndex !== null
      ? runChatEdit(sentMessage)
      : updateLLMChat(
          [selectedDataMessage, ...currentThread.chatMessages, sentMessage],
          selectedChatModel,
          dispatch,
        )

    dispatch(setCurrentMessage(""))
    dispatch(appendMessage(sentMessage))
    runCancelEdit()
  }

  const runChatEdit = (latestMessage: Message) => {
    if (messageEditIndex === null || !currentThread) return

    const messagesToPost = [
      ...currentThread.chatMessages.slice(0, messageEditIndex!),
      latestMessage,
    ]

    updateLLMChat(
      [selectedDataMessage, ...messagesToPost],
      selectedChatModel,
      dispatch,
    )

    dispatch(popMessages(messageEditIndex!))
  }

  const runEditMessage = (messageIndex: number) => {
    if (!currentThread) return

    dispatch(
      setSelectedPrompt({
        prompt: EMPTY_PROMPT_PLACEHOLDER,
        promptType: "summary",
      }),
    )
    dispatch(
      setCurrentMessage(currentThread.chatMessages[messageIndex].content),
    )
    dispatch(setMessageEditIndex(messageIndex))
  }

  const runCancelEdit = () => {
    dispatch(setCurrentMessage(""))
    dispatch(setMessageEditIndex(null))
  }

  return {
    runInsights,
    runChatCreate,
    runChatCreateWithPrompt,
    runChatMessage,
    runEditMessage,
    runCancelEdit,
  }
}
