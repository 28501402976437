import { IConversation } from "app/types"
import { SavedPrompt } from "../../types"
import { useAppSelector } from "app/hooks"
import { TOKEN_ESTIMATE_FACTOR } from "features/insights/constants"
import useInsightsHelperFunctions from "../hooks/useInsightsHelperFunctions"
import {
  formatLlmMessages,
  formatSampledConversations,
} from "../llmUtils/llmFormatting"
import { useLlmModelConfigs } from "../hooks/useLlmModelConfigs"

export function calculateInsightCost(
  selectedPrompt: SavedPrompt,
  conversations: IConversation[],
  providedModelName?: string,
): number {
  const insightsState = useAppSelector((state) => state.insights)

  let modelInfo

  if (providedModelName) {
    modelInfo = insightsState.insightsModelConfig.find(
      (m) => m.name === providedModelName,
    )
  } else {
    modelInfo = insightsState.insightsModelConfig.find(
      (m) => m.name === insightsState.currentThread?.model,
    )
  }

  if (!modelInfo || !modelInfo.cost || !modelInfo.cost.input) return 0

  const conversationTokens = conversations.reduce((total, conversation) => {
    const conversationText = formatSampledConversations(conversation)
    return total + Math.floor(conversationText.length * TOKEN_ESTIMATE_FACTOR)
  }, 0)

  const promptTokens = Math.floor(
    selectedPrompt.prompt.length * TOKEN_ESTIMATE_FACTOR,
  )

  const totalTokens = promptTokens + conversationTokens
  return (totalTokens / 1000000) * modelInfo.cost.input
}

export function calculateLLMChatStats(
  selectedPrompt: SavedPrompt,
  defaultLlmModelOverride?: string,
): {
  tokens: number | undefined
  cost: number | undefined
} {
  const insightsState = useAppSelector((state) => state.insights)
  const { selectedDataMessage } = useInsightsHelperFunctions()
  const { selectedChatModelConfig } = useLlmModelConfigs()

  const calculationModel =
    defaultLlmModelOverride || selectedChatModelConfig?.name

  const modelInfo = insightsState.insightsModelConfig.find(
    (m) => m.name === calculationModel,
  )

  if (
    !selectedDataMessage ||
    !insightsState.currentThread ||
    !calculationModel ||
    !modelInfo ||
    !modelInfo.cost ||
    !modelInfo.cost.input
  ) {
    return { tokens: undefined, cost: undefined }
  }

  const { formattedLLMChat } = formatLlmMessages([
    selectedDataMessage,
    ...insightsState.currentThread.chatMessages,
  ])

  const llmChatTokens = formattedLLMChat.reduce(
    (total, string) =>
      total + Math.floor(string.length * TOKEN_ESTIMATE_FACTOR),
    0,
  )

  const promptTokens = Math.floor(
    selectedPrompt.prompt.length * TOKEN_ESTIMATE_FACTOR,
  )

  const tokens = promptTokens + llmChatTokens
  const cost = (tokens / 1000000) * modelInfo.cost.input

  return { tokens, cost }
}
