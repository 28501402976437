import { ChatThread, Message } from "features/insights/types"
import RemoveChatButton from "../buttons/RemoveChatButton"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { setPreviewedThread } from "features/insights/insightsSlice"
import { RootState } from "app/store"
import { KeyValueType } from "app/types"
import MarkdownPreview from "@uiw/react-markdown-preview"
import rehypeSanitize from "rehype-sanitize"
import { dateFormatter } from "features/insights/utils/dateFormatter"
import ChatReturnButton from "../buttons/ChatReturnButton"
import { useTranslation } from "react-i18next"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"

interface ChatThreadPreviewProps {
  thread: ChatThread
  index: number
  currentChat: boolean
}

const ChatThreadPreview = ({
  thread,
  index,
  currentChat,
}: ChatThreadPreviewProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { renderableMessages } = useInsightsHelperFunctions()

  const filters: KeyValueType[] = JSON.parse(thread.filters)
  const filteredMessages = renderableMessages(thread.chatMessages, true)

  const lastMessages = filteredMessages?.slice(-3)
  if (!lastMessages) return

  const rehypePlugins = [rehypeSanitize]

  const actionBar = (
    <div className="flex-row" style={{ justifyContent: "space-between" }}>
      <h3 className="preview-header">
        {currentChat ? "Current thread" : dateFormatter(thread.lastInteraction)}
      </h3>
      {!currentChat && insightsState.previewedThread === thread.uuid && (
        <RemoveChatButton thread={thread.uuid} />
      )}
      {currentChat && insightsState.previewedThread === thread.uuid && (
        <ChatReturnButton />
      )}
    </div>
  )

  const sentMessageStyle = {
    maxWidth: "80%",
    width: "auto",
    alignSelf: "flex-end",
    background: "var(--background-gray-light)",
    padding: ".75rem",
    display: "flex",
    flexDirection: "column" as const,
  }

  const reportMessageStyle = {
    maxWidth: "80%",
    width: "auto",
    alignSelf: "flex-start",
    background: "var(--background-gray-light)",
    padding: ".75rem",
    display: "flex",
    flexDirection: "column" as const,
  }

  const firstReceivedMessageStyle = {
    alignSelf: "flex-end",
  }

  const preProcessedMessage = (index: number) => {
    return (
      <div
        className="preview-message"
        style={sentMessageStyle}
        key={`data-${index}`}
      >
        {t("labels:llmDataInserted")}
        <br />
        <label
          className="skapa-label"
          style={{ margin: "0", marginTop: ".125rem" }}
        >
          {t("descriptions:llmDataInserted")}
        </label>
      </div>
    )
  }

  const reportMessage = (index: number) => {
    return (
      <div
        className="preview-message"
        style={reportMessageStyle}
        key={`report-${index}`}
      >
        {t("labels:reportGenerated")}
        <br />
        <label
          className="skapa-label"
          style={{ margin: "0", marginTop: ".125rem" }}
        >
          {t("descriptions:reportGenerated")}
        </label>
      </div>
    )
  }

  const regularMessage = (message: Message, index: number) => {
    return (
      <div
        className="preview-message"
        key={`message-${message.type}-${index}`}
        style={message.type === "sent" ? sentMessageStyle : undefined}
      >
        <MarkdownPreview
          source={message.content}
          rehypePlugins={rehypePlugins}
          style={
            message.type === "received" && index === 0
              ? firstReceivedMessageStyle
              : undefined
          }
        />
      </div>
    )
  }

  const threadPreview = (
    <div className="preview-messages">
      {lastMessages.map((message, index) =>
        message.type === "pre-processed-data"
          ? preProcessedMessage(index)
          : message.type === "report"
            ? reportMessage(index)
            : message.type !== "raw-data"
              ? regularMessage(message, index)
              : "",
      )}
    </div>
  )

  type ShadowPosition = "top" | "bottom"

  const shadow = (placement: ShadowPosition) => {
    if (!lastMessages.length || lastMessages.length === 0) return
    const messageIndex = placement === "top" ? 0 : lastMessages.length - 1
    if (messageIndex < 0) return

    return lastMessages[messageIndex].type === "received" ? (
      <div className={`preview-shadow ${placement}`} />
    ) : (
      <div className="preview-padding" />
    )
  }

  const contents = (
    <>
      {actionBar}

      <div className="preview-messages-parent">
        {shadow("top")}
        {threadPreview}
        {shadow("bottom")}
      </div>

      <div className="filter-parent">
        {filters.map(({ key, value }, index) => (
          <div
            className="pill pill--small"
            key={index}
            style={{ pointerEvents: "none" }}
          >
            <span className="pill__label">
              {key
                .replace(/\b\w/g, (char) => char.toUpperCase())
                .replace(/_/g, " ")}
              :{" " + value}
            </span>
          </div>
        ))}
      </div>
    </>
  )

  return (
    <div
      role="button"
      tabIndex={0}
      className={
        insightsState.previewedThread === thread.uuid
          ? `chat-thread-preview focused`
          : `chat-thread-preview`
      }
      key={index}
      onClick={() => dispatch(setPreviewedThread(thread.uuid))}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          dispatch(setPreviewedThread(thread.uuid))
        }
      }}
      style={{ cursor: "pointer" }}
    >
      {contents}
    </div>
  )
}

export default ChatThreadPreview
