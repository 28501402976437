import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { useEffect } from "react"
import { fetchConversations, setDataset } from "../labelConversationsSlice"
import { validMarkets, market } from "app/types"
import { Dataset } from "../types"

export const useFetchConversations = () => {
  const dispatch = useAppDispatch()
  const {
    loadingConversations,
    conversations,
    emptyConversationFilter,
    dataset,
  } = useAppSelector((state: RootState) => state.labelConversations)

  useEffect(() => {
    if (dataset && validMarkets.includes(dataset.country as market)) {
      console.log("fetching", dataset)
      dispatch(fetchConversations(dataset))
    }
  }, [dispatch, dataset])

  const changeDataset = (dataset: Dataset) => {
    if (!validMarkets.includes(dataset.country as market)) return

    dispatch(setDataset(dataset))
  }

  return {
    loadingConversations,
    conversations,
    emptyConversationFilter,
    changeDataset,
  }
}
