import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { fetchSavedPrompts } from "features/insights/insightsSlice"

export const useStoredPrompts = () => {
  const dispatch = useAppDispatch()

  const user = useAppSelector((state: RootState) => state.auth.user)

  const savedInsightsPrompts = useAppSelector(
    (state: RootState) => state.insights.savedInsightsPrompts,
  )

  useEffect(() => {
    if (user && !savedInsightsPrompts) {
      dispatch(fetchSavedPrompts({ collectionIdentifier: user.id }))
    }
  }, [])
}
