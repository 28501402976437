import ToggleLargeContext from "features/insights/ToggleLargeContext"
import RawDataInformation from "./RawDataInformation"
import Button from "@ingka/button"
import InlineMessage from "@ingka/inline-message"
import informationCircle from "@ingka/ssr-icon/paths/information-circle"
import InitialDataSizeSelection from "./InitialDataSizeSelection"
import TokensPerConvSelection from "./TokensPerConvSelection"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import { useTranslation } from "react-i18next"
import {
  setSelectedTokensPerConversation,
  setTokensPerConversation,
} from "features/insights/insightsSlice"
import { DEFAULT_TOKENS_PER_CONVERSATION } from "features/insights/constants"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { useLlmModelConfigs } from "features/insights/utils/hooks/useLlmModelConfigs"

const RawDataControls = () => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { inputDisabled } = useInsightsHelperFunctions()
  const { t } = useTranslation()

  const { contextWindowChat } = useLlmModelConfigs()

  const updateSelectedTokensPerConversation = () => {
    if (!contextWindowChat) return
    dispatch(setSelectedTokensPerConversation())
  }

  // Currently disabled functionality until it's been tested more
  const disabledForUsers = true
  const experimentalFeatures = !disabledForUsers && (
    <>
      <ToggleLargeContext />
      <InitialDataSizeSelection />
    </>
  )

  const rawDataSettingsAltered =
    insightsState.tokensPerConversation !== DEFAULT_TOKENS_PER_CONVERSATION

  const applyButtonDisabled =
    insightsState.selectedTokensPerConversation ===
    insightsState.tokensPerConversation

  return (
    <div className="flex-column" style={{ gap: "2rem" }}>
      <InlineMessage
        title={t("warnings:explanation")}
        body={t("warnings:rawDataProcessing")}
        variant="informative"
        ssrIcon={informationCircle}
      />

      <div className="modal-section-parent">
        <div className="flex-row spaced">
          <h4>Data formatting</h4>
          <div className="flex-row">
            <Button
              type="secondary"
              disabled={inputDisabled || !rawDataSettingsAltered}
              onClick={() => {
                dispatch(
                  setTokensPerConversation(DEFAULT_TOKENS_PER_CONVERSATION),
                )
                dispatch(setSelectedTokensPerConversation())
              }}
              small
            >
              {t("buttons:resetFormatting")}
            </Button>
            <Button
              type="emphasised"
              disabled={inputDisabled || applyButtonDisabled}
              onClick={updateSelectedTokensPerConversation}
              small
            >
              Apply
            </Button>
          </div>
        </div>

        <div className="component-scaler" style={{ margin: "-1rem 0" }}>
          {experimentalFeatures}
          <TokensPerConvSelection />
          <RawDataInformation />
        </div>
      </div>
    </div>
  )
}

export default RawDataControls
