import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  createRef,
} from "react"
import {
  Box,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Typography,
  Paper,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { useFetchConversations } from "./hooks/useFetchConversations"
import { useConversationStatus } from "./hooks/useConversationStatus"
import { fetchLabels } from "./labelConversationsSlice"
import { Dataset, Entity, EntityType } from "./types"
import { ConversationPanel, RightPanel } from "./components/StyledComponents"
import MessageBubbleComponent from "./components/MessageBubble"
import EntityList from "./components/EntityList"
import LabelSelector from "./components/LabelSelector"
import ConversationDetails from "./components/ConversationDetails"
import ConversationSidePanel from "./components/ConversationSidePanel"
import FetchConversationsDialog from "./components/FetchConversationsDialog"
import { Label } from "app/types"

const FetchDialogLayout: React.FC<{
  onFetchConversations: (dataset: Dataset) => void
  onCancel: () => void
}> = ({ onFetchConversations, onCancel }) => {
  return (
    <Box
      p={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
    >
      <Box sx={{ width: "100%", maxWidth: 400 }}>
        <Typography variant="h6" gutterBottom>
          Fetch Conversations
        </Typography>
        <FetchConversationsDialog
          onFetchConversations={onFetchConversations}
          onCancel={onCancel}
        />
      </Box>
    </Box>
  )
}

function areArraysEqual<T>(arr1: T[], arr2: T[]): boolean {
  return JSON.stringify(arr1) === JSON.stringify(arr2)
}

const ConversationList: React.FC = () => {
  const dispatch = useAppDispatch()
  const {
    loadingConversations,
    conversations = [],
    emptyConversationFilter,
    changeDataset,
  } = useFetchConversations()
  const { getConversationData, setConversationData } = useConversationStatus()
  const {
    errorMessage,
    labels = [],
    loadingLabels,
    labelError,
  } = useAppSelector((state: RootState) => state.labelConversations)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [jumpToIndex, setJumpToIndex] = useState("")
  const [labelFilter, setLabelFilter] = useState("")
  const [selectedText, setSelectedText] = useState("")
  const [showPII, setShowPII] = useState(false)
  const [useTranslatedMessages, setUseTranslatedMessages] = useState(false)
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number
    mouseY: number
  } | null>(null)
  const [selectedTextContainer, setSelectedTextContainer] =
    useState<Element | null>(null)
  const [openCompleteDialog, setOpenCompleteDialog] = useState(false)
  const [skipMode, setSkipMode] = useState<
    "none" | "completed" | "labeled_and_completed"
  >("none")
  const [showFlagInput, setShowFlagInput] = useState(false)
  const [highlightedMessage, setHighlightedMessage] = useState<number | null>(
    null,
  )
  const messageRefs = useRef<{
    [key: number]: React.RefObject<HTMLDivElement | null>
  }>({})
  const dialogCancelRef = useRef<HTMLButtonElement>(null)
  const dialogConfirmRef = useRef<HTMLButtonElement>(null)

  const autocompleteRef = useRef<HTMLDivElement>(null)
  const listContainerRef = useRef<HTMLUListElement>(null)

  const currentConversation = conversations[selectedIndex]
  const currentConversationData = getConversationData(
    currentConversation?.md5_conversation_id,
  )
  const currentConversationMessages = showPII
    ? conversations[selectedIndex]?.pii_messages
    : conversations[selectedIndex]?.anon_messages

  const [entities, setEntities] = useState(Array<Entity>)

  const entitiesSaved = areArraysEqual(
    entities,
    currentConversationData.entities,
  )

  useEffect(() => {
    setEntities(currentConversationData.entities)
  }, [selectedIndex, conversations, getConversationData])

  // Initialize refs for messages when conversation changes
  useEffect(() => {
    if (currentConversationMessages) {
      messageRefs.current = currentConversationMessages.reduce(
        (acc, message) => {
          acc[message.transcript_order] = createRef<HTMLDivElement>()
          return acc
        },
        {} as { [key: number]: React.RefObject<HTMLDivElement | null> },
      )
    }
  }, [selectedIndex, conversations])

  const handleTextSelection = useCallback(() => {
    const selection = window.getSelection()
    const selectedText = selection?.toString().trim()

    if (selectedText && selectedText.length > 0) {
      const range = selection?.getRangeAt(0)
      if (range) {
        let currentNode: Node | null = range.commonAncestorContainer
        let messageElement: Element | null = null

        while (
          currentNode &&
          !(
            currentNode instanceof Element &&
            currentNode.hasAttribute("data-transcript-order")
          )
        ) {
          currentNode = currentNode.parentElement
        }

        if (currentNode && currentNode instanceof Element) {
          messageElement = currentNode
          const rect = range.getBoundingClientRect()
          setSelectedText(selectedText)
          setContextMenu({
            mouseX: rect.left,
            mouseY: rect.bottom,
          })
          setSelectedTextContainer(messageElement)
        }
      }
    } else {
      setSelectedText("")
      setContextMenu(null)
      setSelectedTextContainer(null)
    }
  }, [])

  const handleAddEntity = useCallback(
    (type: EntityType) => {
      if (
        selectedText &&
        selectedTextContainer &&
        currentConversationMessages
      ) {
        const transcriptOrder = selectedTextContainer.getAttribute(
          "data-transcript-order",
        )

        if (transcriptOrder) {
          const newEntities: Entity[] = []
          const selectedTextLower = selectedText.toLowerCase()

          // Add the initially selected entity
          newEntities.push({
            id: crypto.randomUUID(),
            ikeaValue: "",
            type,
            value: selectedText,
            transcriptOrder: parseInt(transcriptOrder),
          })

          // Find matching text in other messages
          currentConversationMessages.forEach((message) => {
            if (message.transcript_order === parseInt(transcriptOrder)) return

            const messageContent =
              message.text_raw ?? message.text ?? "[No message content]"
            const messageLower = messageContent.toLowerCase()
            let startIndex = 0

            while (true) {
              const index = messageLower.indexOf(selectedTextLower, startIndex)
              if (index === -1) break

              const exactText = messageContent.slice(
                index,
                index + selectedText.length,
              )
              newEntities.push({
                id: crypto.randomUUID(),
                ikeaValue: "",
                type,
                value: exactText,
                transcriptOrder: message.transcript_order,
              })

              startIndex = index + selectedTextLower.length
            }
          })

          const allEntities = [...entities, ...newEntities]
          setEntities(allEntities)
        }

        setContextMenu(null)
        setSelectedText("")
        setSelectedTextContainer(null)
        window.getSelection()?.removeAllRanges()
      }
    },
    [selectedText, selectedTextContainer, conversations, selectedIndex, entities],
  )

  const handleRemoveEntity = useCallback(
    (entityId: string) => {
      if (!currentConversation) return

      setEntities(entities.filter((entity) => entity.id !== entityId))
    },
    [conversations, selectedIndex, entities, setEntities],
  )

  const handleSetLabel = useCallback(
    (label: Label | null) => {
      if (!currentConversation) return

      const updatedData = {
        ...currentConversationData,
        labelId: label ? label.label_id : null,
      }
      setConversationData(currentConversation.md5_conversation_id, updatedData)
    },
    [
      currentConversation,
      selectedIndex,
      getConversationData,
      setConversationData,
    ],
  )

  const handleUpdateEntityValue = useCallback(
    (entityIds: string[], newValue: string) => {
      if (!currentConversation) return

      const newEntities = entities
        .map((entity) =>
          entityIds.includes(entity.id)
            ? { ...entity, ikeaValue: newValue }
            : entity,
        )
        .sort((a, b) => a.value.localeCompare(b.value))

      setEntities(newEntities)
    },
    [selectedIndex, conversations, entities, getConversationData, setConversationData],
  )

  const handleSaveEntityOverrides = useCallback(async () => {
    if (!currentConversation) return

    const updatedData = {
      ...currentConversationData,
      entities: entities,
    }
    await setConversationData(
      currentConversation.md5_conversation_id,
      updatedData,
    )
  }, [
    selectedIndex,
    getConversationData,
    conversations,
    entities,
    setConversationData,
  ])

  const handleCloseContextMenu = useCallback(() => {
    setContextMenu(null)
    setSelectedText("")
    setSelectedTextContainer(null)
  }, [])

  const scrollToMessage = useCallback((transcriptOrder: number) => {
    const messageRef = messageRefs.current[transcriptOrder]
    if (messageRef?.current) {
      messageRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
      setHighlightedMessage(transcriptOrder)
      setTimeout(() => {
        setHighlightedMessage(null)
      }, 2000)
    }
  }, [])

  const handleComplete = useCallback(() => {
    if (currentConversation) {
      const updatedData = {
        ...currentConversationData,
        isCompleted: !currentConversationData.isCompleted,
      }
      if (!entitiesSaved) {
        alert("Please save entity changes before completing conversation!")
        return
      }
      setConversationData(currentConversation.md5_conversation_id, updatedData)
      getNextIndex(1)
    }
    setOpenCompleteDialog(false)
  }, [
    conversations,
    selectedIndex,
    entitiesSaved,
    getConversationData,
    setConversationData,
  ])

  const getNextIndex = useCallback(
    (increment: number) => {
      let newIndex = selectedIndex + increment
      if (newIndex < 0) newIndex = 0
      if (newIndex >= conversations.length) newIndex = conversations.length - 1
      while (newIndex < conversations.length) {
        const conversationData = getConversationData(
          conversations[newIndex].md5_conversation_id,
        )
        const isCompleted = conversationData.isCompleted
        const isLabeledOrCompleted =
          conversationData.isCompleted || conversationData.labelId !== null
        if (skipMode === "completed" && !isCompleted) {
          break
        } else if (
          skipMode === "labeled_and_completed" &&
          !isLabeledOrCompleted
        ) {
          break
        } else if (skipMode === "none") {
          break
        }
        newIndex += increment
      }
      changeConversationIndex(newIndex)
    },
    [conversations, selectedIndex, skipMode, entitiesSaved],
  )

  const changeConversationIndex = (index: number) => {
    if (!entitiesSaved) {
      alert("Please save entities before changing conversation!")
      return
    }
    if (index < 0) index = 0
    if (index >= conversations.length) index = conversations.length - 1

    setSelectedIndex(index)
  }

  const handleJumpToChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      setJumpToIndex(value)
      const index = parseInt(value) - 1
      if (!isNaN(index)) {
        changeConversationIndex(index)
      }
    },
    [conversations, entitiesSaved],
  )

  // Calculate statistics
  const totalConversations = conversations.length
  const labeledConversations = conversations.filter((conv) => {
    return getConversationData(conv.md5_conversation_id).labelId !== null
  }).length
  const completedConversations = conversations.filter((conv) => {
    return getConversationData(conv.md5_conversation_id).isCompleted
  }).length

  useEffect(() => {
    dispatch(fetchLabels())
  }, [dispatch])

  // Add keyboard shortcut for complete
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (
        event.key === "Enter" &&
        !(event.target instanceof HTMLInputElement) &&
        !(event.target instanceof HTMLTextAreaElement)
      ) {
        event.preventDefault()
        if (currentConversation) {
          if (currentConversationData.labelId === null) {
            alert("Please select a label before completing the conversation.")
            return
          }
          setOpenCompleteDialog(true)
        }
      }
    }

    window.addEventListener("keypress", handleKeyPress)
    return () => window.removeEventListener("keypress", handleKeyPress)
  }, [selectedIndex, conversations, getConversationData])

  // Add keyboard navigation for previous/next conversation
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Skip if we're in an input field or the complete dialog is open
      if (
        event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement ||
        openCompleteDialog
      ) {
        return
      }

      if (event.key === "ArrowLeft") {
        event.preventDefault()
        getNextIndex(-1)
      } else if (event.key === "ArrowRight") {
        event.preventDefault()
        getNextIndex(1)
      }
    }

    window.addEventListener("keydown", handleKeyDown)
    return () => window.removeEventListener("keydown", handleKeyDown)
  }, [getNextIndex, openCompleteDialog])

  // Add effect to scroll selected conversation into view
  useEffect(() => {
    if (listContainerRef.current) {
      const selectedElement = listContainerRef.current.querySelector(
        `[data-conversation-index="${selectedIndex}"]`,
      )
      if (selectedElement) {
        selectedElement.scrollIntoView({ block: "center", behavior: "smooth" })
      }
    }
  }, [selectedIndex])

  // Handle global keyboard events for label filtering
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (
        event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement ||
        event.ctrlKey ||
        event.altKey ||
        event.metaKey ||
        event.key.length !== 1
      ) {
        return
      }

      if (autocompleteRef.current) {
        const input = autocompleteRef.current.querySelector("input")
        if (input) {
          input.focus()
          setLabelFilter(event.key)
        }
      }
    }

    window.addEventListener("keypress", handleKeyPress)
    return () => window.removeEventListener("keypress", handleKeyPress)
  }, [])

  // Update the fetch conversations button click handler
  const handleFetchConversations = useCallback(
    (dataset: Dataset) => {
      setShowFlagInput(false)
      setSelectedIndex(0)
      changeDataset(dataset)
    },
    [dispatch, changeDataset],
  )

  if (loadingConversations) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    )
  }

  if (showFlagInput || conversations.length === 0) {
    return (
      <>
        {emptyConversationFilter && <div>No Conversations Found</div>}
        <FetchDialogLayout
          onFetchConversations={handleFetchConversations}
          onCancel={() => setShowFlagInput(false)}
        />
      </>
    )
  }

  if (errorMessage) {
    return (
      <Box p={3}>
        <Typography color="error">{errorMessage}</Typography>
      </Box>
    )
  }

  // If we have conversations but none selected, show the first one
  if (conversations.length > 0 && !currentConversation) {
    setSelectedIndex(0)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "calc(100vh - 64px)",
        width: "100%",
        position: "fixed",
        top: 64,
        left: 0,
        right: 0,
        bottom: 0,
        bgcolor: "background.default",
      }}
    >
      <ConversationSidePanel
        conversations={conversations.map((conv) => ({
          md5_conversation_id: conv.md5_conversation_id,
          conversation_id: conv.conversation_id,
          country: conv.country,
          channel: conv.channel,
        }))}
        selectedIndex={selectedIndex}
        showFlagInput={showFlagInput}
        totalConversations={totalConversations}
        labeledConversations={labeledConversations}
        completedConversations={completedConversations}
        skipMode={skipMode}
        jumpToIndex={jumpToIndex}
        onSetConversationMode={(value) => setShowPII(value)}
        showPiiData={showPII}
        onSetTranslationMode={(value) => setUseTranslatedMessages(value)}
        translatedData={useTranslatedMessages}
        onShowFlagInput={() => setShowFlagInput(true)}
        onPrevious={() => getNextIndex(-1)}
        onNext={() => getNextIndex(1)}
        onSkipModeChange={setSkipMode}
        onJumpToChange={handleJumpToChange}
        onConversationSelect={setSelectedIndex}
      />

      <ConversationPanel>
        <Box
          sx={{
            width: "100%",
            maxWidth: "800px",
            margin: "0 auto",
          }}
          onMouseUp={handleTextSelection}
        >
          {currentConversationMessages?.map((message, index) => (
            <MessageBubbleComponent
              key={index}
              message={message}
              useTranslatedMessages={useTranslatedMessages}
              entities={entities}
              ref={messageRefs.current[message.transcript_order]}
              isHighlighted={highlightedMessage === message.transcript_order}
            />
          ))}
        </Box>
      </ConversationPanel>

      <RightPanel>
        <LabelSelector
          conversationId={currentConversation.conversation_id}
          selectedLabel={currentConversationData.labelId}
          labels={labels}
          labelFilter={labelFilter}
          loadingLabels={loadingLabels}
          labelError={labelError}
          onLabelChange={(_e, v) => handleSetLabel(v)}
          onLabelFilterChange={setLabelFilter}
          autocompleteRef={autocompleteRef}
        />

        <ConversationDetails
          startTime={currentConversation.start_time}
          channel={currentConversation.channel}
          country={currentConversation.country}
          label={currentConversation.label_id}
          md5ConversationId={currentConversation.md5_conversation_id}
        />

        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Entities
          </Typography>
          <EntityList
            entitiesSaved={entitiesSaved}
            entities={entities}
            onRemove={handleRemoveEntity}
            onScrollTo={scrollToMessage}
            onUpdateEntityValue={handleUpdateEntityValue}
            onSaveEntityOverrides={handleSaveEntityOverrides}
          />
        </Paper>

        <Paper elevation={3} sx={{ p: 2, mt: "auto", mb: 2 }}>
          <Button
            variant="contained"
            color={currentConversationData.isCompleted ? "error" : "success"}
            fullWidth
            onClick={() => {
              if (currentConversationData.labelId === null) {
                alert(
                  "Please select a label before completing the conversation.",
                )
                return
              }
              setOpenCompleteDialog(true)
            }}
          >
            {currentConversationData.isCompleted
              ? "Uncomplete Conversation"
              : "Complete Conversation"}
          </Button>
        </Paper>
      </RightPanel>

      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={() => handleAddEntity("store")}>
          Add as Store
        </MenuItem>
        <MenuItem onClick={() => handleAddEntity("case_number")}>
          Add as Case Number
        </MenuItem>
        <MenuItem onClick={() => handleAddEntity("order_number")}>
          Add as Order Number
        </MenuItem>
        <MenuItem onClick={() => handleAddEntity("product")}>
          Add as Product
        </MenuItem>
        <MenuItem onClick={() => handleAddEntity("service")}>
          Add as Service
        </MenuItem>
        <MenuItem onClick={() => handleAddEntity("anonymization_failure")}>
          Add as Anonymization Failure
        </MenuItem>
      </Menu>

      <Dialog
        open={openCompleteDialog}
        onClose={() => setOpenCompleteDialog(false)}
        aria-labelledby="complete-dialog-title"
        TransitionProps={{
          onEntered: () => {
            if (dialogCancelRef.current) {
              dialogConfirmRef.current?.focus()
            }
          },
        }}
        onKeyDown={(event) => {
          if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
            event.preventDefault()
            if (event.key === "ArrowLeft") {
              dialogCancelRef.current?.focus()
            } else if (event.key === "ArrowRight") {
              dialogConfirmRef.current?.focus()
            }
          }
        }}
      >
        <DialogTitle id="complete-dialog-title">
          {currentConversationData.isCompleted
            ? "Uncomplete Conversation"
            : "Complete Conversation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {currentConversationData.isCompleted
              ? "Are you sure you want to mark this conversation as incomplete? This will allow you to make further changes."
              : "Are you sure you want to mark this conversation as complete? This will help track your progress."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            ref={dialogCancelRef}
            onClick={() => setOpenCompleteDialog(false)}
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault()
                setOpenCompleteDialog(false)
              }
            }}
          >
            Cancel
          </Button>
          <Button
            ref={dialogConfirmRef}
            onClick={handleComplete}
            color={currentConversationData.isCompleted ? "error" : "success"}
            variant="contained"
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault()
                handleComplete()
              }
            }}
          >
            {currentConversationData.isCompleted ? "Uncomplete" : "Complete"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default ConversationList
