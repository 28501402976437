import { InsightModel, LLMModel, SavedPrompt } from "./types"

/* Estimation for string length -> number of tokens.
   Uses the two most common constants for estimating the number of tokens given a string length (e^-1 and 1/4)
   and averages them. **/
export const TOKEN_ESTIMATE_FACTOR = (1 / Math.E + 1 / 4) / 2
export const DEFAULT_LLM_MODEL: LLMModel = "gpt-4o-mini"
export const DEFAULT_NUMBER_OF_CONVERSATIONS = 200
export const DEFAULT_TOKENS_PER_CONVERSATION = 500

// Little hack so we don't have to change the current way of handling saved prompts
export const EMPTY_PROMPT_PLACEHOLDER_UUID =
  "723aeeeb-6b2f-471d-b4ee-ff7c2a12c2a0"

export const EMPTY_PROMPT_PLACEHOLDER: SavedPrompt = {
  displayName: "Browse prompts",
  id: EMPTY_PROMPT_PLACEHOLDER_UUID,
  prompt: "",
}

export const INSIGHTS_MODELS: InsightModel[] = [
  {
    name: "gpt-4o",
    cost: {
      input: 2.5,
      inputCached: 1.25,
      output: 10,
    },
    contextWindow: 128000,
  },
  {
    name: "gpt-4o-mini",
    cost: {
      input: 0.15,
      inputCached: 0.075,
      output: 0.6,
    },
    contextWindow: 128000,
  },
  {
    name: "o3-mini",
    cost: {
      input: 1.1,
      inputCached: 0.55,
      output: 4.4,
    },
    contextWindow: 200000,
  },
  {
    name: "gemini-1.5-pro",
    cost: {
      input: 1.25,
      output: 5,
    },
    contextWindow: 128000,
    largeContextWindow: 2000000,
  },
  {
    name: "gemini-1.5-flash",
    cost: { input: 0.075, output: 0.3 },
    contextWindow: 128000,
  },
]
