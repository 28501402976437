/** Cursor */

import React, { JSX, useEffect, useState } from "react"
import { fetchReports } from "../../app/api"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  TextProps,
} from "recharts"
import {
  Box,
  Typography,
  Paper,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  CircularProgress,
  Link,
  IconButton,
  Button,
} from "@mui/material"
import { useAppSelector } from "app/hooks"
import ReactMarkdown from "react-markdown"
import ConversationModal from "./ConversationModal"
import { TextField } from "@mui/material"
import { insightsSummary } from "app/api"
import Titlebar from "components/Titlebar"
import Text from "@ingka/text"
import { useTranslation } from "react-i18next"
import UserNavigation from "components/UserNavigation"
import PageAnimation from "components/PageAnimation"
import { market, validMarkets } from "app/types"
import MarkdownPreview from "@uiw/react-markdown-preview"
import rehypeSanitize from "rehype-sanitize"
import MetricsTable from "./MetricsTable"
import { fetchStatisticsData } from "./utils/fetchStatistics"
import { ROOT_CAUSE_OWNERS } from "./constants"
import { formatChartData } from "./utils/formatChartData"
import { useDashboardSelection } from "stores/hooks"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      sx={{
        flex: 1,
        overflow: "auto",
      }}
      {...other}
    >
      {value === index && <Box sx={{ p: 4 }}>{children}</Box>}
    </Box>
  )
}

// Add this interface for the props
interface DashboardProps {
  renderControls?: () => JSX.Element
}

// Export the controls component
export const DashboardControls = ({
  selectedMonth,
  selectedYear,
  selectedMarket,
  handlePreviousMonth,
  handleNextMonth,
  handleMarketChange,
  markets,
}: {
  selectedMonth: number
  selectedYear: number
  selectedMarket: string
  handlePreviousMonth: () => void
  handleNextMonth: () => void
  handleMarketChange: (event: any) => void
  markets: { value: string; label: string }[]
}) => {
  const date = new Date(`${selectedYear}-${selectedMonth + 1}-01`)

  const formattedMonth = new Intl.DateTimeFormat("en-US", {
    month: "long",
  }).format(date)

  const formattedYear = date.getFullYear()

  return (
    <div
      className="component-scaler"
      style={{ width: "unset", marginRight: "-2rem", paddingTop: ".25rem" }}
    >
      <div className="flex-row" style={{ gap: "1rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "#f5f5f5",
            borderRadius: 1,
            border: "1px solid #e0e0e0",
            px: 1,
            minWidth: "200px",
          }}
        >
          <IconButton
            size="small"
            onClick={handlePreviousMonth}
            sx={{ color: "#666" }}
          >
            <Typography sx={{ fontSize: "1.2rem" }}>←</Typography>
          </IconButton>
          <Typography
            sx={{
              px: 2,
              py: 1,
              fontSize: "0.875rem",
              fontWeight: "medium",
              color: "#333",
              flex: 1,
              textAlign: "center",
            }}
          >
            {formattedMonth} {formattedYear}
          </Typography>
          <IconButton
            size="small"
            onClick={handleNextMonth}
            sx={{ color: "#666" }}
          >
            <Typography sx={{ fontSize: "1.2rem" }}>→</Typography>
          </IconButton>
        </Box>

        <FormControl size="small" sx={{ minWidth: 150 }}>
          <InputLabel id="market-select-label">Market</InputLabel>
          <Select
            labelId="market-select-label"
            value={selectedMarket}
            label="Market"
            onChange={handleMarketChange}
          >
            {markets.map((market) => (
              <MenuItem key={market.value} value={market.value}>
                {market.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  )
}

const Dashboard: React.FC<DashboardProps> = ({ renderControls }) => {
  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState(0)
  const { labels } = useAppSelector((state) => state.listConversation)

  const {
    setDashboardField,
    resetDashboardField,
    selectedMonth,
    selectedYear,
    selectedRootCauseOwner,
    selectedMarket,
    selectedTopic,
    selectedAnalysis,
    selectedAnalysisFiltering,
    data,
    isLoadingAnalysis,
    selectedConversationId,
    conversationData,
    isLoadingConversation,
    conversationsMap,
    chatMessages,
    csatData,
    isLoadingCsatData,
    statisticsData,
    selectedStatistics,
  } = useDashboardSelection()

  const markets = validMarkets.map((market) => ({
    value: market,
    label: market,
  }))

  const handleMarketChange = (
    event: React.ChangeEvent<{ value: unknown }> | any,
  ) => {
    setDashboardField("selectedMarket", event.target.value as string)
  }

  const handleTabChange = async (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)

    // Load CSAT data when switching to CSAT tab
    if (newValue === 1 && (!csatData || csatData.country !== selectedMarket)) {
      setDashboardField("isLoadingCsatData", true)
      try {
        // Fetch CSAT analysis from Firestore
        const country = selectedMarket
        console.log("Starting CSAT data fetch for country:", country)

        const csatDoc = (
          await fetchReports({
            country: selectedMarket,
            year: selectedYear.toString(),
            month: (selectedMonth + 1).toString(),
            label_id: selectedLabelId ?? "",
            is_csat: true,
          })
        ).data

        console.log("CSAT query completed:", { csatDoc })

        if (!csatDoc || !csatDoc.evaluationResults) {
          console.log("No CSAT documents found")
          setDashboardField("csatData", {
            analysis:
              "No CSAT analysis is currently available for this market.",
            conversations: {},
          })
          return
        }

        console.log("Latest CSAT doc found:", !!csatDoc)

        if (csatDoc) {
          // Extract analysis and conversations using the same logic as Analysis tab
          const cleanAnalysis = csatDoc.evaluationResults
            .split(/^## Analysis/im)[1] // Split at "## Analysis" and take what comes after
            ?.trim() // Remove leading/trailing whitespace

          setDashboardField("csatData", {
            analysis: cleanAnalysis || csatDoc.evaluationResults,
            conversations: csatDoc.formattedConversations || {},
            country: csatDoc.metadata.country,
          })

          // Store conversations in the map
          if (csatDoc.formattedConversations) {
            const newConversationsMap: Record<string, string> = {}
            const conversationsText = csatDoc.formattedConversations

            const conversationMatches = conversationsText.matchAll(
              /<conversation id="(\d+)"[^>]*>(.*?)<\/conversation>/gs,
            )
            for (const match of conversationMatches) {
              const [fullMatch, id] = match
              newConversationsMap[id] = fullMatch
            }

            setDashboardField("conversationsMap", newConversationsMap)
          }

          // Initialize chat messages for CSAT analysis
          const initialMessage: ChatMessage = {
            type: "assistant",
            content: `I've analyzed the CSAT data and customer feedback, and I'm ready to help you understand the insights. You can ask me questions about:

1. Common reasons for low CSAT scores
2. Patterns in customer feedback
3. Specific customer complaints or issues
4. Agent responses and their effectiveness
5. Suggestions for improvement

What would you like to know about the CSAT analysis?`,
          }
          setDashboardField("chatMessages", [initialMessage])
        } else {
          console.warn("No data in the latest CSAT document")
          setDashboardField("csatData", {
            analysis: "No CSAT analysis content available.",
            conversations: {},
          })
        }
      } catch (error) {
        console.error("Error in CSAT data fetch:", error)
        const errorMessage =
          error instanceof Error ? error.message : "Unknown error"
        setDashboardField("csatData", {
          analysis: `Error loading CSAT analysis: ${errorMessage}. Please try again.`,
          conversations: {},
        })
      } finally {
        resetDashboardField("isLoadingCsatData")
      }
    }
  }

  const [selectedLabelId, setSelectedLabelId] = useState<string | null>(null)

  const handleBarClick = async (data: LabelData) => {
    resetDashboardField("selectedAnalysis")

    // If clicking the already selected bar, unselect it
    if (selectedTopic === (data.displayName || `Label ${data.label_id}`)) {
      resetDashboardField("selectedTopic")
      resetDashboardField("selectedStatistics")
      resetDashboardField("conversationsMap")
      resetDashboardField("chatMessages")
      resetDashboardField("selectedAnalysisFiltering")
      return
    }

    setDashboardField("isLoadingAnalysis", true)
    setDashboardField(
      "selectedTopic",
      data.displayName || `Label ${data.label_id}`,
    )

    // Update selected statistics
    if (statisticsData && data.label_id) {
      setDashboardField("selectedStatistics", statisticsData[data.label_id])
      setSelectedLabelId(data.label_id)
    }

    try {
      const analysis = (
        await fetchReports({
          country: selectedMarket,
          year: selectedYear.toString(),
          month: (selectedMonth + 1).toString(),
          label_id: data.label_id,
          is_csat: false,
        })
      ).data

      if (analysis && analysis.evaluationResults) {
        // Extract only the analysis part from the evaluation results
        const cleanAnalysis = analysis.evaluationResults
          .split(/^## Analysis/im)[1] // Split at "## Analysis" and take what comes after
          ?.trim() // Remove leading/trailing whitespace

        setDashboardField(
          "selectedAnalysis",
          cleanAnalysis || analysis.evaluationResults,
        )
        setDashboardField("selectedAnalysisFiltering", analysis.filter)

        // Extract and store conversations if available
        if (analysis.formattedConversations) {
          const newConversationsMap: Record<string, string> = {}
          const conversationsText = analysis.formattedConversations

          // Find all conversations and store them by ID
          const conversationMatches = conversationsText.matchAll(
            /<conversation id="(\d+)"[^>]*>(.*?)<\/conversation>/gs,
          )
          for (const match of conversationMatches) {
            const [fullMatch, id] = match
            newConversationsMap[id] = fullMatch
          }

          setDashboardField("conversationsMap", newConversationsMap)
        }

        // Reset chat messages and initialize with system message
        const initialMessage: ChatMessage = {
          type: "assistant",
          content: `I've analyzed the conversations about "${data.displayName?.split("::").pop()}" and I'm ready to help you understand the insights. You can ask me questions to get more details.`,
        }
        setDashboardField("chatMessages", [initialMessage])

        // Switch to the Analysis tab
        setTabValue(0)
      } else {
        setDashboardField(
          "selectedAnalysis",
          "No analysis available for this topic in the selected market and time period.",
        )
        resetDashboardField("conversationsMap")
        resetDashboardField("chatMessages")
        resetDashboardField("selectedAnalysisFiltering")
      }
    } catch (error) {
      console.error("Error fetching analysis:", error)
      setDashboardField(
        "selectedAnalysis",
        "Error loading analysis. Please try again.",
      )
      setDashboardField("selectedAnalysisFiltering", null)
      resetDashboardField("conversationsMap")
      resetDashboardField("chatMessages")
    } finally {
      resetDashboardField("isLoadingAnalysis")
    }
  }

  const handleConversationClick = (conversationId: string) => {
    setDashboardField("selectedConversationId", conversationId)
    setDashboardField("isLoadingConversation", true)

    try {
      const conversationContent = conversationsMap[conversationId]
      if (conversationContent) {
        setDashboardField("conversationData", {
          formattedConversations: conversationContent,
        })
      } else {
        resetDashboardField("conversationData")
      }
    } catch (error) {
      console.error("Error retrieving conversation:", error)
      resetDashboardField("conversationData")
    } finally {
      resetDashboardField("isLoadingConversation")
    }
  }

  const handleCloseConversation = () => {
    resetDashboardField("selectedConversationId")
    resetDashboardField("conversationData")
  }

  const renderAnalysisWithClickableIds = (text: string) => {
    if (!text) return null

    // Transform conversation IDs into markdown links
    const transformedText = text.replace(
      /(Conversation ID|Reference ID|RefID)(?:: ?| )(\d+)/g,
      (match, _, id) => `[${match}](conversation/${id})`,
    )

    return (
      <Box
        sx={{
          typography: "body1",
          "& h1, & h2, & h3, & h4": {
            fontWeight: "bold",
            mt: 2,
            mb: 1,
          },
          "& h1": { fontSize: "1.5rem" },
          "& h2": { fontSize: "1.3rem" },
          "& h3": { fontSize: "1.1rem" },
          "& p": { mb: 2 },
          "& ul": {
            pl: 4,
            mb: 2,
            listStyle: "disc",
          },
          "& li": { mb: 1 },
          "& strong": { fontWeight: "bold" },
        }}
      >
        <MarkdownPreview
          source={transformedText}
          rehypePlugins={[rehypeSanitize]}
          components={{
            a: ({ children, ...props }) => (
              <Link
                component="button"
                onClick={() => {
                  const id = props.href?.split("/")[1]
                  if (id) handleConversationClick(id)
                }}
                sx={{
                  display: "inline",
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#1976d2",
                  "&:hover": {
                    color: "#1565c0",
                  },
                }}
              >
                {children as React.ReactNode}
              </Link>
            ),
          }}
        />
      </Box>
    )
  }

  const analysisStyles = {
    "& h3": {
      fontSize: "1.1rem",
      fontWeight: "bold",
      mt: 4,
      mb: 3,
      color: "#333",
    },
    "& p": {
      mb: 4,
      fontSize: "0.95rem",
      lineHeight: 1.8,
      color: "#444",
      maxWidth: "900px",
    },
    "& ul": {
      pl: 4,
      mb: 4,
      maxWidth: "900px",
    },
    "& li": {
      fontSize: "0.95rem",
      mb: 2.5,
      color: "#444",
      lineHeight: 1.8,
    },
    "& strong": {
      color: "#333",
      fontWeight: "bold",
    },
  }

  const handleSendMessage = async (message: string) => {
    if (!message.trim() || (!selectedAnalysis && !csatData)) return

    const newUserMessage: ChatMessage = { type: "user", content: message }
    const updatedMessages = [...chatMessages, newUserMessage]
    setDashboardField("chatMessages", updatedMessages)

    try {
      // Determine which analysis to use based on active tab
      const analysisContent =
        tabValue === 1 ? csatData?.analysis : selectedAnalysis
      const analysisType = tabValue === 1 ? "CSAT Analysis" : "Topic Analysis"

      // Create conversation prompt that includes both analysis and conversations
      const conversationPrompt = `Here are the ${analysisType.toLowerCase()} results and the referenced conversations. Please analyze them to answer the user's question:

Analysis Results:
${analysisContent}

Referenced Conversations:
${Object.entries(conversationsMap)
  .map(([id, conv]) => `Conversation ${id}:\n${conv}`)
  .join("\n\n")}

User Question: ${message}

Please provide a specific answer based on both the analysis and the actual conversations. If asked about a specific conversation, provide details from that conversation.`

      // Call the API
      const response = await insightsSummary({
        insights: [conversationPrompt],
        prompt: `You are a helpful assistant with access to both the ${analysisType.toLowerCase()} and the actual conversations. When answering questions, use both the analysis insights and the specific conversation details. For conversation-specific questions, look up the actual conversation content and provide accurate details.`,
        model: "gpt-4o-mini",
      })

      if (!response?.data?.analysis) {
        throw new Error("No response received")
      }

      const assistantMessage: ChatMessage = {
        type: "assistant",
        content: response.data.analysis,
      }

      setDashboardField("chatMessages", [...updatedMessages, assistantMessage])
    } catch (error) {
      console.error("Error sending message:", error)
      const errorMessage: ChatMessage = {
        type: "assistant",
        content:
          "I apologize, but I encountered an error processing your request. Please try again.",
      }
      setDashboardField("chatMessages", [...updatedMessages, errorMessage])
    }
  }

  const handleResetChat = () => {
    resetDashboardField("chatMessages")
  }

  // Add chat interface component
  const ChatInterface = () => {
    const messagesContainerRef = React.useRef<HTMLDivElement>(null)

    const scrollToBottom = () => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop =
          messagesContainerRef.current.scrollHeight
      }
    }

    React.useEffect(() => {
      scrollToBottom()
    }, [chatMessages])

    return (
      <Paper
        elevation={0}
        sx={{
          mt: 4,
          p: 3,
          backgroundColor: "#fff",
          border: "1px solid #e0e0e0",
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ color: "#333" }}>
            Chat with Results
          </Typography>
          <Button
            variant="outlined"
            size="small"
            onClick={handleResetChat}
            sx={{
              textTransform: "none",
              color: "#666",
              borderColor: "#ccc",
              "&:hover": {
                borderColor: "#999",
                backgroundColor: "#f5f5f5",
              },
            }}
          >
            Reset Chat
          </Button>
        </Box>

        <Box
          ref={messagesContainerRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "400px",
            overflowY: "auto",
            p: 2,
            backgroundColor: "#fafafa",
            borderRadius: 1,
            border: "1px solid #e0e0e0",
            mb: 2,
            scrollBehavior: "smooth",
          }}
        >
          {chatMessages.map((message, index) => (
            <Box
              key={index}
              sx={{
                alignSelf: message.type === "user" ? "flex-end" : "flex-start",
                maxWidth: "80%",
                p: 2,
                borderRadius: 2,
                backgroundColor: message.type === "user" ? "#e3f2fd" : "#fff",
                boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  display: "block",
                  mb: 0.5,
                  color: "#666",
                }}
              >
                {message.type === "user" ? "You" : "Assistant"}
              </Typography>
              <ReactMarkdown>{message.content}</ReactMarkdown>
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Ask about the analysis results..."
            multiline
            rows={2}
            onKeyPress={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault()
                const input = e.target as HTMLInputElement
                if (input.value.trim()) {
                  handleSendMessage(input.value)
                  input.value = ""
                }
              }
            }}
          />
          <Button
            variant="contained"
            onClick={(e) => {
              const input = e.currentTarget
                .previousElementSibling as HTMLInputElement
              if (input.value.trim()) {
                handleSendMessage(input.value)
                input.value = ""
              }
            }}
            sx={{
              height: "100%",
              textTransform: "none",
              backgroundColor: "#003E72",
              "&:hover": {
                backgroundColor: "#0055a0",
              },
            }}
          >
            Send
          </Button>
        </Box>
      </Paper>
    )
  }

  const handlePreviousMonth = () => {
    let previousMonth = selectedMonth - 1
    let newYear = selectedYear

    if (previousMonth < 0) {
      previousMonth = 11
      newYear -= 1
    }

    setDashboardField("selectedMonth", previousMonth)
    setDashboardField("selectedYear", newYear)
  }

  const handleNextMonth = () => {
    let nextMonth = selectedMonth + 1
    let newYear = selectedYear

    if (nextMonth > 11) {
      nextMonth = 0
      newYear += 1
    }

    setDashboardField("selectedMonth", nextMonth)
    setDashboardField("selectedYear", newYear)
  }

  const formatAndSetData = (fetchedStats: StatisticsData | null) => {
    if (!fetchedStats) {
      resetDashboardField("statisticsData")
      resetDashboardField("data")
      return
    }

    setDashboardField("statisticsData", fetchedStats)
    setDashboardField(
      "data",
      formatChartData(fetchedStats, selectedRootCauseOwner, labels),
    )
  }

  // Fetch and apply statistics and chart data when the selected market or time changes
  useEffect(() => {
    const params = {
      market: selectedMarket as market,
      year: selectedYear,
      month: selectedMonth,
    }

    fetchStatisticsData(params, formatAndSetData, () => formatAndSetData(null))
  }, [selectedMarket, selectedMonth, labels])

  // Format and apply the chart data when root cause owner changes
  useEffect(() => {
    formatAndSetData(statisticsData)
  }, [selectedRootCauseOwner])

  useEffect(() => {
    if (selectedAnalysisFiltering) {
      setDashboardField(
        "selectedAnalysisFiltering",
        selectedAnalysisFiltering.replace(/\//g, ""),
      )
    } else {
      resetDashboardField("selectedAnalysisFiltering")
    }
  }, [selectedLabelId, selectedAnalysisFiltering])

  const titlebarContent = (
    <>
      <div className="flex-row" style={{ gap: "1rem", width: "auto" }}>
        <Text tagName="h1" headingSize="s">
          {t("buttons:report")}
        </Text>
      </div>
      <div className="flex-row" style={{ gap: ".5rem" }}>
        <div className="flex-row" style={{ gap: "1.5rem", width: "auto" }}>
          {renderControls?.() || (
            <DashboardControls
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
              selectedMarket={selectedMarket}
              handlePreviousMonth={handlePreviousMonth}
              handleNextMonth={handleNextMonth}
              handleMarketChange={handleMarketChange}
              markets={markets}
            />
          )}
        </div>
        <UserNavigation />
      </div>
    </>
  )

  interface CustomTickProps extends TextProps {
    payload?: { value: string }
  }

  const CustomYAxisTick: React.FC<CustomTickProps> = ({ x, y, payload }) => {
    if (!payload || typeof payload.value !== "string") return null

    const text = payload.value.split("::").pop() || payload.value

    return (
      <text x={x} y={y} dy={4} fontSize={11} textAnchor="end" fill="#666">
        {text.length > 35 ? `${text.substring(0, 35)}...` : text}
      </text>
    )
  }

  const barHeight = 30
  const minHeight = 300
  const chartHeight = Math.max(data.length * barHeight, minHeight)

  return (
    <>
      <Titlebar>{titlebarContent}</Titlebar>
      <PageAnimation>
        <div
          className="flex-column"
          style={{
            padding: "1.25rem",
            gap: "var(--vertical-padding)",
            width: "100%",
            overflow: "auto",
          }}
        >
          {/* Top Row */}
          <Grid container item spacing={4}>
            {/* Barchart */}
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <div className="flex-row spaced" style={{ height: "2.5rem" }}>
                <div className="flex-column" style={{ gap: "unset" }}>
                  <Text headingSize="s">Top NVACs</Text>
                  <Text bodySize="s">Non-Value Added Contacts</Text>
                </div>

                {/* Root Cause Owner Dropdown */}
                <div
                  className="component-scaler"
                  style={{ alignSelf: "flex-end", width: "unset" }}
                >
                  <FormControl
                    size="small"
                    sx={{ minWidth: 250, alignSelf: "flex-end" }}
                  >
                    <InputLabel id="root-cause-owner-label">
                      Root cause owner
                    </InputLabel>
                    <Select
                      labelId="root-cause-owner-label"
                      value={selectedRootCauseOwner}
                      label="Root cause owner"
                      onChange={(event) =>
                        setDashboardField(
                          "selectedRootCauseOwner",
                          event.target.value as string,
                        )
                      }
                    >
                      {ROOT_CAUSE_OWNERS.map((owner) => (
                        <MenuItem key={owner.value} value={owner.value}>
                          {owner.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="chart-parent">
                <Box
                  sx={{
                    flexGrow: 1,
                    width: "100%",
                    "& .recharts-wrapper": {
                      height: "auto",
                    },
                    height: `calc(${chartHeight}rem / 16)`,
                  }}
                >
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={data}
                      layout="vertical"
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      barSize={15}
                    >
                      <CartesianGrid
                        strokeDasharray="3 3"
                        horizontal={false}
                        stroke="#e0e0e0"
                      />
                      <XAxis
                        type="number"
                        tickFormatter={(value) =>
                          `${(value * 100).toFixed(1)}%`
                        }
                        domain={[0, "dataMax"]}
                        stroke="#666"
                        fontSize={12}
                      />
                      <YAxis
                        type="category"
                        dataKey="displayName"
                        width={200}
                        tick={<CustomYAxisTick />}
                        stroke="#666"
                      />
                      <Tooltip
                        formatter={(value: number) => [
                          `${(value * 100).toFixed(1)}%`,
                          "Percentage",
                        ]}
                        contentStyle={{
                          fontSize: 12,
                          backgroundColor: "#fff",
                          border: "1px solid #e0e0e0",
                          borderRadius: 4,
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        }}
                        cursor={{ fill: "#f5f5f5" }}
                      />
                      <Bar
                        dataKey="percentage"
                        name="Percentage"
                        onClick={handleBarClick}
                        style={{ cursor: "pointer" }}
                        minPointSize={10}
                      >
                        {data.map((entry) => (
                          <Cell
                            key={`cell-${entry.label_id}`}
                            fill={
                              entry.displayName === selectedTopic
                                ? "#003E72"
                                : "#0055a0"
                            }
                            radius={4}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </div>
            </Grid>

            {/* Conversation Statistics */}
            <Grid item xs={12} md={5}>
              <div className="flex-column" style={{ gap: "1.5rem" }}>
                <div
                  className="flex-row"
                  style={{ height: "2.25rem", marginBottom: ".25rem" }}
                >
                  <div className="flex-column" style={{ gap: "unset" }}>
                    <Text headingSize="s">Conversation Statistics</Text>
                    <Text bodySize="s">
                      {selectedStatistics
                        ? selectedTopic?.split("::").pop()
                        : "For all categories"}
                    </Text>
                  </div>
                </div>
                {/* Total Conversations Card */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    py: 2,
                    bgcolor: "#f5f5f5",
                    borderRadius: 2,
                    mx: 0,
                  }}
                >
                  <Text headingSize="l">
                    {selectedStatistics
                      ? selectedStatistics.conversations.toLocaleString()
                      : statisticsData
                        ? Object.values(
                            statisticsData as Record<
                              string,
                              { conversations: number }
                            >,
                          )
                            .reduce((sum, item) => sum + item.conversations, 0)
                            .toLocaleString()
                        : "0"}
                  </Text>
                  <Text bodySize="s">Conversations</Text>
                </Box>

                {/* Metrics Table */}
                <MetricsTable selectedStatistics={selectedStatistics} />
              </div>
            </Grid>
          </Grid>

          {/* Analysis Section */}
          <Grid item xs={12}>
            <div
              className="flex-column"
              style={{ gap: "unset", margin: "-1rem 0 1.5rem 0" }}
            >
              <Text headingSize="s">Reports</Text>
              <Text bodySize="s">Powered by MOSAIK GPT</Text>
            </div>
            <Paper
              elevation={2}
              sx={{
                bgcolor: "#fff",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                borderRadius: 2,
              }}
            >
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  sx={{
                    px: 3,
                    "& .MuiTab-root": {
                      fontSize: "0.9rem",
                      fontWeight: "medium",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                      color: "#666",
                      py: 2,
                      "&.Mui-selected": {
                        color: "#003E72",
                        fontWeight: "bold",
                      },
                    },
                  }}
                >
                  <Tab label="Analysis" />
                  <Tab label="CSAT" />
                  <Tab label="Topics" />
                </Tabs>
              </Box>

              <TabPanel value={tabValue} index={0}>
                <Box sx={analysisStyles}>
                  {isLoadingAnalysis ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : selectedAnalysis ? (
                    <Box sx={{ height: "100%", overflow: "auto" }}>
                      <Typography
                        variant="h5"
                        sx={{
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                          color: "#003E72",
                          mb: 4,
                          pb: 2,
                          borderBottom: "2px solid #e0e0e0",
                        }}
                      >
                        {selectedTopic?.split("::").pop()}
                      </Typography>
                      {renderAnalysisWithClickableIds(selectedAnalysis)}
                    </Box>
                  ) : (
                    <Text bodySize="s">
                      Select a topic from the chart above to view detailed
                      analysis and insights.
                    </Text>
                  )}
                </Box>
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <Box sx={analysisStyles}>
                  {isLoadingCsatData ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : csatData ? (
                    <Box sx={{ height: "100%", overflow: "auto" }}>
                      <Typography
                        variant="h5"
                        sx={{
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                          color: "#003E72",
                          mb: 4,
                          pb: 2,
                          borderBottom: "2px solid #e0e0e0",
                        }}
                      >
                        Customer Satisfaction Analysis
                      </Typography>
                      {renderAnalysisWithClickableIds(csatData.analysis)}
                    </Box>
                  ) : (
                    <>
                      <Typography
                        variant="h5"
                        sx={{
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                          color: "#003E72",
                          mb: 4,
                          pb: 2,
                          borderBottom: "2px solid #e0e0e0",
                        }}
                      >
                        Customer Satisfaction Analysis
                      </Typography>
                      <Typography>
                        Switch to the CSAT tab to view detailed analysis of
                        customer satisfaction data and feedback.
                      </Typography>
                    </>
                  )}
                </Box>
              </TabPanel>

              <TabPanel value={tabValue} index={2}>
                <Box>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      color: "#003E72",
                      mb: 4,
                      pb: 2,
                      borderBottom: "2px solid #e0e0e0",
                    }}
                  >
                    Conversation Topics
                  </Typography>
                  <Typography>
                    No topic analysis is currently available for this market.
                  </Typography>
                </Box>
              </TabPanel>
            </Paper>

            {/* Analysis Chat Interface */}
            {(selectedAnalysis || csatData) && (
              <Box sx={{ mt: 4 }}>
                <ChatInterface />
              </Box>
            )}
          </Grid>

          <ConversationModal
            open={!!selectedConversationId}
            onClose={handleCloseConversation}
            conversationId={selectedConversationId}
            conversation={conversationData}
            loading={isLoadingConversation}
          />
        </div>
      </PageAnimation>
    </>
  )
}

export default Dashboard
