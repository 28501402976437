import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { SheetViewProps } from "../../types"
import {
  setAdvancedControlsSheetVisible,
  setChatHistoryVisibility,
} from "../../insightsSlice"
import AdvancedView from "../../components/views/AdvancedView"
import AdvancedViewDetails from "../../components/views/AdvancedViewDetails"
import HistoryView from "../../components/views/HistoryView"
import HistoryChatView from "../../components/views/HistoryChatView"
import SheetView from "components/SheetView"
import { usePromptCruds } from "./usePromptCruds"
import PromptCrud from "features/insights/PromptCrud"
import { toggleMarketSettingsVisiblity } from "features/admin/marketSettingsSlice"
import MarketSettingsPage from "features/admin/MarketSettings"

export function useSheetViews() {
  const dispatch = useAppDispatch()
  const advancedControlsSheetVisible = useAppSelector(
    (state: RootState) => state.insights.advancedControlsSheetVisible,
  )
  const chatHistoryVisible = useAppSelector(
    (state: RootState) => state.insights.chatHistoryVisible,
  )
  const marketSettingsVisible = useAppSelector(
    (state: RootState) => state.marketSettings.visible,
  )

  const { savedInsightsPrompts, onAddPrompt, onPromptSave, onPromptDelete } =
    usePromptCruds()

  const prePromptCrud = (
    <PromptCrud
      prompts={savedInsightsPrompts}
      promptType="insight"
      onAddPrompt={onAddPrompt}
      onPromptSave={onPromptSave}
      onPromptDelete={onPromptDelete}
    />
  )

  const advancedView: SheetViewProps = {
    visible: advancedControlsSheetVisible,
    handleClose: () => dispatch(setAdvancedControlsSheetVisible(false)),
    content: <AdvancedView promptCrud={prePromptCrud} />,
    footer: <></>,
    details: <AdvancedViewDetails />,
  }

  const historyView: SheetViewProps = {
    visible: chatHistoryVisible,
    handleClose: () => {
      dispatch(setChatHistoryVisibility(false))
    },
    content: <HistoryView />,
    footer: <></>,
    details: <HistoryChatView />,
  }

  const marketSettingsView: SheetViewProps = {
    visible: marketSettingsVisible,
    handleClose: () => {
      dispatch(toggleMarketSettingsVisiblity())
    },
    content: <MarketSettingsPage />,
    footer: <></>,
    details: <></>,
  }

  return {
    historyViewSheet: <SheetView {...historyView} />,
    advancedViewSheet: <SheetView {...advancedView} />,
    marketSettingsSheet: <SheetView {...marketSettingsView} />,
  }
}
