import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { useEffect } from "react"
import { fetchEntities } from "../labelConversationsSlice"
import { validMarkets, market } from "app/types"

export const useFetchEntities = () => {
  const dispatch = useAppDispatch()
  const { loadingEntities, entities, dataset, entityReloadRequired } =
    useAppSelector((state: RootState) => state.labelConversations)

  useEffect(() => {
    if (
      entityReloadRequired &&
      dataset &&
      validMarkets.includes(dataset.country as market)
    ) {
      dispatch(fetchEntities(dataset.country))
    }
  }, [dispatch, entityReloadRequired])

  return { loadingEntities, entityOptions: entities }
}
