import { RootState } from "app/store"
import { useAppSelector } from "app/hooks"
import { VList } from "virtua"
import { JSX } from "react"

interface ResultsListProps {
  results: JSX.Element[] | undefined
}

const ResultsList: React.FC<ResultsListProps> = ({ results }) => {
  const { loadingConversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  return (
    <VList
      style={{
        flexGrow: 1,
        height: "auto",
        width: "unset",
        ...(loadingConversations
          ? { overflow: "hidden" }
          : { overflow: "auto" }),
      }}
    >
      {results}
    </VList>
  )
}

export default ResultsList
