import PromptInput from "features/insights/PromptInput"
import { PromptControlProps } from "features/insights/types"
import InlineMessage from "@ingka/inline-message"
import informationCircle from "@ingka/ssr-icon/paths/information-circle"
import InsightsButton from "../buttons/InsightsButton"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { displayCost } from "../../utils/costUtils/costFormatting"
import { calculateInsightCost } from "features/insights/utils/costUtils/costUtils"
import LLMModelSelectionChat from "./LLMModelSelectionChat"
import { useTranslation } from "react-i18next"
import Button from "@ingka/button"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import { resetInsights } from "features/insights/insightsSlice"

const LLMProcessing = ({ promptCrud }: PromptControlProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { conversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const { selectedInsightPrompt } = useAppSelector(
    (state: RootState) => state.insights,
  )
  const { prePromptedInsights } = useInsightsHelperFunctions()

  const promptInfo = () => {
    const cost = calculateInsightCost(
      selectedInsightPrompt,
      conversations || [],
    )

    return (
      <div className="flex-row">
        <LLMModelSelectionChat />
        {cost ? displayCost(cost) : ""}
      </div>
    )
  }

  return (
    <div className="flex-column" style={{ gap: "2rem" }}>
      <InlineMessage
        title={t("warnings:explanation")}
        body={t("warnings:llmDataProcessing")}
        variant="informative"
        ssrIcon={informationCircle}
      />

      <div className="modal-section-parent">
        <div className="flex-row spaced">
          <h4>{t("labels:prompt")}</h4>
          <div className="flex-row">
            <Button
              type="secondary"
              disabled={!prePromptedInsights}
              onClick={() => dispatch(resetInsights())}
              small
            >
              {t("buttons:resetInsights")}
            </Button>
            <InsightsButton />
          </div>
        </div>
        <div style={{ margin: "0 0 0 0" }}>
          <label className="skapa-label">
            {t("labels:llmPreProcessinPromptLabel")}
          </label>
          <PromptInput type="insight" promptInfo={promptInfo()} />
          {promptCrud && promptCrud}
        </div>
      </div>
    </div>
  )
}

export default LLMProcessing
