import React from "react"
import UserNavigation from "./UserNavigation"
import Text from "@ingka/text"

interface TitlebarProps {
  title?: string
  children?: React.ReactNode
}

const Titlebar: React.FC<TitlebarProps> = ({ title, children }) => {
  const defaultContent = (
    <>
      <div className="flex-row" style={{ gap: "1rem", width: "auto" }}>
        <Text tagName="h1" headingSize="s">
          {title}
        </Text>
      </div>

      <div className="flex-row" style={{ gap: "0", width: "auto" }}>
        <UserNavigation />
      </div>
    </>
  )

  return (
    <div className="pane-title-bar">
      <div className="pane-title-bar-scaler">
        <div className="flex-row spaced wrapped">
          {children ? children : defaultContent}
        </div>
      </div>
    </div>
  )
}

export default Titlebar
