import { SheetViewProps } from "features/insights/types"
import FilterSelection from "./FilterSelection"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { toggleFilterMenu } from "features/listConversations/listConversationSlice"
import { useDispatch } from "react-redux"
import SheetView from "../SheetView"

const FilterSelectionModal = () => {
  const dispatch = useDispatch()
  const visible = useAppSelector(
    (state: RootState) => state.listConversation.filterMenuOpen,
  )

  const filterSelectionView: SheetViewProps = {
    visible: visible,
    handleClose: () => dispatch(toggleFilterMenu()),
    content: <FilterSelection />,
    footer: <></>,
    details: <></>,
    rightSide: true,
  }

  return <SheetView {...filterSelectionView} />
}

export default FilterSelectionModal
