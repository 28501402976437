import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import "./messageList.css"
import MessageItem from "./MessageItem"
import { useTranslation } from "react-i18next"
import LoadingInstructionHandler from "components/LoadingInstructionHandler"
import useConversationsHelperFunctions from "features/listConversations/hooks/useConversationsHelperFunctions"

const ConversationDetails = () => {
  const { t } = useTranslation()
  const { loadingConversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const { messages } = useAppSelector((state: RootState) => state.messages)
  const { conversationSelected } = useConversationsHelperFunctions()

  const conversation = (
    <div
      className="flex-column"
      style={{
        flexGrow: "0",
        gap: ".5rem",
        padding: "var(--vertical-padding) 0",
      }}
    >
      {messages.map((message, index) => (
        <MessageItem key={index} messageObj={message} index={index} />
      ))}
    </div>
  )

  return (
    <LoadingInstructionHandler
      showLoading={loadingConversations}
      loadingLabel={t("labels:fetchingConversations")}
      showInstruction={!conversationSelected}
      instructionLabel={t("labels:selectConversation")}
    >
      {conversation}
    </LoadingInstructionHandler>
  )
}

export default ConversationDetails
