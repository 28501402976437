interface ChatStyles {
  llmChatPadding: (clientHeight: number | null) => React.CSSProperties
}

export const styles: ChatStyles = {
  llmChatPadding: (clientHeight) => ({
    paddingBottom: clientHeight
      ? `calc(${clientHeight}px + var(--page-bottom-padding) + .75rem)`
      : "0px",
    minHeight: clientHeight
      ? `calc(100% - (${clientHeight}px + var(--page-bottom-padding) + .75rem))`
      : "100%",
  }),
}
