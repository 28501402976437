import { ChatThread } from "features/insights/types"

export const rangeFields = ["lastInteraction"] as const
export const nonRangeFields = ["filters", "uuid"] as const

export const sortableFields = rangeFields.reduce(
  (acc, field) => {
    acc[field] = field
    return acc
  },
  {} as Record<string, string>
)

export type SortingDirection = "asc" | "desc"

export type ChatThreadFilter = {
  [K in keyof ChatThread]?: K extends (typeof rangeFields)[number]
    ? { min?: number; max?: number }
    : K extends (typeof nonRangeFields)[number]
      ? string
      : never
} & {
  sorting?: ChatThreadSorting
}

export type ChatThreadSorting = {
  field: (typeof rangeFields)[number]
  direction: SortingDirection
}
