import { ClientStorageKeys } from "app/types"
import { t } from "i18next"

export const writeToStorage = (
  key: ClientStorageKeys,
  data: unknown,
): boolean => {
  try {
    sessionStorage.setItem(key, JSON.stringify(data))
    return true
  } catch (e) {
    console.error(`Failed to write to storage for key ${key}:`, e)
    if (e instanceof DOMException && e.name === "QuotaExceededError") {
      window.alert(t("alerts:quotaExceeded"))
    }
    return false
  }
}

export const readFromStorage = <T>(key: ClientStorageKeys): T | null => {
  try {
    const stored = sessionStorage.getItem(key)

    return stored ? JSON.parse(stored) : null
  } catch (e) {
    console.error(`Failed to read from storage for key ${key}:`, e)
    return null
  }
}

export const saveSelectedThread = (threadId: string): void => {
  writeToStorage(ClientStorageKeys.SELECTED_THREAD, threadId)
}

export const loadSelectedThread = (): string => {
  return readFromStorage<string>(ClientStorageKeys.SELECTED_THREAD) || ""
}
