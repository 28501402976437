import AdminSettings from "features/admin/AdminSettings"
import ListConversations from "features/listConversations/ListConversations"
import { Navigate, Route, Routes } from "react-router-dom"
import ConversationInsights from "features/insights/ConversationInsights"
import ConversationList from "features/labelConversations/ConversationList"
import PromptBuilder from "features/promptBuilder/PromptBuilder"
import Dashboard from "features/dashboard/Dashboard"
import { InternalRoute, PrivateRoute } from "./RouteWrappers"
import LoginPage from "components/LoginPage"
import { JSX } from "react"

const createRoute = (
  path: string,
  Component: React.ElementType,
  Wrapper?: React.ElementType,
): JSX.Element => (
  <Route
    path={path}
    element={Wrapper ? renderWithWrapper(Wrapper, Component) : <Component />}
  />
)

const renderWithWrapper = (
  Wrapper: React.ElementType,
  Component: React.ElementType,
): JSX.Element => (
  <Wrapper>
    <Component />
  </Wrapper>
)

export const routes = (
  <Routes>
    {createRoute("/view", ListConversations, PrivateRoute)}
    {createRoute("/", Dashboard, PrivateRoute)}
    {createRoute("/insights", ConversationInsights, PrivateRoute)}
    {createRoute("/prompt-builder", PromptBuilder, InternalRoute)}
    {createRoute("/admin", AdminSettings, InternalRoute)}
    {createRoute("/label", ConversationList, InternalRoute)}
    {createRoute("/login", LoginPage)}

    {/** Currently we have no 404 page so using this instead */}
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
)

export default routes
