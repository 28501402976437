import SentMessageToolbar from "./SentMessageToolbar"
import ReceivedMessageToolbar from "./ReceivedMessageToolbar"
import DefaultDataToolbar from "./DefaultDataToolbar"
import InsertedDataToolbar from "./InsertedDataToolbar"
import { Message } from "features/insights/types"

export interface MessageToolbarProps {
  message: Message
  messageIndex: number
  isDisabled: boolean
  isReport?: boolean
}

export const MessageToolbar: React.FC<MessageToolbarProps> = ({
  message,
  messageIndex,
  isDisabled,
}) => {
  switch (message.type) {
    case "sent":
      return (
        <SentMessageToolbar messageIndex={messageIndex} disabled={isDisabled} />
      )
    case "received":
      return (
        <ReceivedMessageToolbar
          message={message}
          messageIndex={messageIndex}
          isDisabled={isDisabled}
        />
      )
    case "report":
      return (
        <ReceivedMessageToolbar
          message={message}
          messageIndex={messageIndex}
          isDisabled={isDisabled}
          isReport={true}
        />
      )
    case "pre-processed-data":
      return <InsertedDataToolbar disabled={isDisabled} />
    case "raw-data":
      return <DefaultDataToolbar disabled={isDisabled} />
    default:
      return null
  }
}
