import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { setCurrentMessage, setPrompt } from "features/insights/insightsSlice"
import { useEffect } from "react"
import { encode, decode } from "utils/sanitize"
import { ChatTextAreaProps } from "features/insights/types"
import { InsightsUtilsRunners } from "features/insights/utils/llmUtils/llmUtilRunners"
import TurndownService from "turndown"

const ChatTextArea: React.FC<ChatTextAreaProps> = ({ textareaRef }) => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { runChatMessage } = InsightsUtilsRunners()
  const turndownService = new TurndownService()

  const adjustHeight = () => {
    if (textareaRef.current) {
      const newLineCount = textareaRef.current.value.split("\n").length

      if (newLineCount > 1) {
        textareaRef.current.style.height = "auto"
        textareaRef.current.style.height = `calc(${textareaRef.current.scrollHeight}px - 16px)`
      } else {
        textareaRef.current.style.height = ""
      }
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault()
      runChatMessage()
    }
  }

  const handleInput = () => {
    adjustHeight()
  }

  useEffect(() => {
    if (textareaRef.current) {
      adjustHeight()
    }
  }, [insightsState.selectedSummaryPrompt.prompt, textareaRef.current?.value])

  const handleCopy = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const text = textareaRef.current?.value ?? ""
    const cleanText = turndownService.turndown(text)

    event.clipboardData.setData("text/plain", cleanText)
    event.preventDefault()
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setCurrentMessage(encode(e.target.value)))
    dispatch(
      setPrompt({
        prompt: encode(e.target.value),
        promptType: "summary",
      }),
    )
  }

  return (
    <textarea
      ref={textareaRef}
      className="chat-input"
      value={decode(insightsState.currentChatMessage.prompt)}
      placeholder="Write a prompt"
      onChange={(e) => handleChange(e)}
      onCopy={handleCopy}
      onInput={handleInput}
      onKeyDown={handleKeyDown}
    />
  )
}

export default ChatTextArea
