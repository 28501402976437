import Modal, { Sheets, ModalBody } from "@ingka/modal"
import { SheetViewProps } from "features/insights/types"
import useSuppressSkapaErrors from "hooks/useSuppressSkapaErrors"

const SheetView: React.FC<SheetViewProps> = ({
  visible,
  handleClose,
  content,
  footer = null,
  details,
  rightSide = false,
}) => {
  useSuppressSkapaErrors()

  return (
    <div className="sheet-parent">
      <Modal
        visible={visible}
        handleCloseBtn={handleClose}
        escapable={true}
        focusLockProps={{ locked: false }}
      >
        <Sheets
          labelledById="sheetView"
          alignment={rightSide ? "right" : "left"} /* left | right */
          preserveAlignment={false} /* affects mobile view only */
          size="small" /* small | medium | large */
          footer={footer}
          header={<></>}
        >
          <ModalBody
            style={{
              display: "flex",
              flexDirection: "column",
              height: "calc(100vh - 3rem)",
            }}
          >
            <div className="sheet-title"></div>
            {content}
          </ModalBody>
        </Sheets>
        {details}
      </Modal>
    </div>
  )
}

export default SheetView
