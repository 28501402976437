import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import Progress from "components/Progress"
import { mapRange } from "utils/utils"
import { ConversationInsight } from "./types"
import { DEFAULT_NUMBER_OF_CONVERSATIONS } from "./constants"

interface InsightsTableProps {
  loading: boolean
  insights: Record<string, ConversationInsight>
}

const InsightsTable: React.FC<InsightsTableProps> = ({ loading, insights }) => {
  const nonEmptyInsightCount = Object.entries(insights).filter(
    ([, value]) =>
      typeof value.insights === "string" && value.insights.trim() !== "",
  ).length

  if (loading || Object.entries(insights).length !== 0) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          {loading ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              height="10.5rem"
            >
              <Progress
                durationMs={
                  Math.floor(Math.random() * 4000) +
                  mapRange(
                    DEFAULT_NUMBER_OF_CONVERSATIONS,
                    20,
                    200,
                    9000,
                    20000,
                  )
                }
              />
            </Grid>
          ) : (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Insight</TableCell>
                    <TableCell>Cost</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(insights).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell>{value.insights && value.insights}</TableCell>
                      <TableCell>
                        {value.cost && value.cost.toFixed(6)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {Object.entries(insights).length !== 0 ? (
                <p
                  style={{
                    color: "#777",
                    fontSize: ".875rem",
                    margin: "1.25rem 0 0 0",
                  }}
                >
                  {nonEmptyInsightCount} insights returned. Total cost of
                  running prompt:{" "}
                  {Object.values(insights)
                    .reduce((sum, { cost }) => sum + cost, 0)
                    .toFixed(6)}{" "}
                  USD
                </p>
              ) : (
                ""
              )}
            </TableContainer>
          )}
        </Grid>
      </Grid>
    )
  } else {
    return (
      <div className="no-messages-label-parent" style={{ margin: "0" }}>
        <div className="no-messages-title">
          No LLM processed data has been generated
        </div>
      </div>
    )
  }
}

export default InsightsTable
