import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { PromptType, SavedPrompt } from "../../types"
import {
  addSavedPrompt,
  deleteSavedPrompt,
  saveCurrentPrompt,
} from "../../insightsSlice"

export function usePromptCruds() {
  const dispatch = useAppDispatch()
  const savedInsightsPrompts = useAppSelector(
    (state: RootState) => state.insights.savedInsightsPrompts,
  )
  const savedSummaryPrompts = useAppSelector(
    (state: RootState) => state.insights.savedSummaryPrompts,
  )
  const user = useAppSelector((state: RootState) => state.auth.user)

  const onPromptDelete = (promptId: string, promptType: PromptType) => {
    if (!user) return
    dispatch(
      deleteSavedPrompt({
        collectionIdentifier: user.id,
        promptType,
        promptId,
      }),
    )
  }

  const onPromptSave = (
    selectedPrompt: SavedPrompt,
    promptType: PromptType,
  ) => {
    if (!user) return
    dispatch(
      saveCurrentPrompt({
        collectionIdentifier: user.id,
        prompt: selectedPrompt,
        promptType,
      }),
    )
  }

  const onAddPrompt = (prompt: SavedPrompt, promptType: PromptType) => {
    if (!user) return
    dispatch(
      addSavedPrompt({
        prompt,
        collectionIdentifier: user.id,
        promptType,
      }),
    )
  }

  return {
    savedInsightsPrompts,
    savedSummaryPrompts,
    onAddPrompt,
    onPromptSave,
    onPromptDelete,
  }
}
