/** Cursor */

import React from 'react';
import { Modal, Box, Typography, IconButton, CircularProgress, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ConversationModalProps {
  open: boolean;
  onClose: () => void;
  conversationId: string | null;
  conversation: any | null;
  loading: boolean;
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 800,
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflow: 'auto',
};

const ConversationModal: React.FC<ConversationModalProps> = ({
  open,
  onClose,
  conversationId,
  conversation,
  loading,
}) => {
  const renderConversation = (conversation: any) => {
    if (!conversation?.formattedConversations) return null;

    // Extract messages from the conversation XML
    const messages: Array<{ role: 'agent' | 'customer', content: string }> = [];
    
    // Remove the outer conversation tag
    const conversationContent = conversation.formattedConversations
      .replace(/<conversation[^>]*>|<\/conversation>/g, '')
      .trim();

    // Split into agent and customer messages
    const messageMatches = conversationContent.matchAll(/<(agent|customer)>(.*?)<\/\1>/gs);
    
    for (const match of messageMatches) {
      const [_, role, content] = match;
      if (content.trim()) {
        messages.push({
          role: role as 'agent' | 'customer',
          content: content.trim()
        });
      }
    }

    return messages.map((message, index) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          justifyContent: message.role === 'customer' ? 'flex-end' : 'flex-start',
          mb: 2,
          mx: 2,
        }}
      >
        <Paper
          elevation={1}
          sx={{
            p: 2,
            maxWidth: '70%',
            bgcolor: message.role === 'customer' ? '#e3f2fd' : '#f5f5f5',
            borderRadius: 2,
          }}
        >
          <Typography
            variant="caption"
            sx={{
              display: 'block',
              mb: 0.5,
              color: 'text.secondary',
              fontWeight: 'medium',
            }}
          >
            {message.role === 'customer' ? 'Customer' : 'Agent'}
          </Typography>
          <Typography variant="body2">{message.content}</Typography>
        </Paper>
      </Box>
    ));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3, px: 2 }}>
          <Typography variant="h6">
            Conversation {conversationId}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <CircularProgress />
          </Box>
        ) : conversation ? (
          <Box sx={{ 
            maxHeight: 'calc(80vh - 100px)', 
            overflow: 'auto',
            pr: 2
          }}>
            {renderConversation(conversation)}
          </Box>
        ) : (
          <Typography color="text.secondary" sx={{ px: 2 }}>
            No conversation data available
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default ConversationModal; 
