type PercentageValue = number | string
type MetricValue = number | string | Period

export const formatters = {
  percentage: {
    parse: (value: PercentageValue): number => {
      return typeof value === "number"
        ? value
        : parseFloat(value.replace("%", ""))
    },

    format: (value: PercentageValue): string => {
      return typeof value === "number"
        ? `${(value * 100).toFixed(1)}%`
        : (value as string)
    },
  },

  time: {
    toHours: (value: number): string => {
      return `${(value / 3600).toFixed(0)} hours`
    },

    toMinutes: (value: number): string => {
      return `${(value / 60).toFixed(1)} minutes`
    },
  },

  period: (value: Period): string => {
    return `${value.month} / ${value.year}`
  },

  label: (key: string): string => {
    // Finds abbreviations by checking for capitalised sequences in the given string. 
    // Capitalised sequences are kept intact, all other keys written in camel case are split accordingly
    const parts = key.split(/(?<=[A-Z])(?=[A-Z][a-z])|(?<=[^A-Z])(?=[A-Z])/)

    // Handle abbreviations
    const formattedParts = parts.map((part, index) => {
      // Keep abbreviations intact
      if (part.toUpperCase() === part && part.length > 1) {
        return part
      }

      if (index === 0) {
        return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
      }

      return part.toLowerCase()
    })

    return formattedParts.join(" ")
  },

  metrics: (key: string, value: MetricValue): string => {
    // Period
    if (typeof value === "object" && "month" in value && "year" in value) {
      return formatters.period(value)
    }

    // Strings
    if (typeof value === "string") {
      if (key === "rootCauseOwner") return value.toUpperCase()
      return value
    }

    // Numerical
    switch (key) {
      case "totalHandlingTime":
        return formatters.time.toHours(value)

      case "averageHandlingTime":
        return formatters.time.toMinutes(value)

      case "CSAT":
        return `${value * 100}% satisfied`

      case "resolutionRate":
      case "firstContactResolution":
      case "percentageConversations":
        return formatters.percentage.format(value)

      default:
        return value.toString()
    }
  },
}
