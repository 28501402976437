import Button from "@ingka/button"
import { auth } from "app/firebase"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { useTranslation } from "react-i18next"
import { logout } from "./authSlice"

const LogOutButton = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { loading, user } = useAppSelector((state: RootState) => state.auth)

  const handleLogOut = async () => {
    await auth.signOut()
    dispatch(logout())
  }

  return (
    user && (
      <Button
        loading={loading}
        type="secondary"
        disabled={loading}
        onClick={() => handleLogOut()}
        text={t("buttons:logOut")}
        small
      />
    )
  )
}

export default LogOutButton
