import Button from "@ingka/button"
import ArrowLeft from "@ingka/ssr-icon/paths/arrow-left"

interface ReturnButtonProps {
  action: () => void
  visible: boolean
}

const ReturnButton = (props: ReturnButtonProps) => {
  if (props.visible)
    return (
      <Button
        type="tertiary"
        iconOnly={true}
        ssrIcon={ArrowLeft}
        small={true}
        onClick={props.action}
        className="scaled-return-button"
      />
    )
}

export default ReturnButton
