import { useRef } from "react"
import ChatView from "./components/views/chat/ChatView"
import ChatViewOverlay from "./components/misc/ChatViewOverlay"
import { usePromptCruds } from "./utils/hooks/usePromptCruds"
import { useStoredPrompts } from "features/listConversations/hooks/useStoredPrompts"
import { useTranslation } from "react-i18next"
import Text from "@ingka/text"
import HistoryButton from "./components/buttons/HistoryButton"
import NewChatButton from "./components/buttons/NewChatButton"
import UserNavigation from "components/UserNavigation"
import LLMReportTool from "./components/input/LLMReportTool"
import { useStoredLLMTemplates } from "features/listConversations/hooks/useStoredLLMTemplates"
import PromptCrud from "./PromptCrud"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import Titlebar from "components/Titlebar"
import MessagePageHandler, { ToastParams } from "components/MessagePageHandler"
import PageAnimation from "components/PageAnimation"
import useInsightsHelperFunctions from "./utils/hooks/useInsightsHelperFunctions"
import {
  setAdvancedControlsSheetVisible,
  setChatHistoryVisibility,
  setMessageAttribute,
  setReportMode,
  toggleSelectedThreadToast,
} from "./insightsSlice"
import { useStoredLLMThreads } from "features/listConversations/hooks/useStoredLLMThreads"
import LoadingInstructionHandler from "components/LoadingInstructionHandler"
import ReturnButton from "../../components/ReturnButton"
import TertiaryButton from "components/TertiaryButton"
import { useFormattedRawData } from "./utils/hooks/useFormattedRawData"
import InsightsMessage from "./InsightsMessage"
import { MessageToolbar } from "./components/toolbars/MessageToolbar"
import MessageFeedback from "./components/views/chat/MessageFeedback"
import Hyperlink from "@ingka/hyperlink"
import { InsightsUtilsRunners } from "./utils/llmUtils/llmUtilRunners"
import { useInitiateNewThread } from "./utils/hooks/useInitiateNewThread"

const ConversationInsights = () => {
  const insightState = useAppSelector((state: RootState) => state.insights)
  const listConversationState = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  const selectedThreadToast = useAppSelector(
    (state: RootState) => state.insights.selectedThreadToast,
  )

  // Selectors
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // Refs
  const chatControlsRef = useRef<HTMLDivElement | null>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  // Custom hooks
  useStoredPrompts()
  useStoredLLMThreads()
  useStoredLLMTemplates()
  useInitiateNewThread()

  const { savedSummaryPrompts, onAddPrompt, onPromptSave, onPromptDelete } =
    usePromptCruds()

  const {
    inputDisabled,
    llmReport,
    llmReportIndex,
    prePromptedInsights,
    currentThreadUsesPrePromptedInsights,
    currentThreadUsingCustomPromptMode,
    currentThreadIsEmpty,
  } = useInsightsHelperFunctions()

  const { formattedRawDataLength } = useFormattedRawData().chat

  const summaryPromptCrud = (
    <PromptCrud
      prompts={savedSummaryPrompts}
      promptType="chat"
      onAddPrompt={onAddPrompt}
      onPromptSave={onPromptSave}
      onPromptDelete={onPromptDelete}
    />
  )

  const { runChatCreateWithPrompt } = InsightsUtilsRunners()

  // Conditionals
  const isPageLoading =
    listConversationState.loadingConversations ||
    insightState.llmReportLoading ||
    insightState.storedThreadLoading

  const reportSetupVisible = !currentThreadUsingCustomPromptMode && !llmReport
  const reportVisible = !currentThreadUsingCustomPromptMode && llmReport

  const leftPaneVisible = reportSetupVisible || reportVisible

  const showBackButton =
    currentThreadUsingCustomPromptMode && currentThreadIsEmpty()

  // Text formatting
  const numberFormatter = new Intl.NumberFormat("en-SE", {
    useGrouping: true,
    minimumFractionDigits: 0,
  })

  // Title bar labels
  const rawDataSummary = t("text:rawDataSummary", {
    rawDataLength: numberFormatter.format(formattedRawDataLength),
  })

  let prePromptedDataSummary: String | null = null

  if (prePromptedInsights)
    prePromptedDataSummary = t("text:prePromptedDataSummary", {
      prePromptedDataLength: numberFormatter.format(
        Object.keys(prePromptedInsights).length,
      ),
    })

  // Components
  const titlebarContent = (
    <>
      <div className="flex-row" style={{ gap: "1rem", width: "auto" }}>
        <ReturnButton
          visible={showBackButton}
          action={() => dispatch(setReportMode(true))}
        />
        <Text tagName="h1" headingSize="s">
          {t("buttons:insights")}
        </Text>

        <Text
          tagName="h1"
          headingSize="s"
          style={{
            color: "#999",
            fontWeight: "400",
            marginRight: "calc(-3rem / 16)",
          }}
        >
          {currentThreadUsesPrePromptedInsights
            ? prePromptedDataSummary
            : rawDataSummary}
        </Text>
      </div>

      <div className="flex-row title-bar">
        <div className="flex-row title-bar-section">
          <div>
            <HistoryButton />
            <TertiaryButton
              text={t("buttons:settings")}
              action={() => dispatch(setAdvancedControlsSheetVisible(true))}
              disabled={inputDisabled}
            />
          </div>
          <NewChatButton />
          <UserNavigation />
        </div>
      </div>
    </>
  )

  const llmReportFeedbackIndex = llmReportIndex ?? 0

  const handleUnsatisfiedReport = (index: number, event: React.MouseEvent) => {
    event.preventDefault()
    const continued = runChatCreateWithPrompt()

    if (continued)
      dispatch(
        setMessageAttribute({
          messageIndex: index,
          attribute: "feedback",
          value:
            "ACTION: User was unsatisfied with report and initiated a new one with the unstatisfied option.",
        }),
      )
  }

  const generatedReport = llmReport && (
    <>
      <div
        className="flex-row spaced"
        style={{ marginBottom: "-.5rem", alignItems: "flex-end" }}
      >
        <div>
          <Text
            className="large-title"
            headingSize="m"
            style={{ marginBottom: ".5rem", whiteSpace: "nowrap" }}
          >
            {t("headers:report")}
          </Text>
          <Text
            bodySize="s"
            style={{ marginTop: ".25rem", whiteSpace: "nowrap" }}
          >
            {t("labels:notSatisfiedReport")}{" "}
            <Hyperlink
              text={t("buttons:newReport")}
              colour="grey"
              newWindow={true}
              onClick={(e) =>
                handleUnsatisfiedReport(llmReportFeedbackIndex, e)
              }
            ></Hyperlink>
          </Text>
        </div>
        <div style={{ marginBottom: "-.375rem" }}>
          <MessageToolbar
            message={llmReport}
            messageIndex={llmReportFeedbackIndex}
            isDisabled={false}
          />
        </div>
      </div>
      <div className="report-parent">
        <InsightsMessage
          messageContent={llmReport.content}
          messageEditState={false}
          type="report"
          toolbar={<></>}
          showReport={true}
        />
      </div>
    </>
  )

  const leftPaneDisabledStyles: React.CSSProperties = {
    opacity: isPageLoading ? 0.2 : 1,
    pointerEvents: isPageLoading ? "none" : "auto",
    overflow: "auto",
  }

  const leftPane = (
    <div
      style={{
        overflow: "auto",
        flex: "1 1 48%",
        ...leftPaneDisabledStyles,
      }}
    >
      <div
        className="flex-column"
        style={{
          gap: "var(--page-bottom-padding)",
          overflow: "auto",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        {reportVisible ? generatedReport : <LLMReportTool />}
      </div>
    </div>
  )

  const insightsChat = (
    <>
      <ChatView
        chatControlsRef={chatControlsRef as React.RefObject<HTMLDivElement>}
      />
      <ChatViewOverlay
        chatControlsRef={chatControlsRef as React.RefObject<HTMLDivElement>}
        textareaRef={textareaRef as React.RefObject<HTMLTextAreaElement>}
        promptCrud={summaryPromptCrud}
      />
    </>
  )

  const rightPane = (
    <div style={{ position: "relative", flex: "1 1 52%" }}>
      <LoadingInstructionHandler
        showLoading={isPageLoading}
        loadingLabel={
          insightState.storedThreadLoading
            ? t("labels:resumingStoredThread")
            : listConversationState.loadingConversations
              ? t("labels:fetchingConversations")
              : t("labels:generatingReport")
        }
        showInstruction={reportSetupVisible}
        instructionLabel={t("labels:generateReport")}
      >
        {insightsChat}
      </LoadingInstructionHandler>
    </div>
  )

  const pageContent = (
    <div
      className="page-contents-narrow two-pane-row"
      style={{ overflow: "auto" }}
    >
      {leftPaneVisible && leftPane}
      {rightPane}
    </div>
  )

  const openPreviousThread = () => {
    dispatch(setChatHistoryVisibility(true))
    dispatch(toggleSelectedThreadToast())
  }

  const newThreadInitiatedToast: ToastParams = {
    text: t("notifications:newThreadInitiated"),
    isOpen: selectedThreadToast,
    actionText: t("buttons:viewThread"),
    action: openPreviousThread,
    closeAction: () => dispatch(toggleSelectedThreadToast()),
  }

  return (
    <>
      <MessageFeedback />

      <Titlebar>{titlebarContent}</Titlebar>
      <MessagePageHandler toastParams={newThreadInitiatedToast}>
        <PageAnimation>{pageContent}</PageAnimation>
      </MessagePageHandler>
    </>
  )
}

export default ConversationInsights
