import { ConversationData } from "../types"
import {
  fetchConversationStatus,
  setConversationStatus,
} from "../labelConversationsSlice"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { useCallback, useEffect } from "react"

export const useConversationStatus = () => {
  const { dataset, conversationStatuses, loadingConversationStatuses } =
    useAppSelector((state: RootState) => state.labelConversations)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!dataset) return

    dispatch(fetchConversationStatus(dataset.name))
  }, [dispatch, dataset])

  const getConversationData = useCallback(
    (md5ConversationId: string | undefined) => {
      if (loadingConversationStatuses || !md5ConversationId)
        return {
          entities: [],
          isCompleted: false,
          labelId: null,
        }

      const data = conversationStatuses[md5ConversationId]

      if (!data)
        return {
          entities: [],
          isCompleted: false,
          labelId: null,
        }

      return data
    },
    [conversationStatuses, loadingConversationStatuses],
  )

  const setConversationData = useCallback(
    async (md5ConversationId: string, conversationData: ConversationData) => {
      if (loadingConversationStatuses || !dataset) {
        console.log("Cannot set before loaded")
        return
      }

      // Create a new copy of the conversationStatuses object
      const updatedStatuses = {
        ...conversationStatuses,
        [md5ConversationId]: conversationData,
      }

      await dispatch(
        setConversationStatus({ dataset: dataset.name, data: updatedStatuses }),
      )
    },
    [dataset, conversationStatuses, loadingConversationStatuses, dispatch],
  )

  return { getConversationData, setConversationData }
}
