import { useAppDispatch } from "app/hooks"
import { market } from "app/types"
import { ENTITY_OPTIONS_DEFAULT_STATE } from "components/filterSection/constants"
import { EntityFilterOptions } from "components/filterSection/types"
import { loadEntitiesZipAsync } from "features/listConversations/listConversationSlice"
import { entityRow } from "features/listConversations/types"
import JSZip from "jszip"
import React from "react"
import { filterDuplicates } from "./utils/filterEntityOptions"

export const useEntityOptions = (
  filteredMarkets: market[],
  entitiesLoading: boolean,
) => {
  const dispatch = useAppDispatch()

  const [entityOptions, setEntityOptions] = React.useState<EntityFilterOptions>(
    ENTITY_OPTIONS_DEFAULT_STATE,
  )
  const [entityOptionsLoadingIntoMem, setEntityOptionsLoadingIntoMem] =
    React.useState<boolean>(false)

  const zipFileKey = `${filteredMarkets[0]}_entities`
  const zipFileKeyExists = localStorage.getItem(zipFileKey)

  // Fetching of the zip
  React.useEffect(() => {
    if (filteredMarkets[0] && !zipFileKeyExists) {
      console.log("entity filters dispatched", filteredMarkets[0])
      dispatch(
        loadEntitiesZipAsync({
          fileKey: zipFileKey,
          inputMarket: filteredMarkets[0],
        }),
      )
    }
  }, [filteredMarkets])

  // Loading the fetched zip from localStorage
  React.useEffect(() => {
    const loadLocalEntityOptions = async () => {
      try {
        const zipFileKey = `${filteredMarkets[0]}_entities`
        const zipFileData = localStorage.getItem(zipFileKey)

        if (zipFileData) {
          try {
            const zip = await JSZip.loadAsync(zipFileData, { base64: true })
            const entities = await zip.file(zipFileKey)?.async("string")

            if (!entities)
              throw new Error("Entity options undefined in zip file")

            try {
              const entityData: entityRow[] = JSON.parse(entities)

              const entityOptions: EntityFilterOptions = {
                touchpoints: filterDuplicates(
                  entityData
                    .filter((x) => x.touchpoint)
                    .map((x) => x.touchpoint as string),
                ),
                productNames: filterDuplicates(
                  entityData
                    .filter((x) => x.product_name)
                    .map((x) => x.product_name as string),
                ),
                productNumbers: filterDuplicates(
                  entityData
                    .filter((x) => x.item_no)
                    .map((x) => x.item_no as string),
                ),
                hfbs: filterDuplicates(
                  entityData.filter((x) => x.hfb).map((x) => x.hfb as string),
                ),
                service_providers: filterDuplicates(
                  entityData
                    .filter((x) => x.service_provider_name)
                    .map((x) => x.service_provider_name as string),
                ),
                services: filterDuplicates(
                  entityData
                    .filter((x) => x.service_entity_name)
                    .map((x) => x.service_entity_name as string),
                ),
                unit_codes: filterDuplicates(
                  entityData
                    .filter((x) => x.unit_code)
                    .map((x) => x.unit_code as string),
                ),
                unit_names: filterDuplicates(
                  entityData
                    .filter((x) => x.unit_name)
                    .map((x) => x.unit_name as string),
                ),
              }
              setEntityOptions(entityOptions)
            } catch (jsonError) {
              console.error("Error parsing entity options JSON:", jsonError)
              localStorage.removeItem(zipFileKey)
            }
          } catch (parseError) {
            console.error("Error parsing entity options zip:", parseError)
            localStorage.removeItem(zipFileKey)
          }
        }
      } catch (error) {
        console.error("Error reading entity options from localStorage:", error)
      }
    }

    if (filteredMarkets.length === 1 && !entitiesLoading && zipFileKeyExists) {
      setEntityOptions(ENTITY_OPTIONS_DEFAULT_STATE)
      setEntityOptionsLoadingIntoMem(true)
      loadLocalEntityOptions()
      setEntityOptionsLoadingIntoMem(false)
    }
  }, [filteredMarkets, entitiesLoading])

  return { entityOptions, entityOptionsLoadingIntoMem }
}
