export interface IMessage {
  transcriptOrder: number
  userType: string
  language: string
  timestamp: string
  text: string | null
  textRaw: string | null
  isAutomated: boolean
  isAfterBillie: boolean
  isCustomer: boolean
}

export interface IConversation {
  id: string
  country: string
  channel: string
  messages: IMessage[]
  startTime: string
  endTime: string | null
  manualLabelId: number
  autoLabelId: number
  llmLabelId: number
  csatScore: number | null
  csatFeedback: Feedback
  entities: IGroupedEntity[]
  customerStatement: string
  review?: IConversationReviewMin
  flagNames?: string[]
}

export interface IConversationReviewMin {
  comment: string | null
  correctLabelId: number
}

interface Feedback {
  feedback: string
  translated: string
}

export interface IConversationReview {
  user_id: string
  published: string | null
  correct_label_id: number | null
  comment: string | null
  country: string
  conversation_id: string
  label_id: number | null
  model_id: string | null
}

export interface IGroupedEntity {
  entityName: string
  values: string[]
}

export type KeyValueType = {
  key: string
  value: string
}

export interface IConversationResponse {
  conversations: IConversation[]
  totalConversationAmount: number
}

export type market =
  | "AT"
  | "AU"
  | "BE"
  | "CA"
  | "CH"
  | "CN"
  | "CZ"
  | "DE"
  | "DK"
  | "ES"
  | "FI"
  | "FR"
  | "GB"
  | "HR"
  | "HU"
  | "IE"
  | "IN"
  | "IT"
  | "JP"
  | "KR"
  | "NL"
  | "NO"
  | "PL"
  | "PT"
  | "RO"
  | "RS"
  | "SE"
  | "SI"
  | "SK"
  | "US"

export const validMarkets: market[] = [
  "AT",
  "AU",
  "BE",
  "CA",
  "CH",
  "CN",
  "CZ",
  "DE",
  "DK",
  "ES",
  "FI",
  "FR",
  "GB",
  "HR",
  "HU",
  "IE",
  "IN",
  "IT",
  "JP",
  "KR",
  "NL",
  "NO",
  "PL",
  "PT",
  "RO",
  "RS",
  "SE",
  "SI",
  "SK",
  "US",
]

export function isMarket(inputMarket: string): inputMarket is market {
  return validMarkets.includes(inputMarket as market)
}

export type Channel = "Chat" | "twitterdirect" | "facebooksession" | "Voice"

export const validChannels: Channel[] = [
  "Chat",
  "Voice",
  "twitterdirect",
  "facebooksession",
]

export enum ClientStorageKeys {
  SELECTED_THREAD = "mosaik_selected_thread",
  DATA_SOURCES = "mosaik_data_sources",
  CONVERSATIONS_STORAGE_KEY = "mosaik_conversations",
  TOTAL_CONVERSATIONS_STORAGE_KEY = "mosaik_total_conversations",
  FILTERS_STORAGE_KEY = "mosaik_filters",
}

export enum FirestoreStorageKeys {
  CHAT_THREADS = "chatThreads",
  CHAT_THREADS_USER_COLLECTION = "threads",
}

export interface ReportData {
  testType: string
  country: string
  filter: string
  formattedConversations: string
  evaluationResults: string
  metadata: {
    country: string
    predictionLabelId: string
    channel: string | null
    dateRange: {
      from: string | null
      to: string | null
    }
  }
}

export interface UploadDataStatistics {
  statistics: any
  market: string
  docId: string
}

export type ToastAction = {
  label: string
  onClick: () => void
}

export type Toast = {
  name: string
  isVisible: boolean
  message: string
  action?: ToastAction
}
export interface ILabelMessageAnon {
  transcript_order: number
  md5_user_id: string
  user_type: string
  language: string
  timestamp: {
    value: string
  }
  text: string | null
  text_raw: string | null
  is_automated: boolean
  is_after_billie: boolean
  is_customer: boolean
}

export interface ILabelMessagePii {
  transcript_order: number
  user_id: string
  user_type: string
  username: string
  language: string
  timestamps: {
    value: string
  }
  text: string | null
  text_raw: string | null
  is_automated: boolean
  is_after_billie: boolean
  is_customer: boolean
  message_id: string
}

export interface ILabelConversation {
  md5_conversation_id: string
  label_id: string
  conversation_id: string
  has_pii_data: boolean
  has_anon_data: boolean
  start_time: {
    value: string
  }
  country: string
  channel: string
  anonymizer: string | null
  translator: string | null
  anon_messages: ILabelMessageAnon[]
  pii_messages: ILabelMessagePii[]
  flags: string[]
}

export interface IFetchLabelConversationsRequest {
  flagName: string
  limit: number
  country: string
  channel: Channel
}

export interface IFetchLabelConversationsResponse {
  conversations: ILabelConversation[]
  numberOfConversations: number
}

export interface Label {
  customer_journey_name: string
  customer_journey_id: number
  main_label_name: string
  main_label_id: number
  label_name: string
  label_id: number
}

export interface IFetchReportRequest {
  country: string
  year: string
  month: string
  label_id: string
  is_csat: boolean
}

export interface IFetchReportResponse {
  evaluationResults: string
  formattedConversations: string | null
  filter: string
  metadata: {
    channel: string
    country: string
  }
}

export interface SetMarketSettingRequest {
  market: string
  document: string
  field: string
  value: any
}
