import { getAnalytics } from "firebase/analytics"
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import {
  getDocs as getFirestoreDocs,
  deleteDoc as deleteFirestoreDoc,
  getDoc as getFirestoreDoc,
  setDoc as setFirestoreDoc,
  addDoc as addFirestoreDoc,
  DocumentReference,
  CollectionReference,
  getFirestore,
  doc as firestoreDoc,
  collection as firestoreCollection,
  DocumentData,
  getDocsFromServer as getFirestoreDocsFromServer,
  Query,
  updateDoc as updateFirestoreDoc,
  WithFieldValue,
  connectFirestoreEmulator,
} from "firebase/firestore"
import { connectFunctionsEmulator, getFunctions } from "firebase/functions"
import { purify } from "utils/sanitize"

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const analytics = getAnalytics(app)
export const functions = getFunctions(app, import.meta.env.VITE_FIREBASE_REGION)
export const firestore = getFirestore()

if (
  import.meta.env.VITE_EMULATOR === "true" &&
  import.meta.env.VITE_FUNCTIONS_ONLY === "true"
) {
  console.log("Emulated (functions only)")
  connectFunctionsEmulator(functions, "127.0.0.1", 5001)
} else if (import.meta.env.VITE_EMULATOR === "true") {
  console.log("Emulated")
  connectFunctionsEmulator(functions, "127.0.0.1", 5001)
  connectFirestoreEmulator(firestore, "127.0.0.1", 8080)
}

export interface DocWrap {
  data: Record<string, any> | undefined
  id: string
}

// Wrapper function for getting a document from Firestore
export async function getDoc<T>(
  docRef: DocumentReference<T>,
): Promise<DocWrap | undefined> {
  const docSnapshot = await getFirestoreDoc(docRef)
  if (docSnapshot.exists()) {
    const docWrap: DocWrap = {
      data: purify(docSnapshot.data()),
      id: docSnapshot.id,
    }
    return docWrap
  }
  return undefined
}

// Wrapper function for checking if a doc exists
export async function docExists<T>(
  docRef: DocumentReference<T>,
): Promise<boolean> {
  const docSnapshot = await getFirestoreDoc(docRef)
  return docSnapshot.exists()
}

// Wrapper function for setting a document in Firestore
export async function setDoc<T>(
  docRef: DocumentReference<T>,
  data: T,
): Promise<void> {
  const purifiedData = purify(data) // Purify the data before setting
  try {
    await setFirestoreDoc(docRef, purifiedData)
  } catch (error) {
    console.log(error)
  }
}

// Wrapper function for getting multiple documents from Firestore based on a query
export async function getDocs<T>(query: Query<T>): Promise<DocWrap[]> {
  const querySnapshot = await getFirestoreDocs(query)
  const documents: DocWrap[] = []
  if (querySnapshot.empty) {
    return []
  }
  querySnapshot.forEach((doc) => {
    const docWrap: DocWrap = {
      data: purify(doc.data()),
      id: doc.id,
    }
    documents.push(docWrap)
  })
  return documents
}

export async function addDoc<AppModelType, DbModelType extends DocumentData>(
  reference: CollectionReference<AppModelType, DbModelType>,
  data: WithFieldValue<AppModelType>,
): Promise<void | DocumentReference<AppModelType, DbModelType>> {
  return await addFirestoreDoc(reference, purify(data)).catch((e) =>
    console.log(e),
  )
}

// Wrapper function for getting multiple documents from Firestore based on a query
export async function getDocsFromServer<T>(
  query: Query<T>,
): Promise<DocWrap[]> {
  const querySnapshot = await getFirestoreDocsFromServer(query)
  const documents: DocWrap[] = []
  if (querySnapshot.empty) {
    return []
  }
  querySnapshot.forEach((doc) => {
    const docWrap: DocWrap = {
      data: purify(doc.data()),
      id: doc.id,
    }
    documents.push(docWrap)
  })
  return documents
}

// Wrapper function for deleting a document from Firestore
export async function deleteDoc(docRef: DocumentReference): Promise<void> {
  await deleteFirestoreDoc(docRef)
}

// Wrapper function for updating a document from Firestore
export async function updateDoc<T>(
  docRef: DocumentReference<T>,
  data: T,
): Promise<void> {
  const purifiedData = purify(data) // Purify the data before setting
  await updateFirestoreDoc(docRef, purifiedData)
}

export function doc(
  path: string,
  ...pathSegments: string[]
): DocumentReference<DocumentData> {
  const documentRef: DocumentReference<DocumentData> = firestoreDoc(
    firestore,
    path,
    ...pathSegments,
  )
  return documentRef
}

export function collection(
  path: string,
  ...pathSegments: string[]
): CollectionReference<DocumentData, DocumentData> {
  const collectionRef: CollectionReference<DocumentData, DocumentData> =
    firestoreCollection(firestore, path, ...pathSegments)
  return collectionRef
}
