import { Label, ILabelConversation, Channel } from "../../app/types"

export interface LabelConversationsState {
  emptyConversationFilter: boolean
  loadingConversations: boolean
  loadingLabels: boolean
  errorMessage?: string
  labelError: string | null
  conversations?: ILabelConversation[]
  labels: Label[]
  dataset: Dataset | undefined
  savingDataset: boolean
  deletingDataset: boolean
  datasetLoaded: boolean
  datasets: string[]
  datasetsLoaded: boolean
  conversationStatuses: ConversationDatas
  loadingConversationStatuses: boolean
  savingConversationData: boolean
  entityReloadRequired: boolean
  loadingEntities: boolean
  entities?: Entities
}

export interface Store {
  cmp_code: string
  cmp_name: string
  city: string
}

export interface Entities {
  cmp: Store[]
  productNumbers: string[]
  productNames: string[]
  services: string[]
  prodcuts: string[]
}

export interface Dataset {
  name: string
  flagName: string
  limit: number
  country: string
  channel: Channel
}

type DatasetKeys = keyof Dataset

// Create a constant array of valid keys from the Dataset interface
export const DatasetKeySample: DatasetKeys[] = Object.keys(
  {} as Dataset,
) as DatasetKeys[]

export type EntityType =
  | "store"
  | "case_number"
  | "order_number"
  | "product"
  | "anonymization_failure"
  | "service"

export interface Entity {
  id: string
  ikeaValue: string
  type: EntityType
  value: string
  transcriptOrder: number
}

export interface ConversationData {
  entities: Entity[]
  labelId: number | null
  isCompleted: boolean
}

export interface ConversationDatas {
  [key: string]: ConversationData
}
