import {
  ChatThread,
  FetchStoredLLMThreadsProps,
  FetchStoredLLMThreadsReq,
  FetchStoredLLMThreadsRes,
  LLMReportTemplate,
  ResumeStoredLLMThreadReq,
  ResumeStoredLLMThreadRes,
} from "features/insights/types"
import { getDocs } from "firebase/firestore"
import { fetchChatThreadsFS } from "./readChatThreadsFS"
import { ChatThreadFilter } from "./types"
import { getLLMReportTemplatesCollection } from "utils/firestorePaths"

export async function fetchReportTemplates(): Promise<LLMReportTemplate[]> {
  const snap = await getDocs(getLLMReportTemplatesCollection)

  return snap.docs.map((doc) => ({
    id: doc.id,
    ...structuredClone(doc.data()),
  })) as LLMReportTemplate[]
}

export async function fetchStoredThreads(
  req: FetchStoredLLMThreadsReq,
): Promise<FetchStoredLLMThreadsRes> {
  const filter: ChatThreadFilter = {
    sorting: { field: "lastInteraction", direction: "desc" },
  }

  const queryParams: FetchStoredLLMThreadsProps = {
    ...req,
    filter,
    quantity: 10,
  }

  const querySnapshot = await fetchChatThreadsFS(queryParams)
  const threads = querySnapshot.docs.map((doc) =>
    structuredClone(doc.data()),
  ) as ChatThread[]

  const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1]
  const hasMore = querySnapshot.docs.length === queryParams.quantity

  return {
    threads,
    lastDoc: lastDoc && hasMore ? {
      id: lastDoc.id,
      path: lastDoc.ref.path
    } : null
  }
}

export async function resumeStoredThread(
  req: ResumeStoredLLMThreadReq,
): Promise<ResumeStoredLLMThreadRes> {
  const filter: ChatThreadFilter = {
    filters: req.currentFilters,
    uuid: req.selectedThread,
    sorting: { field: "lastInteraction", direction: "desc" },
  }

  const queryParams: FetchStoredLLMThreadsProps = {
    userId: req.userId,
    filter: filter,
    quantity: 1,
  }

  try {
    const match = await fetchChatThreadsFS(queryParams)
    const thread = match.docs[0]?.data()

    if (!thread) throw new Error("No matching remote thread found.")
    return thread as ResumeStoredLLMThreadRes
  } catch (error) {
    throw error
  }
}
