import Button from "@ingka/button"

interface TertiaryButtonProps {
  action: () => void
  text: string
  disabled?: boolean
}

const TertiaryButton = ({
  action,
  text,
  disabled = false,
}: TertiaryButtonProps) => {
  return (
    <Button type="tertiary" onClick={action} disabled={disabled}>
      {text}
    </Button>
  )
}

export default TertiaryButton
