import { useAppDispatch } from "app/hooks"
import Pill from "@ingka/pill"
import { toggleFilterMenu } from "./listConversationSlice"
import { KeyValueType } from "app/types"
import {
  deterministicallyOrdered,
  formatFilterKey,
  formatFilterValue,
} from "utils/filterUtils"
import { JSX } from "react"

const Filters = ({
  filters,
  selected = true,
  action = undefined,
}: {
  filters: KeyValueType[]
  selected?: boolean
  action?: JSX.Element
}) => {
  const dispatch = useAppDispatch()

  const actionButton = action ? (
    <div
      style={{ width: "100%", marginTop: ".375rem", marginBottom: "-.25rem" }}
    >
      {action}
    </div>
  ) : (
    ""
  )

  return (
    <>
      {deterministicallyOrdered(filters).map(({ key, value }, index) => {
        return (
          <div key={index} className={`${selected ? "" : "preview-filter"}`}>
            <Pill
              label={formatFilterKey(key) + formatFilterValue(value)}
              size="xsmall"
              onClick={() => dispatch(toggleFilterMenu())}
            />
          </div>
        )
      })}
      {actionButton}
    </>
  )
}

export default Filters
