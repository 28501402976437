import Button from "@ingka/button"
import InlineMessage from "@ingka/inline-message"
import informationCircle from "@ingka/ssr-icon/paths/information-circle"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import { useTranslation } from "react-i18next"
import Checkbox from "@ingka/checkbox"
import Text from "@ingka/text"
import { DEFAULT_DATA_SOURCES } from "stores/constants"
import { useDataSources } from "stores/hooks"

const DataSourcesControls = () => {
  const { inputDisabled } = useInsightsHelperFunctions()
  const { t } = useTranslation()
  const { savedSelection, pending, setPending, apply, reset } = useDataSources()

  const isDefault =
    JSON.stringify(pending) === JSON.stringify(DEFAULT_DATA_SOURCES)

  const hasChanges = JSON.stringify(pending) !== JSON.stringify(savedSelection)

  return (
    <div className="flex-column" style={{ gap: "2rem" }}>
      <InlineMessage
        title={t("warnings:explanation")}
        body={t("warnings:dataSources")}
        variant="informative"
        ssrIcon={informationCircle}
      />

      <div className="modal-section-parent">
        <div className="flex-row spaced">
          <h4>{t("tabs:dataSources")}</h4>
          <div className="flex-row">
            <Button
              type="secondary"
              disabled={inputDisabled || isDefault}
              onClick={reset}
              small
            >
              {t("buttons:resetFormatting")}
            </Button>
            <Button
              type="emphasised"
              disabled={inputDisabled || !hasChanges || isDefault}
              onClick={apply}
              small
            >
              {t("buttons:apply")}
            </Button>
          </div>
        </div>

        <div className="flex-column" style={{ gap: "1.5rem" }}>
          <div className="flex-column">
            <Text bodySize="s">{t("subheadings:generalOptions")}</Text>
            <Checkbox
              label={t("labels:initiationDate")}
              id="dateOfInitiation"
              value="dateOfInitiation"
              checked={pending.date}
              onChange={(e) => setPending("date", e.target.checked)}
            />
            <Checkbox
              label={t("labels:channel")}
              id="channel"
              value="channel"
              checked={pending.channel}
              onChange={(e) => setPending("channel", e.target.checked)}
            />
          </div>

          <div className="flex-column">
            <Text bodySize="s">{t("subheadings:csatOptions")}</Text>
            <Checkbox
              label={t("labels:csatScore")}
              id="csatScore"
              value="csatScore"
              checked={pending.csatScore}
              onChange={(e) => setPending("csatScore", e.target.checked)}
            />
            <Checkbox
              label={t("labels:csatComment")}
              id="csatComment"
              value="csatComment"
              checked={pending.csatComment}
              onChange={(e) => setPending("csatComment", e.target.checked)}
            />
          </div>

          <div className="flex-column">
            <Text bodySize="s">{t("subheadings:entityOptions")}</Text>
            <Checkbox
              label={t("labels:entities")}
              id="entities"
              value="entities"
              checked={pending.entities}
              onChange={(e) => setPending("entities", e.target.checked)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataSourcesControls
