import React, { JSX } from "react"
import LoadingView from "components/Loading"
import { Navigate, useLocation } from "react-router-dom"
import { useAppSelector } from "../hooks"
import { RootState } from "../store"
import Navigation from "components/Navigation"
import { useFetchLabels } from "features/listConversations/hooks/useFetchLabels"
import { useSheetViews } from "features/insights/utils/hooks/useSheetViews"
import { useFetchConversations } from "features/labelConversations/hooks/useFetchConversations"
import { useFetchFlags } from "features/flag/hooks/useFetchFlags"

export type RouteType = "private" | "admin" | "internal"

export interface RouteProps {
  children: JSX.Element
}

const MOST_WIDELY_ACCESSIBLE_PAGE = "/view"

const RouteWrapperBase: React.FC<RouteProps & { type: RouteType }> = ({
  type,
  children,
}) => {
  const location = useLocation()
  const authState = useAppSelector((state: RootState) => state.auth)

  const { advancedViewSheet, historyViewSheet, marketSettingsSheet } =
    useSheetViews()

  /* Global hooks */
  useFetchLabels()
  useFetchConversations()
  useFetchFlags()

  /** Conditions */
  const isLoading =
    authState.loading ||
    (type === "admin" && authState.checkingAdminPermissions) ||
    (type === "internal" && authState.checkingInternalPermissions)

  const isSignedOut = authState.user === null

  const isAdminRequiredButNotAdmin =
    type === "admin" && authState.isAdmin === false

  const isInternalRequiredButNotInternal =
    type === "internal" && authState.isInternal === false

  const isNotPermissed =
    isAdminRequiredButNotAdmin || isInternalRequiredButNotInternal

  /** Route wrapper */
  const routeWrapper = (
    <div className="page">
      <div className="page-contents">
        <Navigation />

        {advancedViewSheet}
        {historyViewSheet}
        {marketSettingsSheet}

        <main>{children}</main>
      </div>
    </div>
  )

  return isSignedOut ? (
    <Navigate to="/login" state={{ from: location }} />
  ) : isLoading ? (
    <LoadingView />
  ) : isNotPermissed ? (
    <Navigate to={MOST_WIDELY_ACCESSIBLE_PAGE} state={{ from: location }} />
  ) : (
    routeWrapper
  )
}

export default RouteWrapperBase
