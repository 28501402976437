import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { useEffect } from "react"
import {
  fetchDatasets,
  setDatasetsLoaded,
  saveDataset,
  deleteDataset,
} from "../labelConversationsSlice"
import { Dataset } from "../types"

export const useFetchDatasets = () => {
  const dispatch = useAppDispatch()
  const { datasets, datasetsLoaded } = useAppSelector(
    (state: RootState) => state.labelConversations,
  )

  useEffect(() => {
    if (!datasetsLoaded) dispatch(fetchDatasets())
  }, [dispatch, datasetsLoaded])

  const trySaveDataset = async (dataset: Dataset) => {
    const updated = await dispatch(saveDataset(dataset))

    if (updated.meta.requestStatus === "fulfilled" && updated.payload) {
      dispatch(setDatasetsLoaded(false))
    }
  }

  const removeDataset = async (dataset: string) => {
    dispatch(deleteDataset(dataset))
  }

  return { datasets, trySaveDataset, removeDataset }
}
