import { NotificationToastsState, StoreState } from "stores/types"

export const createNotificationToastsSlice = (
  set: any,
): NotificationToastsState => ({
  toasts: {},

  showToast: (id) =>
    set((state: StoreState) => ({
      toasts: {
        ...state.notificationToasts.toasts,
        [id]: {
          ...state.notificationToasts.toasts[id],
          isVisible: true,
        },
      },
    })),

  hideToast: (id) =>
    set((state: StoreState) => ({
      toasts: {
        ...state.notificationToasts.toasts,
        [id]: {
          ...state.notificationToasts.toasts[id],
          isVisible: false,
        },
      },
    })),

  defineToast: (id, message, action) =>
    set((state: StoreState) => ({
      toasts: {
        ...state.notificationToasts.toasts,
        [id]: {
          id,
          message,
          action,
          isVisible: false,
        },
      },
    })),
})
