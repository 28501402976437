import Button from "@ingka/button"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { setChatHistoryVisibility } from "features/insights/insightsSlice"

const HistoryButton = () => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)

  return (
    <Button
      type="tertiary"
      onClick={() =>
        dispatch(setChatHistoryVisibility(!insightsState.chatHistoryVisible))
      }
    >
      History
    </Button>
  )
}

export default HistoryButton
