import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { MarketSettings } from "./types"
import { collection, DocWrap, getDocs } from "app/firebase"
import { getPathMarketSettings } from "utils/firestorePaths"
import { applySettingsChanges, getSettingsChanges } from "./marketSettingsUtils"
import { RootState } from "app/store"
import { market } from "app/types"

interface MarketSettingsState {
  settings: MarketSettings
  market?: market
  fetching: boolean
  applying: boolean
  visible: boolean
}

export const getMarketSettings = createAsyncThunk(
  "getMarketSettings",
  ({ market }: { market: market }) =>
    Promise.resolve(getDocs(collection(getPathMarketSettings(market))))
      .then((snapshot) =>
        snapshot
          ? Object.fromEntries(
              snapshot.map((doc: DocWrap) => [doc.id, doc.data]),
            )
          : Promise.reject(
              new Error(
                "Error in function getMarketSettings: failed to get documents",
              ),
            ),
      )
      .then(structuredClone)
      .catch((error) => {
        console.error("Error in getMarketSettings:", error)
        throw error
      }),
)

export const applyMarketSettings = createAsyncThunk(
  "applyMarketSettings",
  (
    { market, newSettings }: { market: market; newSettings: MarketSettings },
    { getState, dispatch },
  ) =>
    Promise.resolve(
      getSettingsChanges(
        newSettings,
        (getState() as RootState).marketSettings.settings,
      ),
    ).then((changes) =>
      changes.length > 0
        ? applySettingsChanges(market, changes)
            .then(() => dispatch(getMarketSettings({ market })))
            .then(() => newSettings)
        : Promise.resolve(undefined),
    ),
)

const initialState: MarketSettingsState = {
  settings: {},
  fetching: false,
  applying: false,
  visible: false,
}

const marketSettingsSlice = createSlice({
  name: "marketSettings",
  initialState,
  reducers: {
    setMarketSettingsMarket: (state, action: PayloadAction<market>) => {
      state.market = action.payload
    },
    toggleMarketSettingsVisiblity: (state) => {
      state.visible = !state.visible
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMarketSettings.pending, (state) => {
        state.fetching = true
      })
      .addCase(getMarketSettings.fulfilled, (state, action) => {
        state.settings = action.payload as MarketSettings
        state.fetching = false
      })
      .addCase(getMarketSettings.rejected, (state) => {
        state.fetching = false
      })
      .addCase(applyMarketSettings.pending, (state) => {
        state.applying = true
      })
      .addCase(applyMarketSettings.fulfilled, (state, action) => {
        state.applying = false
        if (action.payload) {
          state.settings = action.payload
        }
      })
      .addCase(applyMarketSettings.rejected, (state) => {
        state.applying = false
        state.settings = {}
      })
  },
})

export const { setMarketSettingsMarket, toggleMarketSettingsVisiblity } =
  marketSettingsSlice.actions

export default marketSettingsSlice.reducer
