import {
  readFromStorage,
  writeToStorage,
} from "features/insights/utils/storageUtils"
import { DataSourcesState, StoreState } from "stores/types"
import { DEFAULT_DATA_SOURCES } from "stores/constants"
import { ClientStorageKeys } from "app/types"

export const createDataSourcesSlice = (set: any): DataSourcesState => ({
  savedSelection: initialState,
  pending: initialState,
  setPending: (key, value) =>
    set((state: StoreState) => {
      state.dataSources.pending[key] = value
    }),
  reset: () =>
    set((state: StoreState) => {
      Object.assign(state.dataSources.savedSelection, DEFAULT_DATA_SOURCES)
      Object.assign(state.dataSources.pending, DEFAULT_DATA_SOURCES)
      writeToStorage(
        ClientStorageKeys.DATA_SOURCES,
        state.dataSources.savedSelection,
      )
    }),
  apply: () =>
    set((state: StoreState) => {
      Object.assign(state.dataSources.savedSelection, state.dataSources.pending)
      writeToStorage(
        ClientStorageKeys.DATA_SOURCES,
        state.dataSources.savedSelection,
      )
    }),
})

const storedValues = readFromStorage(ClientStorageKeys.DATA_SOURCES) || {}

const initialState = {
  ...DEFAULT_DATA_SOURCES,
  ...storedValues,
}
