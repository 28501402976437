import Button from "@ingka/button"
import { useAppDispatch } from "app/hooks"
import { setAdvancedControlsSheetVisible } from "features/insights/insightsSlice"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import { useTranslation } from "react-i18next"

const ShowAdvancedButton = ({ small = true }: { small?: boolean }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { inputDisabled } = useInsightsHelperFunctions()

  return (
    <Button
      onClick={() => dispatch(setAdvancedControlsSheetVisible(true))}
      type="secondary"
      disabled={inputDisabled}
      small={small}
    >
      {t("buttons:settings")}
    </Button>
  )
}

export default ShowAdvancedButton
