import { KeyValueType } from "app/types"
import { purify } from "./sanitize"
import { FilterMap } from "components/filterSection/types"

export const deterministicallyOrdered = (
  filters: KeyValueType[],
): KeyValueType[] => {
  const filterMap = new Map(filters.map((item) => [item.key, item.value]))

  return Array.from(filterMap.entries())
    .map(([key, value]) => ({ key, value }))
    .sort((a, b) => a.key.localeCompare(b.key))
}

export const urlToFilters = (searchParams: URLSearchParams): KeyValueType[] => {
  const searchFilters: KeyValueType[] = []
  for (const [param, value] of searchParams.entries()) {
    if (value) {
      searchFilters.push({ key: param, value: purify(value) })
    }
  }

  return deterministicallyOrdered(searchFilters)
}

export const filtersToUrl = (filters: KeyValueType[]): URLSearchParams => {
  const searchParams = new URLSearchParams()
  deterministicallyOrdered(filters).forEach(({ key, value }) => {
    searchParams.append(key, value)
  })

  return searchParams
}

export const filterMapToKv = (menuFilters: FilterMap) => {
  let kvFilters: KeyValueType[] = []
  for (const [key, value] of Object.entries(menuFilters)) {
    if (value && value.length > 0) {
      kvFilters.push({ key, value: value.toString() })
    }
  }

  return kvFilters
}

export const requestFormatHandler = (
  filters: KeyValueType[],
): Record<string, any> => {
  const payload: Record<string, any> = {}
  payload["filters"] = { filters: [], logic: "AND" }

  for (let i = 0; i < filters.length; i++) {
    const item = filters[i]

    switch (item.key) {
      case "from_start_time":
      case "to_start_time":
      case "limit":
      case "page":
        payload[item.key] = item.value
        break
      case "country":
        payload["countries"] = [item.value]
        break
      case "cust_sat":
      case "manual_tag_label_id":
      case "prediction_label_id":
        payload["filters"]["filters"].push({
          key: item.key,
          value: item.value.split(",").map(Number),
          relation: "IN",
        })
        break
      case "channel":
        payload["filters"]["filters"].push({
          key: item.key,
          value: item.value.split(","),
          relation: "IN",
        })
        break
      case "hfbs":
      case "services":
      case "service_providers":
      case "unit_codes":
      case "productNames":
      case "unit_names":
        payload["filters"]["filters"].push({
          key: item.key,
          value: item.value.split(","),
        })
        break
      default:
        payload["filters"]["filters"].push(item)
        break
    }
  }

  return payload
}

const UNDERSCORE = "_"
const SPACE_CHAR = " "

export const formatFilterKey = (key: string): string => {
  if (key === "channel") {
    return ""
  }

  let capitalizedKey = key
    .split(UNDERSCORE)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(SPACE_CHAR)

  if (key === "from_start_time" || key === "to_start_time") {
    capitalizedKey = capitalizedKey.split(SPACE_CHAR)[0]
  }

  return capitalizedKey + ": "
}

export const formatFilterValue = (value: string): string => {
  return value.replace(/,/g, ", ")
}
