import Button from "@ingka/button"
import { InsightsUtilsRunners } from "features/insights/utils/llmUtils/llmUtilRunners"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import { useTranslation } from "react-i18next"

const NewChatButton = () => {
  const { runChatCreateWithPrompt } = InsightsUtilsRunners()
  const { inputDisabled } = useInsightsHelperFunctions()
  const { t } = useTranslation()

  return (
    <Button
      type="emphasised"
      disabled={inputDisabled}
      onClick={() => runChatCreateWithPrompt()}
    >
      {t("buttons:newChat")}
    </Button>
  )
}

export default NewChatButton
