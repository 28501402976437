import { doc } from "app/firebase"
import { market } from "app/types"
import { getDoc } from "firebase/firestore"

type StatisticsParameters = {
  market: market
  year: number
  month: number
}

export const fetchStatisticsData = (
  params: StatisticsParameters,
  onSuccess: (data: StatisticsData | null) => void,
  onError?: (error: Error) => void,
): void => {
  const fileName = `${params.year}_${params.month + 1}` // The months are not 0-indexed in Firestore
  const filePath = doc("Reports", params.market, "Statistics", fileName)

  getDoc(filePath)
    .then((snapshot) => {
      const exists = snapshot.exists()
      const data = snapshot.data()?.data
      onSuccess(exists && data ? (data as StatisticsData) : null)
    })
    .catch((error) => {
      console.error("Error fetching statistics:", error)
      onError?.(error)
      onSuccess(null)
    })
}
