import { httpsCallable } from "firebase/functions"
import { functions } from "./firebase"
import {
  IConversationResponse,
  IConversationReview,
  IFetchLabelConversationsRequest,
  IFetchLabelConversationsResponse,
  IFetchReportRequest,
  IFetchReportResponse,
  market,
  ReportData,
  SetMarketSettingRequest,
  UploadDataStatistics,
} from "./types"
import { DataProcessingRequest, DefaultRequest } from "features/insights/types"
import { Flag } from "features/flag/types"
import { AuthResponse } from "features/auth/types"

export const listConversations = httpsCallable<
  Record<string, any>,
  IConversationResponse
>(functions, "fetch_conversations")

export const fetchLabelConversations = httpsCallable<
  IFetchLabelConversationsRequest,
  IFetchLabelConversationsResponse
>(functions, "fetchLabelConversations")

export const fetchEntitiesAPI = httpsCallable<string, string>(
  functions,
  "fetchEntities",
)

export const conversationInsights = httpsCallable<DataProcessingRequest, any>(
  functions,
  "conversationInsights",
  {
    timeout: 120000,
  },
)

export const insightsSummary = httpsCallable<DefaultRequest, any>(
  functions,
  "insightsSummary",
  {
    timeout: 120000,
  },
)

export const saveReview = httpsCallable<IConversationReview, void>(
  functions,
  "saveReview",
)

export const saveFlagOnConversation = httpsCallable<Flag, void>(
  functions,
  "saveFlagOnConversation",
)

export const getEntitiesZip = httpsCallable<market, string>(
  functions,
  "getEntitiesZip",
)

export const fetchReports = httpsCallable<
  IFetchReportRequest,
  IFetchReportResponse
>(functions, "fetch_report_data")

export const saveReportFn = httpsCallable<
  ReportData,
  { success: boolean; reportId: string }
>(functions, "saveReport")

export const uploadStatistics = httpsCallable<
  UploadDataStatistics,
  { success: boolean }
>(functions, "uploadStatistics")

export const getAccessMarkets = httpsCallable<undefined, AuthResponse>(
  functions,
  "authentication",
)

export const getAccessGpt = httpsCallable<undefined, boolean>(
  functions,
  "getAccessGpt",
)

export const getAccessMarketAdmin = httpsCallable<market, boolean>(
  functions,
  "getAccessMarketAdmin",
)

export const setMarketSettingField = httpsCallable<
  SetMarketSettingRequest,
  void
>(functions, "setMarketSettingField")
