import { LLMModel, Message } from "../types"

export const INSIGHTS_THUNK_ACTIONS = {
  "insights/fetchConversationInsights": "insights/fetchConversationInsights",
  "insights/fetchInsightsSummary": "insights/fetchInsightsSummary",
  "insights/generateLLMReport": "insights/generateLLMReport",
} as const

export const INSIGHTS_MIDDLEWARE_ACTIONS = {
  "insights/appendMessage": (payload: Message) => ({
    type: "insights/appendMessage",
    payload,
  }),
  "insights/removeMessage": (payload: number) => ({
    type: "insights/removeMessage",
    payload,
  }),
  "insights/popMessages": (payload: number) => ({
    type: "insights/popMessages",
    payload,
  }),
  "insights/resetMessages": () => ({
    type: "insights/resetMessages",
    payload: undefined,
  }),
  "insights/setSelectedChatModel": (payload: LLMModel) => ({
    type: "insights/setSelectedChatModel",
    payload,
  }),
  "insights/removeThread": (payload: string) => ({
    type: "insights/removeThread",
    payload,
  }),
  "insights/setMessageAttribute": (payload: {
    threadId: string
    messageIndex: number
    feedback: string
  }) => ({
    type: "insights/setMessageAttribute",
    payload,
  }),
  "insights/resetInsights": () => ({
    type: "insights/restInsights",
    payload: undefined,
  }),
} as const
