import { JSX } from "react"

export type OverlayDetailsState =
  | "collapsed"
  | "slightlyVisible"
  | "fullyExpanded"

const OverlayDetailsPane = ({
  children,
  visible,
  state,
}: {
  children: JSX.Element
  visible: boolean
  state: OverlayDetailsState
}) => {
  if (!visible) return

  return (
    <div className={`modal-details-panel-parent ${state}`}>
      <div className="overflow-hider">
        <div className="modal-details-panel">{children}</div>
      </div>
    </div>
  )
}

export default OverlayDetailsPane
