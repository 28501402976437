import { Dispatch } from "redux"
import {
  fetchConversationInsights,
  fetchInsightsSummary,
} from "../../insightsSlice"
import { LLMModel, Message } from "../../types"
import { decode } from "utils/sanitize"
import { IConversation } from "app/types"
import { formatLlmMessages, formatSampledConversations } from "./llmFormatting"

export const updateInsights = async (
  prompt: string,
  conversations: IConversation[],
  dispatch: Dispatch<any>,
  model: LLMModel,
) => {
  const messages: Record<string, string> = {}
  conversations.slice(0, conversations.length).forEach((conversation) => {
    messages[conversation.id] = formatSampledConversations(conversation)
  })

  dispatch(
    fetchConversationInsights({
      messages,
      prompt,
      model,
    }),
  )
}

export const updateLLMChat = (
  insights: Message[],
  model: LLMModel,
  dispatch: Dispatch<any>,
) => {
  const { config, formattedLLMChat } = formatLlmMessages(insights)

  // For logging purposes
  formattedLLMChat.forEach((chat) => {
    const decodedChat = decode(chat)
    console.log(decodedChat)
  })

  dispatch(
    fetchInsightsSummary({
      insights: formattedLLMChat,
      prompt: config.systemPrompt,
      model,
    }),
  )
}
