import { Box, Paper, Typography, List } from "@mui/material"
import { styled } from "@mui/material/styles"

export const MessageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(2),
  width: "100%",
}))

export const MessageContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isCustomer",
})<{ isCustomer: boolean }>(({ isCustomer }) => ({
  display: "flex",
  gap: "8px",
  width: "100%",
  justifyContent: isCustomer ? "flex-start" : "flex-end",
}))

export const MessageBubbleWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isCustomer",
})<{ isCustomer: boolean }>(({ isCustomer }) => ({
  maxWidth: "70%",
  display: "flex",
  flexDirection: "column",
  alignItems: isCustomer ? "flex-start" : "flex-end",
}))

export const MessageBubble = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "isCustomer",
})<{ isCustomer: boolean; isAutomated: boolean }>(
  ({ theme, isCustomer, isAutomated }) => ({
    padding: theme.spacing(2),
    backgroundColor: isAutomated ? "#955" : isCustomer
      ? theme.palette.grey[100]
      : theme.palette.primary.main,
    color: isCustomer
      ? theme.palette.text.primary
      : theme.palette.primary.contrastText,
    borderRadius: 16,
    width: "fit-content",
    maxWidth: "100%",
    wordBreak: "break-word",
  }),
)

export const TimeStamp = styled(Typography)({
  fontSize: "0.75rem",
  opacity: 0.7,
  marginTop: 4,
})

export const SidePanel = styled(Paper)(({ theme }) => ({
  width: "33.33%",
  minWidth: "300px",
  maxWidth: "400px",
  height: "100%",
  overflowY: "hidden",
  borderRadius: 0,
  borderRight: `1px solid ${theme.palette.divider}`,
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.background.default,
}))

export const NavigationHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.default,
  position: "sticky",
  top: 0,
  zIndex: 2,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  minWidth: 0,
}))

export const ConversationListContainer = styled(List)({
  overflowY: "auto",
  flex: 1,
})

export const FloatingHeader = styled(Paper)(({ theme }) => ({
  position: "sticky",
  top: 0,
  zIndex: 1,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  borderRadius: theme.shape.borderRadius,
}))

export const RightPanel = styled(Paper)(({ theme }) => ({
  width: "33.33%",
  minWidth: "300px",
  maxWidth: "400px",
  height: "100%",
  overflowY: "auto",
  borderRadius: 0,
  borderLeft: `1px solid ${theme.palette.divider}`,
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}))

export const ConversationPanel = styled(Box)(({ theme }) => ({
  flex: 1,
  minWidth: "33.33%",
  height: "100%",
  overflowY: "auto",
  padding: theme.spacing(3),
  backgroundColor: theme.palette.grey[100],
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}))

export const InfoPanel = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.default,
  flexShrink: 0,
}))
