import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { KeyValueType } from "app/types"
import { fetchConversations } from "features/listConversations/listConversationSlice"
import { purify } from "utils/sanitize"
import { setSearchText } from "./conversationSearchSlice"
import Search from "@ingka/search"

const maxLength = 60

const ConversationSearch = () => {
  const dispatch = useAppDispatch()
  const { loadingConversations, filters } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const { searchText } = useAppSelector(
    (state: RootState) => state.conversationSearch,
  )

  const scopes = {
    English: "translated",
    // Untranslated: "original",
  }

  function handlePaste(event: React.ClipboardEvent<HTMLDivElement>) {
    event.preventDefault()
    const pastedText = event.clipboardData.getData("text/plain")
    let fullText = searchText + pastedText
    if (fullText.length > maxLength) {
      fullText = fullText.substring(0, maxLength)
    }
    dispatch(setSearchText(fullText))
  }

  function onSearch() {
    if (searchText === "") {
      dispatch(fetchConversations(filters))
      return
    }

    const search: KeyValueType = {
      key: "text_contains",
      value: purify(searchText),
    }
    const filtersWithSearch = [...filters, search]

    dispatch(fetchConversations(filtersWithSearch))
  }

  function searchChanged(e: any) {
    if (e.target.value.length > maxLength) return
    dispatch(setSearchText(e.target.value))
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      event.preventDefault()
      onSearch()
    }
  }

  return (
    <Search
      id="search"
      scope={scopes}
      disabled={loadingConversations}
      value={searchText}
      onSearch={() => onSearch()}
      onPaste={(e) => handlePaste(e)}
      onChange={(e) => searchChanged(e)}
      onKeyDown={(e) => handleKeyDown(e)}
    />
  )
}

export default ConversationSearch
