import {
  ChatTextAreaProps,
  ChatViewProps,
  PromptControlProps,
} from "features/insights/types"
import SendMessageButton from "../buttons/SendMessageButton"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { LinearProgress } from "@mui/material"
import ChatTextArea from "./ChatTextArea"
import { displayCost } from "features/insights/utils/costUtils/costFormatting"
import { calculateLLMChatStats } from "features/insights/utils/costUtils/costUtils"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import LLMModelSelectionChat from "../input/LLMModelSelectionChat"
import { useLlmModelConfigs } from "features/insights/utils/hooks/useLlmModelConfigs"

interface ChatViewOverlayProps
  extends ChatViewProps,
    ChatTextAreaProps,
    PromptControlProps {}

const ChatViewOverlay = ({
  chatControlsRef,
  textareaRef,
  promptCrud,
}: ChatViewOverlayProps) => {
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { inputDisabled } = useInsightsHelperFunctions()
  const { contextWindowChat } = useLlmModelConfigs()
  const { tokens, cost } = calculateLLMChatStats(
    insightsState.selectedSummaryPrompt,
  )

  const formattedCost = cost ? displayCost(cost) : ""

  const contextWindowUsage =
    tokens && contextWindowChat ? (
      <p className="info-text" style={{ alignSelf: "flex-end" }}>
        {Math.round((tokens / contextWindowChat) * 100)}% of context window used
      </p>
    ) : (
      ""
    )

  return (
    <div className="sticky-overlay">
      <div className="chat-controls-parent">
        <div className="chat-input-section">
          <div className="chat-controls" ref={chatControlsRef}>
            {inputDisabled ? (
              <div className="chat-input-loading">
                <LinearProgress color="secondary" />
              </div>
            ) : (
              <div className="flex-row spaced">
                <div className="chat-input-parent">
                  <ChatTextArea textareaRef={textareaRef} />
                </div>
              </div>
            )}
            <div
              className="flex-row spaced"
              style={{
                padding: "0 1rem",
                width: "calc(100% - 2rem)",
              }}
            >
              <div>{promptCrud}</div>
              <div style={{ width: "100%" }}>
                <div
                  className="flex-row"
                  style={{ width: "100%", justifyContent: "flex-end" }}
                >
                  <SendMessageButton />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex-row llm-chat-input"
          style={{ justifyContent: "space-between" }}
        >
          <LLMModelSelectionChat />

          <div
            className="flex-row"
            style={{
              gap: "1rem",
              ...(inputDisabled && { opacity: ".5" }),
            }}
          >
            {contextWindowUsage}
            {formattedCost}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatViewOverlay
