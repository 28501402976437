import React, { useState } from "react"
import { Channel } from "../../../app/types"
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import { validMarkets, validChannels } from "app/types"
import { Dataset } from "../types"
import { getDatasetDoc } from "utils/firestorePaths"
import { getDoc } from "app/firebase"
import { useFetchDatasets } from "../hooks/useFetchDatasets"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"

const DEFAULT_LIMIT = 50
const DEFAULT_COUNTRY = "US"
const DEFAULT_CHANNEL = "Chat"
const DEFAULT_FLAG_NAME = ""

interface FetchConversationsDialogProps {
  onFetchConversations: (dataset: Dataset) => void
  onCancel: () => void
}

const FetchConversationsDialog: React.FC<FetchConversationsDialogProps> = ({
  onFetchConversations,
  onCancel,
}) => {
  const [datasetName, setDatasetName] = useState<string>("")
  const [limit, setLimit] = useState<number>(DEFAULT_LIMIT)
  const [country, setCountry] = useState<string>(DEFAULT_COUNTRY)
  const [channel, setChannel] = useState<Channel>(DEFAULT_CHANNEL)
  const [flagName, setFlagName] = useState<string>(DEFAULT_FLAG_NAME)
  const { datasets, trySaveDataset, removeDataset } = useFetchDatasets()
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)

  // Check if current dataset name exists in presets
  const isExistingDataset = datasets.some((d) => d === datasetName)
  const fieldsDisabled = isExistingDataset

  const handleDatasetChange = async (newValue: string | null) => {
    const selectedName = newValue || ""
    setDatasetName(selectedName)
    if (!selectedName) return
    const dataset = await getDoc(getDatasetDoc(selectedName))

    if (dataset) {
      const limit = dataset.data?.limit
      const country = dataset.data?.country
      const flagName = dataset.data?.flagName
      const channel = dataset.data?.channel

      if (limit && country && flagName && channel) {
        setLimit(limit)
        setCountry(country)
        setFlagName(flagName)
        setChannel(channel)
      }
    }
  }

  const handleDeleteDataset = async (dataset: string) => {
    setDatasetName("")
    setDeleteDialog(false)
    await removeDataset(dataset)
  }

  const handleFetch = async () => {
    if (limit > 0 && country && datasetName) {
      const dataset = {
        name: datasetName,
        flagName: flagName,
        limit: limit,
        country,
        channel,
      }
      // Save dataset configuration
      await trySaveDataset(dataset)
      onFetchConversations(dataset)
    }
  }

  const isValid = datasetName && flagName && limit > 0 && country

  return (
    <Box>
      <Autocomplete
        freeSolo
        value={datasetName}
        onChange={(_event, newValue) => handleDatasetChange(newValue)}
        onInputChange={(_event, newValue) => handleDatasetChange(newValue)}
        options={datasets}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Dataset Name"
            required
            size="small"
            helperText={
              datasets.length > 0
                ? "Select existing dataset or enter new name"
                : "Enter new dataset name"
            }
          />
        )}
        renderOption={(propsInner, option) => {
          const { key, ...otherProps } = propsInner
          return (
            <li key={option} {...otherProps}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                padding={1}
              >
                <div>{`${option}`}</div>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    setDatasetName(option)
                    setDeleteDialog(true)
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            </li>
          )
        }}
        sx={{ mb: 1 }}
      />
      <TextField
        fullWidth
        size="small"
        label="Flag Name"
        value={flagName}
        onChange={(e) => setFlagName(e.target.value)}
        required
        disabled={fieldsDisabled}
        sx={{
          mb: 1,
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
            opacity: fieldsDisabled ? 0.7 : 1,
          },
        }}
      />
      <TextField
        fullWidth
        size="small"
        label="Limit"
        type="number"
        value={limit}
        onChange={(e) => setLimit(parseInt(e.target.value))}
        required
        disabled={fieldsDisabled}
        sx={{
          mb: 1,
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
            opacity: fieldsDisabled ? 0.7 : 1,
          },
        }}
        inputProps={{ min: 1 }}
      />
      <TextField
        fullWidth
        size="small"
        select
        label="Country"
        value={country}
        onChange={(e) => setCountry(e.target.value)}
        required
        disabled={fieldsDisabled}
        sx={{
          mb: 1,
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
            opacity: fieldsDisabled ? 0.7 : 1,
          },
        }}
      >
        {validMarkets.map((market) => (
          <MenuItem key={market} value={market}>
            {market}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        size="small"
        select
        label="channel"
        value={channel}
        onChange={(e) => setChannel(e.target.value as Channel)}
        required
        disabled={fieldsDisabled}
        sx={{
          mb: 1,
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
            opacity: fieldsDisabled ? 0.7 : 1,
          },
        }}
      >
        {validChannels.map((channel) => (
          <MenuItem key={channel} value={channel}>
            {channel}
          </MenuItem>
        ))}
      </TextField>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Button
          variant="contained"
          size="small"
          fullWidth
          onClick={handleFetch}
          disabled={!isValid}
        >
          {isExistingDataset ? "Fetch Using Preset" : "Fetch and Save Preset"}
        </Button>
        <Button variant="outlined" size="small" fullWidth onClick={onCancel}>
          Cancel
        </Button>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={deleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete "{datasetName}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteDataset(datasetName)}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default FetchConversationsDialog
