import Grid from "@mui/material/Grid"
import Text from "@ingka/text"
import { IMessage } from "app/types"
import { RootState } from "app/store"
import { useAppSelector } from "app/hooks"
import { decode } from "utils/sanitize"

interface MessageItemProps {
  messageObj: IMessage
  index: number
}

const MessageItem: React.FC<MessageItemProps> = ({ messageObj, index }) => {
  const conversationState = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  const hideMessage =
    !conversationState.showAutomatedMessages && messageObj.isAutomated

  const message = (
    <Grid columnSpacing={1} item container xs={12}>
      <Grid item xs={1} className="centerContent">
        <Text tagName="p">{index}</Text>
      </Grid>
      <Grid
        item
        xs={8}
        className={
          messageObj.userType === "agent"
            ? "messageBox messageBoxAgent"
            : "messageBox messageBoxCustomer"
        }
        style={{ overflowWrap: "break-word" }}
      >
        <Text tagName="p">
          {conversationState.showOriginalLanguage || messageObj.text === null
            ? decode(messageObj.textRaw)
            : decode(messageObj.text)}
        </Text>
      </Grid>
      <Grid
        item
        xs={2}
        className="centerContent"
        sx={{
          wordWrap: "break-word",
          overflowWrap: "break-word",
          wordBreak: "break-all",
        }}
      >
        <Text tagName="p">{messageObj.userType}</Text>
      </Grid>
    </Grid>
  )

  return hideMessage ? null : message
}

export default MessageItem
