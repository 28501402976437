import { format, isValid, parseISO } from "date-fns"

export const formatDate = (
  dateString: string | null | undefined,
  formatStr: string = "PPpp",
): string => {
  if (!dateString) {
    return format(new Date(0), formatStr)
  }

  try {
    // First try parsing as ISO string
    let date = parseISO(dateString)

    // If that fails, try our custom format
    if (!isValid(date)) {
      const cleanDateString = dateString
        .replace(" UTC", "") // Remove UTC suffix
        .replace(/\.\d+/, "") // Remove milliseconds
        .replace(" ", "T") // Replace space with T
      date = new Date(cleanDateString)
    }

    // If still invalid, use fallback date
    if (!isValid(date)) {
      date = new Date(0)
    }

    return format(date, formatStr)
  } catch (error) {
    console.error("Error formatting date:", error)
    return format(new Date(0), formatStr)
  }
}
