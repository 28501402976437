import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { conversationInsights, insightsSummary } from "app/api"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
} from "app/firebase"
import {
  InsightsState,
  PromptType,
  SavedPrompt,
  Message,
  ChatThread,
  DefaultRequest,
  DataProcessingRequest,
  LLMModel,
  LLMReportTemplate,
  ResumeStoredLLMThreadReq,
  FetchStoredLLMThreadsReq,
  FetchStoredLLMThreadsRes,
  ResumeStoredLLMThreadRes,
} from "./types"
import {
  DEFAULT_LLM_MODEL,
  DEFAULT_TOKENS_PER_CONVERSATION,
  EMPTY_PROMPT_PLACEHOLDER,
  INSIGHTS_MODELS,
} from "./constants"
import { loadSelectedThread, saveSelectedThread } from "./utils/storageUtils"
import { IConversation } from "app/types"
import {
  rawDataFormatter,
  RawDataFormatterParams,
} from "./utils/rawDataFormatter"
import { v4 as uuidv4 } from "uuid"
import {
  fetchReportTemplates,
  fetchStoredThreads,
  resumeStoredThread,
} from "./utils/firestore/fetchers"

// Async thunks
export const fetchConversationInsights = createAsyncThunk(
  "insights/fetchConversationInsights",
  async (req: DataProcessingRequest) => {
    const result = await conversationInsights(req)
    return result.data
  },
)

export const fetchInsightsSummary = createAsyncThunk(
  "insights/fetchInsightsSummary",
  async (req: DefaultRequest) => {
    const result = await insightsSummary(req)
    return result ? result.data : "Error fetching result"
  },
)

export const generateLLMReport = createAsyncThunk(
  "insights/generateLLMReport",
  async (params: {
    conversations: IConversation[]
    tokensPerConversation: number
    contextWindow: number
    insightsDataShare: number
    model: LLMModel
    template: string
  }) => {
    const tokenLimit = params.contextWindow * (params.insightsDataShare / 100)

    const rawDataParams: RawDataFormatterParams = {
      conversations: params.conversations,
      tokensPerConversation: params.tokensPerConversation,
      tokenLimit,
    }

    const { formattedRawData } = rawDataFormatter(rawDataParams)

    // For logging purposes on the client
    console.log(formattedRawData, params.template)

    const result = await insightsSummary({
      insights: [formattedRawData],
      prompt: params.template,
      model: params.model,
    })

    return result.data
  },
)

export const fetchSavedPrompts = createAsyncThunk(
  "insights/fetchSavedPrompts",
  async ({
    collectionIdentifier,
    systemPrompts,
  }: {
    collectionIdentifier: string
    systemPrompts?: boolean
  }) => {
    const [summarySnap, insightsSnap] = await Promise.all([
      getDocs(
        collection(
          "prompts",
          systemPrompts ? "summarySystem" : "summary",
          collectionIdentifier,
        ),
      ),
      getDocs(
        collection(
          "prompts",
          systemPrompts ? "insightSystem" : "insight",
          collectionIdentifier,
        ),
      ),
    ])

    const summaryPrompts: SavedPrompt[] = summarySnap.map(
      (doc) =>
        ({
          ...doc.data,
          id: doc.id,
        }) as SavedPrompt,
    )

    const insightsPrompts: SavedPrompt[] = insightsSnap.map(
      (doc) =>
        ({
          ...doc.data,
          id: doc.id,
        }) as SavedPrompt,
    )

    return { summaryPrompts, insightsPrompts }
  },
)

export const deleteSavedPrompt = createAsyncThunk(
  "insights/deleteSavedPrompt",
  async ({
    collectionIdentifier,
    promptType,
    promptId,
    systemPrompt,
  }: {
    collectionIdentifier: string
    promptType: PromptType
    promptId: string
    systemPrompt?: boolean
  }) => {
    await deleteDoc(
      doc(
        "prompts",
        systemPrompt ? `${promptType}System` : promptType,
        collectionIdentifier,
        promptId,
      ),
    )
    return { promptType, promptId }
  },
)

export const addSavedPrompt = createAsyncThunk(
  "insights/addSavedPrompt",
  async ({
    collectionIdentifier,
    promptType,
    prompt,
    systemPrompt,
  }: {
    collectionIdentifier: string
    promptType: PromptType
    prompt: SavedPrompt
    systemPrompt?: boolean
  }) => {
    const docRef = await addDoc(
      collection(
        "prompts",
        systemPrompt ? `${promptType}System` : promptType,
        collectionIdentifier,
      ),
      prompt,
    )

    if (!docRef) return

    return {
      promptType,
      savedPrompt: { ...prompt, id: docRef.id },
    }
  },
)

export const saveCurrentPrompt = createAsyncThunk(
  "insights/saveCurrentPrompt",
  async ({
    collectionIdentifier,
    prompt,
    promptType,
    systemPrompt,
  }: {
    collectionIdentifier: string
    prompt: SavedPrompt
    promptType: PromptType
    systemPrompt?: boolean
  }) => {
    await setDoc(
      doc(
        "prompts",
        systemPrompt ? `${promptType}System` : promptType,
        collectionIdentifier,
        prompt.id,
      ),
      prompt,
    )
    return { promptType, prompt }
  },
)

export const fetchLLMReportTemplates = createAsyncThunk<LLMReportTemplate[]>(
  "insights/fetchLLMReportTemplates",
  async () => fetchReportTemplates(),
)

export const fetchStoredLLMThreads = createAsyncThunk<
  FetchStoredLLMThreadsRes,
  FetchStoredLLMThreadsReq
>("insights/fetchStoredLLMThreads", async (req) => fetchStoredThreads(req))

export const resumeStoredLLMThread = createAsyncThunk<
  ResumeStoredLLMThreadRes,
  ResumeStoredLLMThreadReq
>("insights/resumeStoredLLMThread", async (req) => resumeStoredThread(req))

const initiateThread = (state: InsightsState, filters: string) => {
  // Just set new filters to previously initiated thread if its empty
  if (state.currentThread && state.currentThread.chatMessages.length === 0) {
    state.currentThread.filters = filters
    return
  }

  const newThread: ChatThread = {
    chatMessages: [],
    insights: {},
    filters: filters,
    recap: "",
    uuid: uuidv4(),
    lastInteraction: Date.now(),
    model: state.selectedChatModel,
    tokensPerConversation: state.tokensPerConversation,
  }

  state.currentThread = newThread
  state.reportMode = true

  const selectedThreadRef = state.selectedThread
  state.selectedThread = state.currentThread.uuid
  state.selectedThreadPrev = selectedThreadRef

  updateTimestampThread(state.currentThread)
  saveSelectedThread(state.selectedThread)
  triggerNewThreadToast(state)
}

const triggerNewThreadToast = (state: InsightsState) => {
  if (state.selectedThreadPrev === "") return

  state.selectedThreadToast = true

  const timer = setTimeout(() => {
    state.selectedThreadToast = false
  }, 3000)

  return () => clearTimeout(timer)
}

// Initial state
export const initialState: InsightsState = {
  insightsSummary: "",
  insightsSummaryCost: 0,
  insightsLoading: false,
  insightsSummaryLoading: false,
  summaryPromptIsSaved: true,
  insightPromptIsSaved: true,
  chatPromptIsSaved: true,
  reportPromptIsSaved: true,
  isSaving: false,
  promptsLoading: false,
  selectedSummaryPrompt: EMPTY_PROMPT_PLACEHOLDER,
  selectedInsightPrompt: EMPTY_PROMPT_PLACEHOLDER,
  chatMessages: [],
  currentChatMessage: {
    displayName: "",
    id: "initial-prompt",
    prompt: "",
  },
  messageEditIndex: null,
  advancedControlsSheetVisible: false,
  advancedControlsVisible: true,
  tabIndex: 0,
  animateButton: false,
  chatHistoryVisible: false,
  selectedThread: loadSelectedThread(),
  selectedThreadPrev: "",
  selectedThreadToast: false,
  previewedThread: "",
  insightsDataShare: 75,
  insightsModelConfig: INSIGHTS_MODELS,
  largeContextWindowEnabled: false,
  tokensPerConversation: DEFAULT_TOKENS_PER_CONVERSATION,
  settingsVisible: false,
  selectedChatModel: DEFAULT_LLM_MODEL,
  llmReportModel: DEFAULT_LLM_MODEL,
  selectedTokensPerConversation: DEFAULT_TOKENS_PER_CONVERSATION,
  llmReportLoading: false,
  reportMode: true,
  chatThreads: [],
  storedThreadHistoryLoading: false,
  storedThreadHistoryLoadingSilently: false,
  storedThreadHistoryPage: 0,
  storedThreadLoading: false,
  initialThreadPersistCheckDone: false,
}

// Chat thread helper functions
const updateTimestampThread = (thread: ChatThread) =>
  (thread.lastInteraction = Date.now())

// Slice
export const insightsSlice = createSlice({
  name: "insights",
  initialState: initialState,
  reducers: {
    setPrompt: (
      state,
      action: PayloadAction<{ prompt: string; promptType: PromptType }>,
    ) => {
      const { prompt, promptType } = action.payload
      const selectedPrompt =
        promptType === "summary"
          ? state.selectedSummaryPrompt
          : state.selectedInsightPrompt
      selectedPrompt.prompt = prompt
      state[`${promptType}PromptIsSaved`] = false
    },
    setSelectedPrompt: (
      state,
      action: PayloadAction<{ prompt: SavedPrompt; promptType: PromptType }>,
    ) => {
      const { prompt, promptType } = action.payload

      const capitalPromptType = (promptType.charAt(0).toUpperCase() +
        promptType.slice(1)) as "Insight" | "Summary"

      state[`selected${capitalPromptType}Prompt`] = prompt
      state[`${promptType}PromptIsSaved`] = true
    },
    resetInsights: (state) => {
      if (!state.currentThread) return

      state.currentThread.insights = {}
    },
    appendMessage: (state, action: PayloadAction<Message>) => {
      if (!state.currentThread) return

      state.currentThread.chatMessages = [
        ...state.currentThread.chatMessages,
        action.payload,
      ]

      updateTimestampThread(state.currentThread)
    },
    removeMessage: (state, action: PayloadAction<number>) => {
      if (!state.currentThread) return

      const index = action.payload
      const chatMessages = state.currentThread.chatMessages

      if (index >= 0 && index < chatMessages.length) {
        chatMessages.splice(index, 1)
        updateTimestampThread(state.currentThread)
      }
    },
    popMessages: (state, action: PayloadAction<number>) => {
      if (!state.currentThread) return

      const index = action.payload
      const chatMessages = state.currentThread.chatMessages

      if (index >= 0 && index < chatMessages.length) {
        state.currentThread.chatMessages = chatMessages.slice(0, index)
        updateTimestampThread(state.currentThread)
      }
    },
    setCurrentMessage: (state, action: PayloadAction<string>) => {
      state.currentChatMessage.prompt = action.payload
    },
    resetMessages: (state) => {
      if (!state.currentThread) return

      state.currentThread.chatMessages = []
      updateTimestampThread(state.currentThread)
    },
    setMessageEditIndex: (state, action: PayloadAction<number | null>) => {
      state.messageEditIndex = action.payload
    },
    setAdvancedControlsSheetVisible: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.advancedControlsSheetVisible = action.payload

      state.tokensPerConversation = state.selectedTokensPerConversation
    },
    setAdvancedControlsVisible: (state, action: PayloadAction<boolean>) => {
      state.advancedControlsVisible = action.payload
    },
    setTabIndex: (state, action: PayloadAction<number>) => {
      state.tabIndex = action.payload
    },
    setAnimateButton: (state, action: PayloadAction<boolean>) => {
      state.animateButton = action.payload
    },
    setChatHistoryVisibility: (state, action: PayloadAction<boolean>) => {
      state.chatHistoryVisible = action.payload
    },
    createThread: (state, action: PayloadAction<string>) => {
      initiateThread(state, action.payload)
    },
    removeThread: (state, action: PayloadAction<string>) => {
      if (!Array.isArray(state.chatThreads)) {
        state.chatThreads = []
      }
      const threadIndex = state.chatThreads.findIndex(
        (thread) => thread.uuid === action.payload,
      )
      if (threadIndex === -1 || state.selectedThread === action.payload) return

      state.chatThreads = [
        ...state.chatThreads.slice(0, threadIndex),
        ...state.chatThreads.slice(threadIndex + 1),
      ]

      if (state.previewedThread === action.payload) {
        state.previewedThread = ""
      }
    },
    setPreviewedThread: (state, action: PayloadAction<string>) => {
      if (state.previewedThread === action.payload) {
        state.previewedThread = ""
      } else {
        state.previewedThread = action.payload
      }
    },
    setInsightsDataShare: (state, action: PayloadAction<number>) => {
      state.insightsDataShare = action.payload
    },
    toggleLargeContextWindow: (state) => {
      state.largeContextWindowEnabled = !state.largeContextWindowEnabled
    },
    setTokensPerConversation: (state, action: PayloadAction<number>) => {
      state.tokensPerConversation = action.payload
    },
    setSettingsVisibility: (state, action: PayloadAction<boolean>) => {
      state.settingsVisible = action.payload
    },
    setSelectedChatModel: (state, action: PayloadAction<LLMModel>) => {
      if (!state.currentThread) return

      state.currentThread.model = action.payload
      state.selectedChatModel = action.payload

      updateTimestampThread(state.currentThread)
    },
    setSelectedLLMTemplate: (
      state,
      action: PayloadAction<LLMReportTemplate | undefined>,
    ) => {
      if (action.payload) {
        state.llmReportTemplateSelected = action.payload
        state.llmReportModel = action.payload.model ?? DEFAULT_LLM_MODEL
        state.llmReportManualPrompt = ""
      } else {
        state.llmReportTemplateSelected = undefined
        state.llmReportModel = DEFAULT_LLM_MODEL
        state.llmReportManualPrompt = ""
      }
    },
    setLLMReportManualInput: (state, action: PayloadAction<string>) => {
      state.llmReportManualPrompt = action.payload
    },
    setSelectedTokensPerConversation: (state) => {
      state.selectedTokensPerConversation = state.tokensPerConversation
    },
    setReportMode: (state, action: PayloadAction<boolean>) => {
      state.reportMode = action.payload
      state.llmReportTemplateSelected = undefined
    },
    incrementStoredThreadHistoryPage: (state) => {
      state.storedThreadHistoryPage += 1
    },
    setMessageAttribute: (
      state,
      action: PayloadAction<{
        messageIndex: number
        attribute: "feedback" | "rating"
        value: string | null
      }>,
    ) => {
      const { messageIndex, attribute, value } = action.payload

      if (
        !state.currentThread ||
        messageIndex < 0 ||
        messageIndex >= state.currentThread.chatMessages.length
      )
        return

      if (value === null) {
        const { [attribute]: removed, ...messageWithoutAttribute } =
          state.currentThread.chatMessages[messageIndex]
        state.currentThread.chatMessages[messageIndex] = messageWithoutAttribute
      } else {
        state.currentThread.chatMessages[messageIndex] = {
          ...state.currentThread.chatMessages[messageIndex],
          [attribute]: value,
        }
      }
    },
    toggleSelectedThreadToast: (state) => {
      state.selectedThreadToast = !state.selectedThreadToast
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversationInsights.pending, (state) => {
        state.insightsLoading = true
      })
      .addCase(fetchConversationInsights.fulfilled, (state, action) => {
        state.insightsLoading = false
        if (!state.currentThread) return

        state.currentThread.insights = action.payload
        updateTimestampThread(state.currentThread)
      })
      .addCase(fetchConversationInsights.rejected, (state) => {
        state.insightsLoading = false
      })
      .addCase(fetchInsightsSummary.pending, (state) => {
        state.insightsSummaryLoading = true
      })
      .addCase(fetchInsightsSummary.fulfilled, (state, action) => {
        state.insightsSummaryLoading = false
        state.insightsSummary = action.payload ? action.payload["analysis"] : ""
        state.insightsSummaryCost = action.payload
          ? action.payload["total_cost"]?.toFixed(6)
          : 0

        if (!state.currentThread) return

        state.currentThread.chatMessages = [
          ...state.currentThread.chatMessages,
          { content: action.payload.analysis, type: "received" },
        ]

        updateTimestampThread(state.currentThread)
      })
      .addCase(fetchInsightsSummary.rejected, (state) => {
        state.insightsSummaryLoading = false
      })
      .addCase(fetchSavedPrompts.pending, (state) => {
        state.promptsLoading = true
      })
      .addCase(fetchSavedPrompts.fulfilled, (state, action) => {
        const insightsPrompts = action.payload.insightsPrompts || []
        state.savedInsightsPrompts = insightsPrompts.map(
          (prompt) =>
            ({
              ...prompt,
            }) as SavedPrompt,
        )
        const summaryPrompts = action.payload.summaryPrompts || []
        state.savedSummaryPrompts = summaryPrompts.map(
          (prompt) =>
            ({
              ...prompt,
            }) as SavedPrompt,
        )
        state.promptsLoading = false
      })
      .addCase(fetchSavedPrompts.rejected, (state) => {
        state.savedInsightsPrompts = []
        state.savedSummaryPrompts = []
        state.selectedInsightPrompt = initialState.selectedInsightPrompt
        state.selectedSummaryPrompt = initialState.selectedSummaryPrompt
        state.promptsLoading = false
      })
      .addCase(addSavedPrompt.pending, (state) => {
        state.isSaving = true
      })
      .addCase(addSavedPrompt.fulfilled, (state, action) => {
        if (action.payload?.promptType === "insight") {
          state.savedInsightsPrompts = [
            ...(state.savedInsightsPrompts || []),
            action.payload.savedPrompt,
          ]
          state.selectedInsightPrompt = action.payload.savedPrompt
          state.insightPromptIsSaved = true
        } else if (action.payload?.promptType === "summary") {
          state.savedSummaryPrompts = [
            ...(state.savedSummaryPrompts || []),
            action.payload.savedPrompt,
          ]
          state.selectedSummaryPrompt = action.payload.savedPrompt
          state.summaryPromptIsSaved = true
        }
        state.isSaving = false
      })
      .addCase(addSavedPrompt.rejected, (state) => {
        state.isSaving = false
      })
      .addCase(deleteSavedPrompt.pending, (state) => {
        state.isSaving = true
      })
      .addCase(deleteSavedPrompt.fulfilled, (state, action) => {
        const { promptType, promptId } = action.payload
        if (promptType === "insight") {
          state.savedInsightsPrompts = (
            state.savedInsightsPrompts || []
          ).filter((p) => p.id !== promptId)
          if (state.selectedInsightPrompt.id === promptId) {
            state.selectedInsightPrompt =
              state.savedInsightsPrompts[0] ||
              initialState.selectedInsightPrompt
          }
        } else if (promptType === "summary") {
          state.savedSummaryPrompts = (state.savedSummaryPrompts || []).filter(
            (p) => p.id !== promptId,
          )
          if (state.selectedSummaryPrompt.id === promptId) {
            state.selectedSummaryPrompt =
              state.savedSummaryPrompts[0] || initialState.selectedSummaryPrompt
          }
        }
        state.isSaving = false
      })
      .addCase(deleteSavedPrompt.rejected, (state) => {
        state.isSaving = false
      })
      .addCase(saveCurrentPrompt.pending, (state) => {
        state.isSaving = true
      })
      .addCase(saveCurrentPrompt.fulfilled, (state, action) => {
        const { promptType, prompt } = action.payload
        if (promptType === "insight") {
          state.insightPromptIsSaved = true
          state.savedInsightsPrompts = (state.savedInsightsPrompts || []).map(
            (p) => (p.id === prompt.id ? prompt : p),
          )
        } else if (promptType === "summary") {
          state.summaryPromptIsSaved = true
          state.savedSummaryPrompts = (state.savedSummaryPrompts || []).map(
            (p) => (p.id === prompt.id ? prompt : p),
          )
        }
        state.isSaving = false
      })
      .addCase(saveCurrentPrompt.rejected, (state) => {
        state.isSaving = false
      })
      .addCase(fetchLLMReportTemplates.pending, (state) => {
        state.llmReportTemplatesLoading = true
      })
      .addCase(fetchLLMReportTemplates.fulfilled, (state, action) => {
        state.llmReportTemplatesLoading = false
        state.llmReportTemplates = action.payload
        setSelectedLLMTemplate(state.llmReportTemplates[0])
      })
      .addCase(fetchLLMReportTemplates.rejected, (state) => {
        state.llmReportTemplatesLoading = false
        state.llmReportTemplates = []
      })
      .addCase(generateLLMReport.pending, (state) => {
        state.llmReportLoading = true
      })
      .addCase(generateLLMReport.fulfilled, (state, action) => {
        state.llmReportLoading = false
        state.generatedLlmReport = action.payload
        state.llmReportTemplateSelected = undefined

        if (!state.currentThread) return

        state.currentThread.chatMessages = [
          ...state.currentThread.chatMessages,
          { content: action.payload.analysis, type: "report" },
        ]

        updateTimestampThread(state.currentThread)
      })
      .addCase(generateLLMReport.rejected, (state) => {
        state.llmReportLoading = false
      })
      .addCase(resumeStoredLLMThread.pending, (state) => {
        state.storedThreadLoading = true
      })
      .addCase(resumeStoredLLMThread.fulfilled, (state, action) => {
        state.initialThreadPersistCheckDone ||= true
        state.storedThreadLoading = false

        state.currentThread = action.payload
      })
      .addCase(resumeStoredLLMThread.rejected, (state, action) => {
        state.initialThreadPersistCheckDone ||= true
        state.storedThreadLoading = false

        const filters = action.meta.arg.currentFilters
        initiateThread(state, filters)
      })
      .addCase(fetchStoredLLMThreads.pending, (state, action) => {
        if (action.meta.arg.fetchSilently) {
          state.storedThreadHistoryLoadingSilently = true
        }
        state.storedThreadHistoryLoading = true
      })
      .addCase(fetchStoredLLMThreads.fulfilled, (state, action) => {
        state.storedThreadHistoryLoadingSilently = false
        state.storedThreadHistoryLoading = false

        if (action.payload.threads) {
          const alreadyFetchedThreads = new Set(
            state.chatThreads.map((thread) => thread.uuid),
          )
          const newThreads = action.payload.threads.filter(
            (thread) => !alreadyFetchedThreads.has(thread.uuid),
          )

          state.chatThreads.push(...newThreads)
          state.storedThreadHistoryLastDoc = action.payload.lastDoc
        }
      })
      .addCase(fetchStoredLLMThreads.rejected, (state) => {
        state.storedThreadHistoryLoadingSilently = false
        state.storedThreadHistoryLoading = false
      })
  },
})

export const {
  setPrompt,
  setSelectedPrompt,
  resetInsights,
  appendMessage,
  removeMessage,
  popMessages,
  setCurrentMessage,
  resetMessages,
  setMessageEditIndex,
  setAdvancedControlsSheetVisible,
  setAdvancedControlsVisible,
  setTabIndex,
  setAnimateButton,
  setChatHistoryVisibility,
  createThread,
  removeThread,
  setPreviewedThread,
  setInsightsDataShare,
  toggleLargeContextWindow,
  setTokensPerConversation,
  setSettingsVisibility,
  setSelectedChatModel,
  setSelectedLLMTemplate,
  setLLMReportManualInput,
  setSelectedTokensPerConversation,
  setReportMode,
  incrementStoredThreadHistoryPage,
  setMessageAttribute,
  toggleSelectedThreadToast,
} = insightsSlice.actions

export default insightsSlice.reducer
