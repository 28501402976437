import { PromptControlProps } from "features/insights/types"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import FeatureTitle from "components/FeatureTitle"
import Tabs, { Tab, TabPanel } from "@ingka/tabs"
import LLMProcessing from "../input/LLMProcessing"
import RawDataControls from "../input/RawDataControls"
import { useTranslation } from "react-i18next"
import DataSourcesControls from "../input/DataSourcesControls"

const AdvancedView = ({ promptCrud }: PromptControlProps) => {
  const { t } = useTranslation()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const advancedControlsDisabled = !insightsState.advancedControlsVisible

  const tabs = [
    <Tab key="tab-0" text={t("tabs:dataSources")} tabPanelId="tab_0" />,
    <Tab key="tab-1" text={t("tabs:dataFormatting")} tabPanelId="tab_1" />,
    <Tab key="tab-2" text={t("tabs:llmPreProcessing")} tabPanelId="tab_2" />,
  ]

  const tabPanels = [
    <TabPanel key="tab-0" tabPanelId="tab_0">
      <DataSourcesControls />
    </TabPanel>,
    <TabPanel key="tab-1" tabPanelId="tab_1">
      <RawDataControls />
    </TabPanel>,
    <TabPanel key="tab-2" tabPanelId="tab_2">
      <LLMProcessing promptCrud={promptCrud} />
    </TabPanel>,
  ]

  return (
    <div style={{ position: "relative", height: "inherit" }}>
      <div
        className={`${advancedControlsDisabled ? "unfocused-content controls-parent" : "controls-parent"}`}
      >
        <FeatureTitle featureName={t("buttons:settings")} />
        <Tabs tabs={tabs} tabPanels={tabPanels} defaultActiveTab="tab_0" />
      </div>
    </div>
  )
}

export default AdvancedView
