import { Skeleton, TextField } from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import {
  setCurrentMessage,
  setLLMReportManualInput,
  setPrompt,
} from "./insightsSlice"
import { encode, decode } from "utils/sanitize"
import { ChangeEvent, ReactElement } from "react"
import useInsightsHelperFunctions from "./utils/hooks/useInsightsHelperFunctions"
import { PromptType } from "./types"

interface PromptInputProps {
  type: PromptType
  promptInfo?: ReactElement | undefined
}

const PromptInput: React.FC<PromptInputProps> = ({ type, promptInfo }) => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { inputDisabled } = useInsightsHelperFunctions()

  const selectedPrompt =
    type === "insight"
      ? insightsState.selectedInsightPrompt
      : type === "summary"
        ? insightsState.selectedSummaryPrompt
        : type === "chat"
          ? insightsState.selectedSummaryPrompt
          : insightsState.selectedSummaryPrompt

  const handlePromptInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (type === "insight" || type === "summary") {
      dispatch(
        setPrompt({
          prompt: encode(e.target.value),
          promptType: type,
        }),
      )
    } else if (type === "report") {
      dispatch(setLLMReportManualInput(e.target.value))
    } else {
      dispatch(setCurrentMessage(encode(e.target.value)))
    }
  }

  const currentInput =
    type === "report"
      ? insightsState.llmReportManualPrompt
      : decode(selectedPrompt.prompt)

  return (
    <>
      {insightsState.promptsLoading ? (
        <Skeleton variant="rectangular" width="100%" height={300} />
      ) : (
        <>
          <div style={{ position: "relative" }}>
            <TextField
              id="prompt-textarea"
              disabled={inputDisabled}
              multiline
              fullWidth
              minRows={type === "chat" ? 5 : 10}
              value={currentInput}
              onChange={(e) => handlePromptInput(e)}
            />
          </div>
          <div
            style={{
              margin: "-2rem 0 3.25rem 1rem",
              position: "relative",
              zIndex: "2",
              width: "100%",
            }}
          >
            {promptInfo}
          </div>
        </>
      )}
    </>
  )
}

export default PromptInput
