import React, { useState, useMemo } from "react"
import {
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Button,
} from "@mui/material"
import DeleteOutline from "@mui/icons-material/DeleteOutline"
import ExpandMore from "@mui/icons-material/ExpandMore"
import ExpandLess from "@mui/icons-material/ExpandLess"
import { Entity, EntityType } from "../types"
import { EntityColors } from "./MessageBubble"
import { useFetchEntities } from "../hooks/useEntities"
import VirtualizedSelect from "components/VirtualizedSelect"
import { createFilterOptions } from "@mui/material/Autocomplete"

interface CollapsedEntity {
  ikeaValue: string | null
  type: EntityType
  value: string
  occurrences: Entity[]
}

const EntityTypeLabels: Record<EntityType, string> = {
  store: "Store",
  case_number: "Case Number",
  order_number: "Order Number",
  product: "Product",
  service: "Service",
  anonymization_failure: "Anonymization Failure",
}

interface EntityListProps {
  entities: Entity[]
  onRemove: (id: string) => void
  onScrollTo: (transcriptOrder: number) => void
  onUpdateEntityValue: (entityIds: string[], ikeaValue: string) => void
  onSaveEntityOverrides: () => Promise<void>
  entitiesSaved: boolean
}

const EntityList: React.FC<EntityListProps> = ({
  entities,
  onRemove,
  onScrollTo,
  onUpdateEntityValue,
  onSaveEntityOverrides,
  entitiesSaved
}) => {
  const [expandedEntities, setExpandedEntities] = useState<Set<string>>(
    new Set(),
  )
  const { entityOptions, loadingEntities } = useFetchEntities()

  // Group entities by type and value (case-insensitive)
  const groupedEntities = useMemo(() => {
    const groups = new Map<string, CollapsedEntity>()

    entities.forEach((entity) => {
      const key = `${entity.type}-${entity.value.toLowerCase()}` // Use lowercase for grouping key
      if (!groups.has(key)) {
        groups.set(key, {
          ikeaValue: entity.ikeaValue,
          type: entity.type,
          value: entity.value,
          occurrences: [],
        })
      }
      groups.get(key)?.occurrences.push(entity)
    })

    return Array.from(groups.values())
  }, [entities])

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (entity: string) => `${entity}`,
  })

  const toggleExpand = (key: string) => {
    setExpandedEntities((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(key)) {
        newSet.delete(key)
      } else {
        newSet.add(key)
      }
      return newSet
    })
  }

  const handleOptionSelect = (entity: CollapsedEntity, newValue: string) => {
    const entityIds = entity.occurrences.map((o) => o.id)
    onUpdateEntityValue(entityIds, newValue)
  }

  const handleEntityNumberOverride = (
    entity: CollapsedEntity,
    newValue: string,
  ) => {
    const entityIds = entity.occurrences.map((o) => o.id)
    const onlyNumbers = newValue.replace(/\D/g, "")

    onUpdateEntityValue(entityIds, onlyNumbers)
  }

  const renderEntityOptions = (entity: CollapsedEntity) => {
    if (!entityOptions) return null

    switch (entity.type) {
      case "store":
        return (
          <FormControl size="small" fullWidth sx={{ mt: 1 }}>
            <InputLabel>Select Store</InputLabel>
            <Select
              value={entity.ikeaValue ?? ""}
              label="Select Store"
              onChange={(e) => handleOptionSelect(entity, e.target.value)}
              onClick={(e) => e.stopPropagation()}
            >
              {entityOptions.cmp.map(
                (store: {
                  cmp_code: string
                  cmp_name: string
                  city: string
                }) => (
                  <MenuItem key={store.cmp_code} value={store.cmp_name}>
                    {store.cmp_name} ({store.city})
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        )
      case "service":
        return (
          <FormControl size="small" fullWidth sx={{ mt: 1 }}>
            <InputLabel>Select Service</InputLabel>
            <Select
              value={entity.ikeaValue ?? ""}
              label="Select Service"
              onChange={(e) => handleOptionSelect(entity, e.target.value)}
              onClick={(e) => e.stopPropagation()}
            >
              {entityOptions.services.map((service: string) => (
                <MenuItem key={service} value={service}>
                  {service}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      case "product":
        return (
          <FormControl size="small" fullWidth sx={{ mt: 1 }}>
            <VirtualizedSelect
              value={entity.ikeaValue}
              onChange={(_, newValue) => handleOptionSelect(entity, newValue)}
              options={entityOptions.prodcuts}
              getOptionLabel={(option: string) => `${option}`}
              filterSelectedOptions
              loading={loadingEntities}
              placeholder={"Seclect Product"}
              isOptionEqualToValue={(o: string, v: string) => o === v}
              filterOptions={filterOptions}
            />
          </FormControl>
        )

      case "order_number":
      case "case_number":
        return (
          <FormControl size="small" fullWidth sx={{ mt: 1 }}>
            <Input
              value={entity.ikeaValue}
              onChange={(e) =>
                handleEntityNumberOverride(entity, e.target.value)
              }
            />
          </FormControl>
        )
    }

    return null
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {entities.length === 0 ? (
        <Typography variant="body2" color="text.secondary">
          No entities added yet. Select text from the conversation to add
          entities.
        </Typography>
      ) : (
        Object.entries(EntityTypeLabels).map(([type, label]) => {
          const typeEntities = groupedEntities.filter(
            (entity) => entity.type === type,
          )
          if (typeEntities.length === 0) return null

          return (
            <Box key={type} sx={{ mb: 2 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: EntityColors[type as EntityType],
                  fontWeight: "bold",
                  mb: 1,
                }}
              >
                {label}
              </Typography>
              {typeEntities.map((entity) => {
                const key = `${entity.type}-${entity.value.toLowerCase()}` // Use lowercase for key
                const isExpanded = expandedEntities.has(key)

                return (
                  <Box key={key}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: 1,
                        mb: 1,
                        backgroundColor: `${EntityColors[entity.type]}10`,
                        p: 1,
                        borderRadius: 1,
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: `${EntityColors[entity.type]}20`,
                        },
                      }}
                      onClick={() => toggleExpand(key)}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {isExpanded ? <ExpandLess /> : <ExpandMore />}
                          <Box sx={{ ml: 1 }}>
                            <Typography
                              variant="body2"
                              sx={{
                                color: EntityColors[entity.type],
                                fontWeight: 500,
                              }}
                            >
                              {entity.value}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: EntityColors[entity.type],
                                opacity: 0.7,
                              }}
                            >
                              {entity.occurrences.length} occurrence
                              {entity.occurrences.length > 1 ? "s" : ""}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      {renderEntityOptions(entity)}
                    </Box>
                    {isExpanded && (
                      <Box sx={{ ml: 4, mb: 2 }}>
                        {entity.occurrences.map((occurrence) => (
                          <Box
                            key={occurrence.id}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              gap: 1,
                              mb: 1,
                              p: 1,
                              borderRadius: 1,
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: `${EntityColors[entity.type]}10`,
                              },
                            }}
                            onClick={() =>
                              onScrollTo(occurrence.transcriptOrder)
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Typography
                                variant="caption"
                                sx={{
                                  color: EntityColors[entity.type],
                                  opacity: 0.7,
                                }}
                              >
                                Message #{occurrence.transcriptOrder}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: EntityColors[entity.type],
                                  opacity: 0.9,
                                }}
                              >
                                ({occurrence.value})
                              </Typography>
                            </Box>
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation()
                                onRemove(occurrence.id)
                              }}
                              sx={{
                                opacity: 0.7,
                                color: EntityColors[entity.type],
                              }}
                            >
                              <DeleteOutline fontSize="small" />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                )
              })}
            </Box>
          )
        })
      )}
      <Button
        onClick={onSaveEntityOverrides}
        color={entitiesSaved ? "success" : "error"}
        variant="contained"
        tabIndex={0}
      >
        {entitiesSaved ? "Entities Saved" : "Unsaved Entities"}
      </Button>
    </Box>
  )
}

export default EntityList
