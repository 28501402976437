import { useEffect } from "react"

/* Necessary for suppressing non critical errors not yet fixed for the 
Ingka Skapa component library triggered when using newer versions of React */

const useSuppressSkapaErrors = () => {
  useEffect(() => {
    const originalConsoleError = console.error

    console.error = (...args) => {
      if (
        args[0] &&
        (args.includes("closeBtnClick") ||
          args[0].includes("Invalid prop `prefix`") ||
          args[0].includes("React.Fragment"))
      ) {
        return
      }

      originalConsoleError(...args)
    }

    return () => {
      console.error = originalConsoleError
    }
  }, [])
}

export default useSuppressSkapaErrors
