import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import FilterButton from "features/insights/components/buttons/FilterButton"
import ShowAdvancedButton from "features/insights/components/buttons/ShowAdvancedButton"
import Filters from "features/listConversations/Filters"
import Button from "@ingka/button"
import { useDashboardSelection } from "stores/hooks"
import { useTranslation } from "react-i18next"
import { urlToFilters } from "utils/filterUtils"
import { useNavigateWithParams } from "hooks/useNavigateWithParams"
import { KeyValueType } from "app/types"
import { useFetchConversations } from "features/listConversations/hooks/useFetchConversations"
import Text from "@ingka/text"

const FilterSummary = () => {
  const { t } = useTranslation()
  const { handleApplyFilterChange } = useFetchConversations()
  const { navigateWithParams } = useNavigateWithParams()

  // States
  const currentlyFetchedFilters = useAppSelector(
    (state: RootState) => state.listConversation.filters,
  )

  const { selectedAnalysisFiltering, isLoadingAnalysis, selectedTopic } =
    useDashboardSelection()

  const filterSelectionDashboard = urlToFilters(
    new URLSearchParams(selectedAnalysisFiltering ?? ""),
  )

  // Conditionals
  const isCurrentlyOnDashboard = location.pathname === "/"

  const filterAlreadyUsed =
    JSON.stringify(currentlyFetchedFilters) ===
    JSON.stringify(filterSelectionDashboard)

  const graphUnselected = isCurrentlyOnDashboard && !selectedTopic

  const showCurrentFilter = !isCurrentlyOnDashboard || filterAlreadyUsed

  const showProposedFilter =
    isCurrentlyOnDashboard && selectedAnalysisFiltering && !filterAlreadyUsed

  const noProposedFilterAvailable =
    isCurrentlyOnDashboard && !selectedAnalysisFiltering && selectedTopic

  const showDashboardInstructions =
    graphUnselected || (noProposedFilterAvailable && !showCurrentFilter)

  const showSelectedFilter =
    !showProposedFilter && Object.keys(currentlyFetchedFilters).length !== 0

  // Handlers
  const handleSetDashboardFilters = () => {
    handleApplyFilterChange(filterSelectionDashboard)
    navigateWithParams("/insights")
  }

  // Objects
  const visibleFilters: KeyValueType[] = showProposedFilter
    ? filterSelectionDashboard
    : currentlyFetchedFilters

  const showInGpt = (
    <Button
      type="emphasised"
      loading={isLoadingAnalysis}
      onClick={() => handleSetDashboardFilters()}
    >
      {t("buttons:useFilter")}
    </Button>
  )

  const primaryAction = showProposedFilter ? showInGpt : <FilterButton />

  const secondaryAction = showSelectedFilter ? (
    <ShowAdvancedButton />
  ) : undefined

  const staticLabelDashboard = noProposedFilterAvailable
    ? t("labels:noAnalysisAvailable")
    : t("labels:selectTopicDashboard")

  return (
    <>
      <div className="page-specific-links">
        <div
          className={`${isLoadingAnalysis ? "filters-disabled" : ""}`}
          style={{ width: "100%" }}
        >
          <div className="flex-row wrapped">
            {showDashboardInstructions ? (
              <div
                style={{
                  height: "10rem",
                  display: "flex",
                  alignItems: "center",
                  padding: "0 .75rem",
                  borderRadius: "1rem",
                  border: "var(--border-gray-light-dotted)",
                  width: "100%",
                  flex: "1",
                  justifyContent: "center",
                }}
              >
                <Text headingSize="xs" tagName="h6" className="small-title">
                  {staticLabelDashboard}
                </Text>
              </div>
            ) : (
              <Filters
                filters={visibleFilters}
                selected={showCurrentFilter}
                action={secondaryAction}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex-column filter-button-navigation">
        {primaryAction}
      </div>
    </>
  )
}

export default FilterSummary
