import { DashboardSelectionState, StoreState } from "stores/types"

const date = new Date()
const currentDay = date.getDate()

// Use two months ago if its the 1st or 2nd day of month and otherwise the previous month
// (as the report data for the previous month is generated on the 2nd)
if (currentDay <= 2) {
  date.setDate(1)
  date.setMonth(date.getMonth() - 2)
} else {
  date.setDate(1)
  date.setMonth(date.getMonth() - 1)
}

const PREVIOUS_MONTH = date.getMonth()
const PREVIOUS_YEAR = date.getFullYear()

const DEFAULT_DASHBOARD_SELECTION = {
  selectedMarket: "AT",
  selectedAnalysisFiltering: null,
  selectedTopic: null,
  selectedRootCauseOwner: "all",
  selectedMonth: PREVIOUS_MONTH,
  selectedYear: PREVIOUS_YEAR,
  selectedAnalysis: null,
  data: [],
  isLoadingAnalysis: false,
  selectedConversationId: null,
  conversationData: null,
  isLoadingConversation: false,
  conversationsMap: {},
  chatMessages: [],
  csatData: null,
  isLoadingCsatData: false,
  statisticsData: null,
  selectedStatistics: null,
}

export const createDashboardSelectionSlice = (
  set: any,
): DashboardSelectionState => ({
  ...DEFAULT_DASHBOARD_SELECTION,

  setDashboardField: (field: keyof DashboardSelectionState, value: any) =>
    set((state: StoreState) => ({
      dashboardSelection: {
        ...state.dashboardSelection,
        [field]: value,
      },
    })),

  resetDashboardField: (field: keyof DashboardSelectionState) =>
    set((state: StoreState) => ({
      dashboardSelection: {
        ...state.dashboardSelection,
        [field]:
          DEFAULT_DASHBOARD_SELECTION[
            field as keyof typeof DEFAULT_DASHBOARD_SELECTION
          ],
      },
    })),
})
