import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { fetchLabels } from "../listConversationSlice"

export const useFetchLabels = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state: RootState) => state.auth.user)
  const loadingLabels = useAppSelector(
    (state: RootState) => state.listConversation.loadingLabels,
  )
  const labels = useAppSelector(
    (state: RootState) => state.listConversation.labels,
  )

  const requestLabels =
    user && !loadingLabels && Object.keys(labels).length === 0

  useEffect(() => {
    const fetchLabelsIfNotLoaded = async () => {
      if (requestLabels) {
        await dispatch(fetchLabels())
      }
    }

    fetchLabelsIfNotLoaded()
  }, [user, loadingLabels, labels])
}
