import Button from "@ingka/button"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { toggleFilterMenu } from "features/listConversations/listConversationSlice"
import { useTranslation } from "react-i18next"
import { useDashboardSelection } from "stores/hooks"

const FilterButton = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const loadingConversations = useAppSelector(
    (state: RootState) => state.listConversation.loadingConversations,
  )

  const { isLoadingAnalysis } = useDashboardSelection()

  return (
    <Button
      type="primary"
      onClick={() => dispatch(toggleFilterMenu())}
      fluid={true}
      loading={loadingConversations || isLoadingAnalysis}
    >
      {t("buttons:editFilters")}
    </Button>
  )
}

export default FilterButton
