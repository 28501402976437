import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { useFormattedRawData } from "features/insights/utils/hooks/useFormattedRawData"
import { useLlmModelConfigs } from "features/insights/utils/hooks/useLlmModelConfigs"

const RawDataInformation = () => {
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { contextWindowChat } = useLlmModelConfigs()
  const { formattedRawDataTokenCount } = useFormattedRawData().settings

  if (contextWindowChat)
    return (
      <>
        <div
          className="flex-row spaced"
          style={{ marginTop: "1.25rem", opacity: ".5" }}
        >
          <label className="skapa-label scaled no-wrap">
            ← More conversations
          </label>
          <label className="skapa-label scaled no-wrap">
            More tokens per conversation →
          </label>
        </div>
        <div
          className="flex-row"
          style={{ margin: "1.25rem 0 0 0", width: "100%" }}
        >
          <table>
            <tbody>
              <tr>
                <th>Property</th>
                <th>Size (tokens)</th>
              </tr>
              <tr>
                <td>Context window</td>
                <td>{Math.round(contextWindowChat / 1000)}K</td>
              </tr>
              <tr>
                <td>Max raw data size</td>
                <td>
                  {Math.round(
                    (contextWindowChat * insightsState.insightsDataShare) /
                      100 /
                      1000,
                  )}
                  K
                </td>
              </tr>
              <tr>
                <td>Current raw data size</td>
                <td>{Math.round(formattedRawDataTokenCount / 1000)}K</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
}

export default RawDataInformation
