import useConversationsHelperFunctions from "features/listConversations/hooks/useConversationsHelperFunctions"
import { JSX } from "react"
import { useTranslation } from "react-i18next"
import Toast from "@ingka/toast"

export interface ToastParams {
  text: string
  isOpen: boolean
  actionText: string
  action: () => void
  closeAction: () => void
}

const MessagePage = ({
  message,
  hint,
}: {
  message: string
  hint?: JSX.Element
}) => {
  return (
    <div
      className="no-messages-label-parent centered"
      style={{ flexDirection: "column" }}
    >
      <p className="no-messages-title">{message}</p>
      {hint && <div>{hint}</div>}
    </div>
  )
}

const MessagePageHandler = ({
  children,
  toastParams,
  overrideCondition = false,
}: {
  children: JSX.Element
  toastParams?: ToastParams
  overrideCondition?: boolean
}) => {
  const { t } = useTranslation()
  const { filtersApplied, conversationsBeingFetched, conversationsAvailable } =
    useConversationsHelperFunctions()

  const message = !filtersApplied
    ? t("labels:applyFilters")
    : t("labels:noResultsInteractions")

  const showMessagePage =
    !(conversationsAvailable || conversationsBeingFetched) && !overrideCondition

  const toast = toastParams && (
    <Toast
      text={toastParams.text}
      isOpen={toastParams.isOpen}
      actionButtonText={toastParams.actionText}
      actionClick={toastParams.action}
      onCloseRequest={toastParams.closeAction}
      ariaLabelCloseBtn={t("buttons:closeToast")}
    />
  )

  return (
    <div className="message-page-container">
      {showMessagePage ? <MessagePage message={message} /> : children}
      {toast}
    </div>
  )
}

export default MessagePageHandler
