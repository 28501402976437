import { FC, JSX } from "react"
import { MessageType } from "./types"
import "./chat.css"
import Markdown from "markdown-to-jsx"
import { decode, heEscape } from "utils/sanitize"

interface InsightsMessageProps {
  messageContent: string
  messageEditState: boolean | null
  type: MessageType
  toolbar: JSX.Element | null
  showReport?: boolean
}

const InsightsMessage: FC<InsightsMessageProps> = ({
  messageContent,
  messageEditState,
  type,
  toolbar,
  showReport = false,
}) => {
  if (!showReport && type === "report") return null

  const messageClass = type === "sent" ? "input" : "output"
  const editClass = messageEditState ? "editing" : ""

  const parentClass = `chat-message ${messageClass} ${editClass}`
  const childClass = `message-content ${messageClass}`

  return (
    <div className={parentClass}>
      <div className={childClass}>
        <Markdown>{decode(heEscape(messageContent))}</Markdown>
      </div>
      {toolbar && <div className="toolbar-parent">{toolbar}</div>}
    </div>
  )
}

export default InsightsMessage
