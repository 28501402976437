import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { listConversations } from "app/api"
import { doc, getDoc } from "app/firebase"
import {
  IConversation,
  IConversationResponse,
  IConversationReviewMin,
  KeyValueType,
  market,
} from "app/types"
import { entityRow, ListConversationState, MosaikViewLabel } from "./types"
import { getEntitiesZip } from "./api"
import {
  loadConversationsFromStorage,
  saveConversationsToStorage,
} from "./utils/persistConversations"
import { requestFormatHandler } from "utils/filterUtils"

const {
  conversations: storedConversations,
  totalAmount: storedTotalAmount,
  filters: storedFilters,
} = loadConversationsFromStorage()

const initialState: ListConversationState = {
  loadingConversations: false,
  totalAmountOfConversations: storedTotalAmount,
  filters: storedFilters,
  previousFilters: storedFilters,
  labels: {},
  loadingLabels: false,
  entityOptionsFetching: false,
  filterMenuOpen: false,
  showOriginalLanguage: true,
  showAutomatedMessages: false,
  conversations: storedConversations,
  hasInitiallyFetched: false,
}

export const fetchConversations = createAsyncThunk(
  "listConversation/fetchConversations",
  async (filters: KeyValueType[]) => {
    const convertedRequest = requestFormatHandler(filters)
    const response = (await listConversations(convertedRequest)).data

    return response
  },
)

export const loadEntitiesZipAsync = createAsyncThunk(
  "listConversation/loadEntitiesZipAsync",
  async ({
    fileKey,
    inputMarket,
  }: {
    fileKey: string
    inputMarket: market
  }) => {
    const zipFile = await getEntitiesZip(inputMarket)
    return { fileKey, zipFile }
  },
)

export const fetchLabels = createAsyncThunk(
  "listConversation/fetchLabels",
  async () => {
    const labelsRef = doc("config", "labels")
    const labelsDoc = await getDoc(labelsRef)

    if (!labelsDoc?.data) {
      return {}
    }

    const labelsObject: Record<number, MosaikViewLabel> = {}
    labelsDoc.data.labels.forEach((label: MosaikViewLabel) => {
      labelsObject[label.id] = label
    })

    return labelsObject
  },
)

export const listConversationSlice = createSlice({
  name: "listConversation",
  initialState,
  reducers: {
    setConversations: (state, action: PayloadAction<IConversation[]>) => {
      state.conversations = action.payload
      saveConversationsToStorage(
        action.payload,
        state.totalAmountOfConversations,
        state.filters,
      )
    },
    setSelectedConversation: (
      state,
      action: PayloadAction<IConversation | undefined>,
    ) => {
      state.selectedConversation = action.payload
    },
    setFilters: (state, action: PayloadAction<KeyValueType[]>) => {
      state.previousFilters = state.filters
      state.filters = action.payload
    },
    updateFlagNames: (
      state,
      action: PayloadAction<{ conversationId: string; flagNames: string[] }>,
    ) => {
      if (!state.conversations) return
      const conversationIndex = state.conversations.findIndex(
        (c) => c.id === action.payload.conversationId,
      )

      state.conversations[conversationIndex].flagNames =
        action.payload.flagNames
    },
    updateReview: (
      state,
      action: PayloadAction<{
        conversationId: string
        correctLabelId?: number
        comment?: string
      }>,
    ) => {
      if (!state.conversations) return
      const conversationIndex = state.conversations.findIndex(
        (c) => c.id === action.payload.conversationId,
      )
      if (action.payload.correctLabelId) {
        const conversationReview: IConversationReviewMin = {
          correctLabelId: action.payload.correctLabelId,
          comment: action.payload.comment ?? null,
        }
        state.conversations[conversationIndex].review = conversationReview
      } else {
        state.conversations[conversationIndex].review = undefined
      }
    },
    resetErrorMessage: (state) => {
      state.errorMessage = undefined
    },
    setEntities: (state, action: PayloadAction<entityRow[]>) => {
      state.entities = action.payload
    },
    toggleFilterMenu: (state) => {
      state.filterMenuOpen = !state.filterMenuOpen
    },
    toggleLanguage: (state) => {
      state.showOriginalLanguage = !state.showOriginalLanguage
    },
    toggleAutomatedMessages: (state) => {
      state.showAutomatedMessages = !state.showAutomatedMessages
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-unused-vars
    builder.addCase(fetchConversations.pending, (state) => {
      state.loadingConversations = true
    })
    builder.addCase(
      fetchConversations.fulfilled,
      (state, action: PayloadAction<IConversationResponse>) => {
        state.conversations = action.payload.conversations ?? []
        state.totalAmountOfConversations =
          action.payload.totalConversationAmount
        state.loadingConversations = false
        state.errorMessage = undefined
        state.hasInitiallyFetched = true
        saveConversationsToStorage(
          state.conversations,
          state.totalAmountOfConversations,
          state.filters,
        )
      },
    )
    builder.addCase(fetchConversations.rejected, (state, action) => {
      state.loadingConversations = false
      state.conversations = []
      state.errorMessage = action.error.message
    })
    builder.addCase(fetchLabels.pending, (state) => {
      state.loadingLabels = true
    })
    builder.addCase(
      fetchLabels.fulfilled,
      (state, action: PayloadAction<Record<number, MosaikViewLabel>>) => {
        state.labels = action.payload
        state.loadingLabels = false
      },
    )
    builder.addCase(fetchLabels.rejected, (state) => {
      state.loadingLabels = false
    })
    builder.addCase(loadEntitiesZipAsync.pending, (state) => {
      state.entityOptionsFetching = true
    })
    builder.addCase(loadEntitiesZipAsync.fulfilled, (state, action) => {
      state.entityOptionsFetching = false
      localStorage.setItem(action.payload.fileKey, action.payload.zipFile)
    })
    builder.addCase(loadEntitiesZipAsync.rejected, (state) => {
      state.entityOptionsFetching = false
    })
  },
})

export const {
  setConversations,
  setSelectedConversation,
  setFilters,
  updateReview,
  resetErrorMessage,
  updateFlagNames,
  toggleFilterMenu,
  toggleLanguage,
  toggleAutomatedMessages,
} = listConversationSlice.actions

export default listConversationSlice.reducer
