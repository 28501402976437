import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import "./styles/header.css"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import logoSvg from "assets/MosaikViewGPT.svg"
import Text from "@ingka/text"
import { useNavigateWithParams } from "hooks/useNavigateWithParams"
import FilterSummary from "./FilterSummary"

const Navigation = () => {
  const { t } = useTranslation()
  const { isAdmin } = useAppSelector((state: RootState) => state.auth)

  const { navigateWithParams } = useNavigateWithParams()
  const navigate = useNavigate()

  const navButton = (label: string, action: () => void) => {
    return (
      <Text
        tagName="h1"
        headingSize="xs"
        onClick={action}
        style={{ margin: ".25rem 0", cursor: "pointer" }}
      >
        {label}
      </Text>
    )
  }

  return (
    <div className="nav">
      <div className="nav-contents">
        <div>
          <div
            className="pane-title-bar"
            style={{
              padding: "0 1.25rem",
              width: "100%",
              background: "transparent",
            }}
          >
            <div className="pane-title-bar-scaler">
              <a className="nav-logo" onClick={() => navigate("/")}>
                <img src={logoSvg} draggable={false} />
              </a>
            </div>
          </div>
          <div className="main-nav-links">
            {isAdmin && (
              <div>
                <Text bodySize="s">{t("labels:overviewTools")}</Text>
                {navButton(t("buttons:report"), () => navigate("/"))}
              </div>
            )}
            <div>
              <Text bodySize="s">{t("labels:sampleTools")}</Text>
              {navButton(t("buttons:insights"), () =>
                navigateWithParams("/insights"),
              )}
              {navButton(t("buttons:view"), () => navigateWithParams("/view"))}
            </div>
          </div>
        </div>

        <FilterSummary />
      </div>
    </div>
  )
}

export default Navigation
