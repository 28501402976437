import {
  INSIGHTS_MIDDLEWARE_ACTIONS,
  INSIGHTS_THUNK_ACTIONS,
} from "./constants"
import { InsightsState } from "../types"
import { IUser } from "../../auth/types"

export interface ChatThreadsMiddlewareState {
  insights: InsightsState
  auth: {
    user: IUser | null
  }
}

type ThunkAction = {
  type: `${keyof typeof INSIGHTS_THUNK_ACTIONS}/${
    | "pending"
    | "fulfilled"
    | "rejected"}`
  payload?: any
  error?: any
}

export type InsightAction =
  | {
      [K in keyof typeof INSIGHTS_MIDDLEWARE_ACTIONS]: ReturnType<
        (typeof INSIGHTS_MIDDLEWARE_ACTIONS)[K]
      >
    }[keyof typeof INSIGHTS_MIDDLEWARE_ACTIONS]
  | ThunkAction

export const isInsightAction = (action: any): action is InsightAction => {
  return (
    action &&
    typeof action === "object" &&
    (action.type in INSIGHTS_MIDDLEWARE_ACTIONS ||
      Object.values(INSIGHTS_THUNK_ACTIONS).some((thunkType) =>
        action.type.startsWith(thunkType),
      ))
  )
}
