import { useAppDispatch, useAppSelector } from "app/hooks"
import { setSelectedChatModel } from "../../insightsSlice"
import { InsightModel, LLMModel } from "../../types"
import useInsightsHelperFunctions from "features/insights/utils/hooks/useInsightsHelperFunctions"
import { INSIGHTS_MODELS } from "features/insights/constants"
import { RootState } from "app/store"
import { useLlmModelConfigs } from "features/insights/utils/hooks/useLlmModelConfigs"

const GPT_PATTERN = /\b(gpt)\b/gi

const LLMModelSelectionChat = () => {
  const dispatch = useAppDispatch()
  const insightsState = useAppSelector((state: RootState) => state.insights)
  const { inputDisabled } = useInsightsHelperFunctions()
  const { selectedChatModelConfig } = useLlmModelConfigs()

  if (!selectedChatModelConfig) return

  const formattedInsightModelList = (models: InsightModel[]) => {
    return models
      .map((model: InsightModel) => ({
        originalName: model.name,
        ...model,
        displayName: model.name
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
          .replace(GPT_PATTERN, "GPT"),
      }))
      .sort((a, b) => a.displayName.localeCompare(b.displayName))
  }

  return (
    <select
      id="chat-model-selection"
      name="chat-model-selection"
      value={insightsState.selectedChatModel ?? "GPT-4o Mini"}
      disabled={inputDisabled}
      onChange={(e) =>
        dispatch(setSelectedChatModel(e.target.value as LLMModel))
      }
      style={inputDisabled ? { cursor: "default" } : {}}
    >
      {formattedInsightModelList(INSIGHTS_MODELS).map((model) => (
        <option key={model.originalName} value={model.originalName}>
          {model.displayName}
        </option>
      ))}
    </select>
  )
}

export default LLMModelSelectionChat
