import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { fetchLLMReportTemplates } from "features/insights/insightsSlice"

export const useStoredLLMTemplates = () => {
  const user = useAppSelector((state: RootState) => state.auth.user)
  const llmReportTemplates = useAppSelector(
    (state: RootState) => state.insights.llmReportTemplates,
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (user && !llmReportTemplates) {
      dispatch(fetchLLMReportTemplates())
    }
  }, [])
}
