import { FilterMap } from "./types"

const currentDate = new Date()
const oneMonthAgo = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth() - 1,
  currentDate.getDate(),
)

const formatDateToYYYYMMDD = (date: Date): string => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")
  return `${year}-${month}-${day}`
}

export const FILTERS_DEFAULT_STATE: FilterMap = {
  from_start_time: formatDateToYYYYMMDD(oneMonthAgo),
  to_start_time: formatDateToYYYYMMDD(currentDate),
  channel: [],
  country: [],
  manual_tag_label_id: [],
  prediction_label_id: [],
  llm_label_id: [],
  not_llm_label_id: [],
  llm_prompt_hash: "",
  text_contains: "",
  cust_sat: "",
  flag_name: "",
}

export const ENTITY_OPTIONS_DEFAULT_STATE = {
  hfbs: [],
  productNames: [],
  productNumbers: [],
  service_providers: [],
  services: [],
  touchpoints: [],
  unit_codes: [],
  unit_names: [],
}
