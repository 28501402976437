import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "app/store"
import { MarketSettings } from "./types"
import {
  applyMarketSettings,
  getMarketSettings,
  setMarketSettingsMarket,
} from "./marketSettingsSlice"
import { useAppDispatch } from "app/hooks"
import { market } from "app/types"
import { useAuthHelpers } from "features/auth/useAuthHelpers"
import { checkAccessMarketAdmin } from "features/auth/authSlice"
import FeatureTitle from "components/FeatureTitle"
import Button from "@ingka/button"
import InlineMessage from "@ingka/inline-message"
import warningIcon from "@ingka/ssr-icon/paths/warning-triangle"
import { useTranslation } from "react-i18next"
import Text from "@ingka/text"
import Select, { Option } from "@ingka/select"
import Status from "@ingka/status"
import Switch from "@ingka/switch"

export const MarketSettingsPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const settings = useSelector(
    (state: RootState) => state.marketSettings.settings,
  )

  const market = useSelector((state: RootState) => state.marketSettings.market)

  const applying = useSelector(
    (state: RootState) => state.marketSettings.applying,
  )

  const accessMarketAdmin = useSelector(
    (state: RootState) => state.auth.accessMarketAdmin,
  )

  const checkingAccessMarketAdmin = useSelector(
    (state: RootState) => state.auth.checkingAccessMarketAdmin,
  )

  const { accessibleMarkets, noAccessibleMarkets } = useAuthHelpers()

  const [localSettings, setLocalSettings] = useState<MarketSettings>({})

  useEffect(() => {
    if (market) {
      dispatch(checkAccessMarketAdmin(market))
      dispatch(getMarketSettings({ market }))
    }
  }, [market])

  useEffect(() => {
    setLocalSettings(settings)
  }, [settings])

  const handleMarketSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setMarketSettingsMarket(event.target.value as market))
  }

  const handleApply = () => {
    if (!market) return

    dispatch(
      applyMarketSettings({
        market,
        newSettings: localSettings,
      }),
    )
  }

  const handleReset = () => {
    setLocalSettings(settings)
  }

  const hasChanges = JSON.stringify(localSettings) !== JSON.stringify(settings)

  const settingsVisible =
    market && accessMarketAdmin && !checkingAccessMarketAdmin

  const noMarketSelected = !market
  const noAccessToMarket = !accessMarketAdmin && market

  const status = checkingAccessMarketAdmin
    ? t("status:checkingAdmin")
    : noMarketSelected
      ? t("status:noMarketSelected")
      : noAccessToMarket
        ? t("status:noAccessMarket")
        : undefined

  const statusColor =
    checkingAccessMarketAdmin || noMarketSelected
      ? "grey"
      : noAccessToMarket
        ? "red"
        : undefined

  return (
    <div style={{ position: "relative", height: "inherit" }}>
      <div className="controls-parent">
        <FeatureTitle featureName={t("buttons:marketAdministration")} />

        <div
          className="flex-column"
          style={{ marginTop: "1.25rem", gap: "2rem" }}
        >
          <InlineMessage
            title={t("warnings:caution")}
            body={t("warnings:marketSettings")}
            variant="cautionary"
            ssrIcon={warningIcon}
          />

          <div className="flex-column">
            <Text bodySize="l">{t("labels:selectMarket")}:</Text>

            <Select
              value={market ?? ""}
              onChange={handleMarketSelect}
              disabled={noAccessibleMarkets}
              id="market-selector"
            >
              {accessibleMarkets.map((market) => (
                <Option key={market} value={market} name={market} />
              ))}
            </Select>

            {status && (
              <Status label={status} small={true} statusColor={statusColor} />
            )}
          </div>

          {settingsVisible && (
            <div className="modal-section-parent">
              <div className="flex-row spaced">
                <h4>{t("buttons:settings")}</h4>
                <div className="flex-row">
                  <Button type="secondary" onClick={handleReset} small>
                    {t("buttons:resetChanges")}
                  </Button>
                  <Button
                    type="emphasised"
                    onClick={handleApply}
                    loading={applying}
                    disabled={!hasChanges}
                    small
                  >
                    {t("buttons:apply")}
                  </Button>
                </div>
              </div>

              <div>
                <Switch
                  id="enableGpt"
                  label={t("settings:enableGpt")}
                  value={(localSettings.gpt?.enabled ?? false).toString()}
                  checked={localSettings.gpt?.enabled ?? false}
                  onChange={() =>
                    setLocalSettings((prev) =>
                      Object.assign({}, prev, {
                        gpt: { enabled: !prev.gpt?.enabled },
                      }),
                    )
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MarketSettingsPage
