import { LlmFeedbackState, StoreState } from "stores/types"

export const createLlmFeedbackSlice = (set: any): LlmFeedbackState => ({
  feedbackIsOpen: false,
  openMessageIndex: null,
  toggleFeedback: (messageIndex?: number) =>
    set((state: StoreState) => {
      Object.assign(state.llmFeedback, handleToggle(state, messageIndex))
    }),
})

const handleToggle = (state: StoreState, messageIndex?: number) => {
  return {
    feedbackIsOpen: !state.llmFeedback.feedbackIsOpen,
    openMessageIndex: messageIndex !== undefined ? messageIndex : null,
  }
}
