import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { LLMModel } from "features/insights/types"

export const useLlmModelConfigs = () => {
  const insightsState = useAppSelector((state: RootState) => state.insights)

  // Model configurations
  const selectedChatModelConfig = insightsState.insightsModelConfig.find(
    (model: { name: LLMModel }) =>
      model.name === insightsState.selectedChatModel,
  )!

  const selectedReportModelConfig = insightsState.insightsModelConfig.find(
    (model: { name: LLMModel }) => model.name === insightsState.llmReportModel,
  )!

  // Context window conditions
  const largeContextEnabled: boolean = !!(
    insightsState.largeContextWindowEnabled &&
    selectedChatModelConfig.largeContextWindow
  )

  const contextWindowChat = largeContextEnabled
    ? (selectedChatModelConfig.largeContextWindow ?? 0)
    : (selectedChatModelConfig.contextWindow ?? 0)

  const contextWindowReport = largeContextEnabled
    ? (selectedChatModelConfig.largeContextWindow ?? 0)
    : (selectedChatModelConfig.contextWindow ?? 0)

  return {
    selectedChatModelConfig,
    selectedReportModelConfig,
    contextWindowChat,
    contextWindowReport,
  }
}
