import React from "react"
import { Paper, Typography, Box } from "@mui/material"
import { formatDate } from "../utils/dateUtils"

interface ConversationDetailsProps {
  startTime?: { value: string }
  channel: string
  country: string
  label: string
  md5ConversationId: string
}

const ConversationDetails: React.FC<ConversationDetailsProps> = ({
  startTime,
  channel,
  country,
  label,
  md5ConversationId,
}) => {
  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Conversation Details
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="body2" color="text.secondary">
          Started: {formatDate(startTime?.value)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Channel: {channel}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Country: {country}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Previous Label: {label}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            wordBreak: "break-all",
            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
          }}
          onClick={() => {
            navigator.clipboard.writeText(md5ConversationId)
          }}
        >
          ID: {md5ConversationId}
        </Typography>
      </Box>
    </Paper>
  )
}

export default ConversationDetails
