import { IConversation } from "app/types"
import { ConversationInsight, LLMFormattingConfig, Message } from "../../types"
import { defaultConfig } from "./llmFormattingConfig"
import { useStore } from "stores/useStore"
import { decode } from "utils/sanitize"

export const formatSampledConversations = (conversation: IConversation) => {
  let formattedText = ""

  const dataSourcesState = useStore.getState().dataSources
  const dataSourcesConfig = dataSourcesState.savedSelection

  if (Object.entries(dataSourcesConfig).some(([_, value]) => value)) {
    formattedText += "<data"
    if (dataSourcesConfig.date && conversation.endTime)
      formattedText += ` date="${conversation.endTime}"`
    if (dataSourcesConfig.channel && conversation.channel)
      formattedText += ` channel="${conversation.channel}"`
    if (dataSourcesConfig.csatScore && conversation.csatScore)
      formattedText += ` csatScoreCustomerSatisfaction="${conversation.csatScore}"`
    if (dataSourcesConfig.csatComment && conversation.csatFeedback?.feedback)
      formattedText += ` csatCommentCustomerSatisfaction="${conversation.csatFeedback?.feedback}"`
    if (dataSourcesConfig.entities && conversation.entities) {
      const entityString = conversation.entities
        .map((entity) => `${entity.entityName}: ${entity.values.join(", ")}`)
        .join(" | ")
      formattedText += ` entities="${entityString}"`
    }
    formattedText += " />"
  }

  for (const mess of conversation.messages) {
    if (!mess.isAutomated || !mess.isAfterBillie)
      formattedText += `<${mess.userType}>${decode(mess.textRaw)}</${mess.userType}>`
  }

  return formattedText
}

export const formatPrePromptedInsights = (
  prePromptedInsights: Record<string, ConversationInsight>,
) => {
  return Object.values(prePromptedInsights)
    .map(
      ({ insights }, index) =>
        `<llm-processed-conversation id="${index}">${insights}</llm-processed-conversation>`,
    )
    .join("\n")
}

export const formatLlmMessages = (insights: Message[]) => {
  const configKey = "llmChatConfig"
  const storedConfig = localStorage.getItem(configKey)

  let config: LLMFormattingConfig

  if (storedConfig) {
    const parsedConfig: LLMFormattingConfig = JSON.parse(storedConfig)
    if (parsedConfig.version === defaultConfig.version) {
      config = parsedConfig
    } else {
      console.warn(
        "Configuration version mismatch LLM instructions. Resetting to default.",
      )
      localStorage.removeItem(configKey)
      config = defaultConfig
      localStorage.setItem(configKey, JSON.stringify(defaultConfig))
    }
  } else {
    config = defaultConfig
    localStorage.setItem(configKey, JSON.stringify(defaultConfig))
  }

  const formattedLLMChat = Object.values(insights).map((i, index, array) => {
    if (i.type === "received") {
      return config.instructions.received.replace(
        JSON.stringify("PLACEHOLDER"),
        i.content,
      )
    } else if (i.type === "sent") {
      if (index === array.length - 1) {
        return config.instructions.sent.latest.replace(
          JSON.stringify("PLACEHOLDER"),
          i.content,
        )
      }
      return config.instructions.sent.previous.replace(
        JSON.stringify("PLACEHOLDER"),
        i.content,
      )
    } else if (i.type === "raw-data") {
      return config.instructions.rawData.replace(
        JSON.stringify("PLACEHOLDER"),
        i.content,
      )
    } else if (i.type === "pre-processed-data") {
      return config.instructions.preProcessedData.replace(
        JSON.stringify("PLACEHOLDER"),
        i.content,
      )
    } else if (i.type === "report") {
      return config.instructions.report.replace(
        JSON.stringify("PLACEHOLDER"),
        i.content,
      )
    } else {
      return ""
    }
  })

  return { config, formattedLLMChat }
}
