import Button from "@ingka/button"
import SSRIcon from "@ingka/ssr-icon"
import docSum from "@ingka/ssr-icon/paths/document-magnifying-glass"
import Text from "@ingka/text"
import React from "react"
import { decode } from "utils/sanitize"

interface RenderConversationSummaryProps {
  summary: string
  isExpanded: boolean
  toggleExpand: () => void
}

const ConversationSummary: React.FC<RenderConversationSummaryProps> = ({
  summary,
  isExpanded,
  toggleExpand,
}) => {
  if (!summary) return null

  const decodedSummary = decode(summary)
  const minimisedSummary = `${decodedSummary.slice(0, 200)}...`

  const minimisedSummaryAvailable = decodedSummary.length > 200
  const minimisedSummaryDisplayed = !isExpanded && minimisedSummaryAvailable

  return (
    <Text
      style={{
        textAlign: "left",
      }}
      tagName="p"
      bodySize="m"
    >
      <SSRIcon paths={docSum} className="entityIcon" />

      {minimisedSummaryDisplayed ? minimisedSummary : decodedSummary}
      {minimisedSummaryAvailable && (
        <Button
          className="verySmallBtn"
          small
          type="tertiary"
          style={{ marginLeft: "1rem" }}
          onClick={toggleExpand}
        >
          {isExpanded ? "  Show less" : "Show more"}
        </Button>
      )}
    </Text>
  )
}

export default ConversationSummary
