import { removeMessage } from "../../insightsSlice"
import { MessageToolbarProps } from "./MessageToolbar"
import { saveAs } from "file-saver"
import Text from "@ingka/text"
import Button from "@ingka/button"
import thumbsdown from "@ingka/ssr-icon/paths/thumbs-down"
import thumbsup from "@ingka/ssr-icon/paths/thumbs-up"
import chat from "@ingka/ssr-icon/paths/speech-bubble"
import { setMessageAttribute } from "features/insights/insightsSlice"
import { useAppDispatch } from "app/hooks"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { MessageRating } from "features/insights/types"
import { useLlmFeedback } from "stores/hooks"

const ReceivedMessageToolbar = ({
  message,
  messageIndex,
  isDisabled,
  isReport = false,
}: MessageToolbarProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { toggleFeedback } = useLlmFeedback()

  const [rating, setRating] = useState<MessageRating | null>(
    message.rating || null,
  )

  const downloadMarkdown = (messageContent: string): void => {
    const blob = new Blob([messageContent], { type: "text/markdown" })
    saveAs(blob, "insights.md")
  }

  useEffect(() => {
    if (message.rating) {
      setRating(message.rating)
    } else {
      setRating(null)
    }
  }, [message.rating])

  const handleRating = (newRating: MessageRating) => {
    const value = rating === newRating ? null : newRating
    setRating(value)
    dispatch(
      setMessageAttribute({
        messageIndex,
        attribute: "rating",
        value,
      }),
    )
    value && toggleFeedback(messageIndex)
  }

  return (
    <div className="flex-row spaced" style={{ marginTop: ".25rem" }}>
      <div className="flex-row message-toolbar">
        {!isReport && (
          <>
            <div className="flex-row message-toolbar">
              <button
                disabled={isDisabled}
                onClick={() => dispatch(removeMessage(messageIndex))}
              >
                {t("buttons:removeAnswer")}
              </button>
            </div>
            <div className="flex-row message-toolbar">
              <button onClick={() => downloadMarkdown(message.content)}>
                {t("buttons:exportAnswer")}
              </button>
            </div>
          </>
        )}
      </div>
      <div
        className="flex-row message-toolbar"
        style={{
          justifyContent: "center",
          gap: "1rem",
          transform: "scale(.75)",
          transformOrigin: "right center",
          marginLeft: "-6rem",
        }}
      >
        <div className="flex-row" style={{ gap: ".25rem" }}>
          <Text bodySize="l" style={{ whiteSpace: "nowrap" }}>
            {t("labels:rateAnswer")}
          </Text>
          <Button
            type={rating === MessageRating.liked ? "secondary" : "tertiary"}
            iconOnly={true}
            xsmallIconOnly
            ssrIcon={thumbsup}
            onClick={() => handleRating(MessageRating.liked)}
          />
          <Button
            type={rating === MessageRating.disliked ? "secondary" : "tertiary"}
            iconOnly={true}
            xsmallIconOnly
            ssrIcon={thumbsdown}
            onClick={() => handleRating(MessageRating.disliked)}
          />
        </div>
        <div className="flex-row" style={{ gap: ".25rem" }}>
          <Text bodySize="l" style={{ whiteSpace: "nowrap" }}>
            {t("labels:provideFeedback")}
          </Text>
          <Button
            type="tertiary"
            iconOnly={true}
            xsmallIconOnly
            ssrIcon={chat}
            onClick={() => toggleFeedback(messageIndex)}
          />
        </div>
      </div>
    </div>
  )
}

export default ReceivedMessageToolbar
