import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { fetchConversations, setFilters } from "../listConversationSlice"
import {
  deterministicallyOrdered,
  filtersToUrl,
  urlToFilters,
} from "utils/filterUtils"
import { KeyValueType } from "app/types"
import { setSearchText } from "features/conversationSearch/conversationSearchSlice"
import { setMessages } from "features/messages/messagesSlice"

export const useFetchConversations = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  const conversations = useAppSelector(
    (state: RootState) => state.listConversation.conversations,
  )

  const loadingConversations = useAppSelector(
    (state: RootState) => state.listConversation.loadingConversations,
  )

  const updateQueryParams = (params: KeyValueType[]) => {
    const newSearch = filtersToUrl(params).toString()
    const newUrl = `${location.pathname}?${newSearch}`

    window.history.replaceState({}, "", newUrl)
  }

  const handleApplyFilterChange = (kvFilters: KeyValueType[]) => {
    updateQueryParams(kvFilters)
    dispatch(setSearchText(""))
    dispatch(setMessages([]))
    dispatch(fetchConversations(kvFilters))
    dispatch(setFilters(kvFilters))
  }

  const fetchUrlParamsOnMount =
    searchParams && !loadingConversations && !conversations

  useEffect(() => {
    if (fetchUrlParamsOnMount) {
      handleApplyFilterChange(
        deterministicallyOrdered(urlToFilters(searchParams)),
      )
    }
  }, [])

  return { handleApplyFilterChange }
}
