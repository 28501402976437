import Button from "@ingka/button"
import Check from "@mui/icons-material/Check"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { IConversation, IConversationReviewMin } from "app/types"
import { updateReview } from "features/listConversations/listConversationSlice"
import React, { useState } from "react"
import ReviewMenu from "./ReviewMenu"
import {
  createConversationReview,
  delConversationReview,
} from "./conversationReviewSlice"
import reviewIcon from "@ingka/ssr-icon/paths/chat"

const ReviewButton: React.FC<{ conversation: IConversation }> = ({
  conversation,
}) => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { saving } = useAppSelector((state: RootState) => state.review)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleSubmitReview = async (review: IConversationReviewMin) => {
    if (!user) return

    handleMenuClose()
    await dispatch(
      createConversationReview({
        conversation,
        comment: review.comment ?? "",
        correctLabelId: review.correctLabelId,
        userId: user.id,
      }),
    )
    dispatch(
      updateReview({
        conversationId: conversation.id,
        comment: review.comment ?? "",
        correctLabelId: review.correctLabelId,
      }),
    )
  }

  const handleRemoveReview = async () => {
    if (!user) return

    handleMenuClose()
    await dispatch(
      delConversationReview({
        userId: user.id,
        conversationId: conversation.id,
        country: conversation.country,
      }),
    )
    dispatch(
      updateReview({
        conversationId: conversation.id,
      }),
    )
  }

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
        disabled={saving}
        small
        iconPosition="leading"
        ssrIcon={reviewIcon}
        type="tertiary"
      >
        {!!conversation.review && <Check style={{ fontSize: "12px" }} />}
        Review
      </Button>
      <ReviewMenu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        conversation={conversation}
        onSubmitReview={handleSubmitReview}
        onRemoveReview={handleRemoveReview}
      />
    </>
  )
}

export default ReviewButton
